import '../../css/publicUI-old.css';
import '../../css/public2UI.css';
import '../../css/jQueryDatePicker.css';
import '../../css/scroller.css';

const Cookies = require('js-cookie');
import {fieldDisplay} from './fieldDisplay.js';
import {bins} from './bins.js';
import {dialogs} from './dialogs.js';
import {commonSearch} from './commonSearch.js';
import {contactForm} from './contactForm.js';
import {pushState} from './pushState.js';
import {browserCheck} from './browserCheck.js';
import {hfCommon, page} from './common.js';
import {UserManager} from '../../lib/login/js/UserManager.js';

import {videoWindowWidget} from './jquery.videoWindow.js';
require ('./jquery.searchForm.js');


//global Auth functions
function sendForgotPasswordEmail(username) {
    $.ajax({
        data: {
            action: 'sendForgotpassword',
            username: username
        },
        success: function () {
            dialogs.showAlert({
                message: "Thank you.  You will receive a link to reset your password shortly.",
                title: "Email Sent"
            });
        }
    });
}

function testPasswordStrength(password) {
    if (password.length < HF_GLOBALS.minimumPasswordLength) {
        return "Your password must be at least " + HF_GLOBALS.minimumPasswordLength + " characters long";
    }
    return true;
}

function initAuth(videoWindow) {

    UserManager.ajaxPage = HF_AUTH.loginSettings.ajaxUrl;
    if (!HF_AUTH.isLoggedIn) {
        UserManager.setupLogInForm(HF_AUTH.loginSettings.loginFormId);
        UserManager.renderForgotPasswordDialog(
            HF_AUTH.loginSettings.forgotPasswordDialogId, 
            $(`.${HF_AUTH.loginSettings.forgotPasswordContainer}`), 
            HF_AUTH.loginSettings.forgotPassPrompt, 
            sendForgotPasswordEmail
        );
        if (HF_AUTH.loginError) {
            dialogs.showAlert({
                message: HF_AUTH.loginError,
                title: "Sign-in Error"
            });
        }
        initPageNotLoggedIn(videoWindow);
    } else {
        UserManager.renderChangePasswordDialog(
            HF_AUTH.loginSettings.changePassFormId, 
            HF_AUTH.loginSettings.changePassDialogId, 
            "", 
            window.location.href, 
            $(`#${HF_AUTH.loginSettings.changePasswordContainer}`),
            testPasswordStrength
        );
        UserManager.renderLogOutForm(
            HF_AUTH.loginSettings.logOutFormId, 
            "", 
            window.location.href, 
            $(`#${HF_AUTH.loginSettings.logOutContainer}`));
        UserManager.renderTimeout(HF_AUTH.loginSettings.sessionTimeout * 1000);
        initPageLoggedIn(videoWindow);
    }
    if (HF_AUTH.showWelcome) {
        dialogs.showAlert({
            message: "Thank you for registering with Historic Films!" +
                "  A confirmation message has been sent to your email address." +
                "  Use the link in that message to sign in and activate your account."+
                "  Once signed in, you'll be able to save your bins,"+
                " speak to an Historic Films researcher, and order footage.",
            title: "Registration Complete!"
        });
    }

}

function initPageNotLoggedIn() {
    //authentication methods
    $(document).on('click', '.signInLink', function () {
        const form = $("#signInForm"),
            container = form.closest('.headerContainer'),
            btn = $(this);

        if (!hfCommon.cookiesEnabled()) {
            hfCommon.showCookiesAlert();
            return;
        }

        form.addClass('overlay');
        $('html').addClass('dialogOpen');
        if (container.find(".myLinks .ui-widget-overlay").length === 0) {
            container.find(".myLinks").append($('<div/>').addClass('ui-widget-overlay').on('click',
                function () {
                    hfCommon.hideMenu();
                }));
        }
        form.find('.close').off().click(function () {
            hfCommon.hideMenu();
        });

        if (btn.closest('.ui-dialog-content').length) {
            $('.ui-dialog-content:visible').dialog('close');
        }
        return false;
    });

    $('.registerLink').on('touchdown, mousedown', function () {
        if (!hfCommon.cookiesEnabled()) {
            hfCommon.showCookiesAlert();
            return false;
        }
    });
}

function initPageLoggedIn(videoWindow) {
    $('.showOrders').on('click', function () {
        import('./ordersDialog.js').then((module) => {
            module.ordersDialog.show($("#ordersDialog"));
        });
    });
     $("#contactDialog").dialog({
        title: "Contact Information",
        buttons: {
            'OK': function () {
                $(this).dialog('close');
            }
        },
        autoOpen: false
    });

    $('.menu .contactInfo').on('click', function () {
        dialogs.openDialog($("#contactDialog"));
        return false;
    });   
}

export const init = {

    init: function() {
        window.fbAsyncInit = function() {
            FB.init({
                appId      : HF_GLOBALS.facebookAppId,
                xfbml      : true,
                version    : 'v2.10'
            });
            FB.AppEvents.logPageView();
        };

        (function(d, s, id){
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {return;}
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

        //AJAX Setup 
        $.ajaxSetup({
            url: "u.ajax.php",
            type: "POST",
            beforeSend: function (jqHR, settings) {
                // console.log(jqHR);
                // if (settings.container) {
                //  settings.container.waitingBox({
                //      autoStart: true
                //  });
                // } else if (!$('#layoutContainer').data('disableWaiting')) {
                //  $('#layoutContainer').waitingBox({
                //      autoStart: true
                //  });
                // }
            },
            complete: function (jqHR, settings) {
                
                // if (settings.container) {
                //  settings.container.trigger('stopWaiting');
                // } else if (!$('#layoutContainer').data('keepWaiting')) {
                //  $('#layoutContainer').trigger('stopWaiting');
                // }
            }
        });
        $(document).ajaxError(function (event, jqXHR, ajaxSettings, thrownError) {
            if (jqXHR.status !== 0 && thrownError != "unauthorized" && jqXHR.responseText) {
                var message = 'An unexpected error occurred.';
                switch (jqXHR.status) {
                case 500:
                    message = fieldDisplay.nl2br(jqXHR.responseText).replace('!CONTACT!', HF_GLOBALS.contactMarkup);
                    break;
                case 404:
                case 403:
                    message = jqXHR.statusText;
                    break;
                }
                dialogs.showAlert({
                    message: message,
                    title: 'Error'
                });
            }
            $('#layoutContainer').trigger('stopWaitng');
        });

        /*if (browserCheck.isIOSHandHeld() && browserCheck.getIOSVersion() < 8) {
            (function () {
                var refresh = function () { // This function forces Safari to redraw the page and triggers the window resize event that was removed.
                    if (!$(".focused").length) {
                        window.scrollTo(document.body.scrollLeft, document.body.scrollTop);
                        hfCommon.resizeHandler();
                    }
                };
                setInterval(function () {
                    var landscape = window.orientation == 90 || window.orientation == -90,
                        viewportHeight = window.innerHeight,
                        // The testHeight is based off the actual screen height multiplied by a fudge factor that allows for the status and mini address bar in portrait mode and the fullscreen in landscape mode.
                        testHeight = verge.viewportH() * 0.85;
                    if ((ios7bars === null || ios7bars === false) && ((landscape && viewportHeight < testHeight) ||
                            (!landscape &&
                                viewportHeight < testHeight))) {
                        ios7bars = true;
                        refresh();
                    } else if ((ios7bars === null || ios7bars === true) && ((landscape && viewportHeight >
                            testHeight) || (!landscape &&
                            viewportHeight > testHeight))) {
                        ios7bars = false;
                        refresh();
                    }
                }, 500); // To get a better response to the bars being toggled you can lower the interval however the lower you go the more page performance on a whole may slow down.
            })();
        }*/

    },
    initPage: function() {
        let dialogResizeTimeout = null;
        //HF_AUTH.init();
        initAuth(videoWindow);
        window.videoWindow = $("#videoWindow");
        
        
        videoWindowWidget.init();
        videoWindow.videoWindow({
            clipChangedCallback: function (reelData) {
                if (!reelData.clip) {
                    pushState.updateState({
                        query: history.state.query,
                        reelData: reelData
                    });
                }
                //reelData.mlt = null;
                commonSearch.showMoreLikeThis(reelData, videoWindow.find('.mlt'));
            },
            closeCallback: function () {
                pushState.updateState({
                    query: history.state.query
                });
                videoWindow.removeClass('forceFixedHeader');
                $("#videoWindow .headerTop").prependTo($('#pageHeader'));
                if ($('video.background-video').length) {
                    $('video.background-video')[0].play();
                }
                $("#videoWindow #footer").appendTo($("body"));
            },
            openWindowCallback: function() {
                let headerContainer = videoWindow.find('.headerContainer').toggleClass('fixed', $('#pageHeader').hasClass('fixed'));

                $('#pageHeader .headerTop').prependTo(headerContainer);
                headerContainer.data('origTop', headerContainer.position().top);
                if (headerContainer.hasClass('fixed')) {
                    videoWindow.find('.videoWindowContents').scrollTop(1);
                } else {
                     videoWindow.find('.videoWindowContents').scrollTop(0);
                }
                if ($('video.background-video').length) {
                    $('video.background-video')[0].pause();
                }
                $("#footer").appendTo(videoWindow.find('.videoWindowContents'));

            },
            showContentCallback: function (data) {
              
                if (data.clip) {
                    //don't update the state if the current clip is already in the state.
                    if (history.state && history.state.clip && history.state.clip.id == data.clip &&
                        history.state.clip.clipType == data.clip.clipType) {
                        return true;
                    }
                   
                    pushState.updateState($.extend(history.state ? history.state : {}, {
                        clip: data.clip,
                        //bin: data.bin,
                        reelData: null
                    }));
                }
               
                return true;
            },
            getTranscript: function (tape) {
                return $.ajax({
                    url: `transcripts/${tape.tapeId}_${tape.id}_transcript.json`,
                    dataType: 'json',
                    global: false
                });
            },
            logShare: function(tapeId, type) {
            $.ajax({
                    data: {
                        action: 'logShare',
                        tapeId: tapeId,
                        type: type
                    }
                });
            },
            addNewClipToBin: bins.addClipToBin,
            getClip: bins.getClip,
            showMailClipDialog: contactForm.showMailClipDialog,
            trackEvent: trackEvent,
            saveClipChanges: function (reelData) {
                return bins.saveClipChanges(reelData);
            },
            initialized: function() {
                
                window.videoWindow.addClass('initialized');
            }
        });
        $('.searchBar').searchForm({
            performSearch: function (data) {
                if (page && typeof (page.performSearch) == 'function') {
                    videoWindow.videoWindow('close');
                    console.log(data);
                    page.performSearch(data, false);
                } else {
                    Object.keys(data).forEach((k) => !data[k] && delete data[k]);
                    const urlparams = new URLSearchParams(data);
                    const str = urlparams.toString();
                    location.href = 'search/' + (str ? `?${str}` : '');
                }
            },
            getSuggestions: function (data) {
                $('#layoutContainer').data('disableWaiting', true);
                return $.ajax({
                    data: {
                        q: fieldDisplay.escapeString(data.q)
                    },
                    url: 'suggest/',
                    dataType: 'json',
                    method: 'GET'
                }).always(function () {
                    $('#layoutContainer').data('disableWaiting', '');
                });
            },
        });

        /*largestViewPort = {
            width: Math.min(verge.viewportW(), verge.viewportH()),
            height: Math.max(verge.viewportW(), verge.viewportH()),
            orientation: Math.abs(window.orientation % 180)
        };*/
        $('html').addClass('pageLoad');
        if ($(window).scrollTop() != 0 || window.innerWidth <= HF_GLOBALS.fixedHeaderThreshold) {
            console.log('fixed header');
            $(".headerContainer").addClass('fixed');
            $('html').addClass('fixedHeader');
        }
        $('html').removeClass('pageLoad');
        
        $('.backButton').click(function() {
            history.go(-1);
        })

        //initialize cookies
        Cookies.set('screenResolution', screen.width + "x" + screen.height);

        
        const resizeObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
                hfCommon.resizeHandler(entry);
            }
        });
        resizeObserver.observe($('#layoutContainer')[0]);

       
        //Menus
        $('a.external').click(function () {
            window.open(this.href, '_blank');
            hfCommon.hideMenu();
            return false;
        });

        $('.showMenu').on('click', function (e) {
            var $this = $(this),
                menu = $this.next(),
                isBinMenu = $this.hasClass('showBins'),
                topResult = null;

            if (isBinMenu && $('body').hasClass('home') && $this.closest('#pageHeader').length) {
                return;
            }

            e.preventDefault();
            if (menu.hasClass('overlay')) {
                hfCommon.hideMenu(menu);
                return false;
            }
            menu.find('a, button, .controlElement').attr('tabindex', '0');
            //TODO: refactor this code so it's not a mess!!
            if (isBinMenu) {

                topResult = hfCommon.getScrollTopResult();

                menu.addClass('sidebar');
                $('html').addClass('binSidebarOpen');
                menu.one('transitionend', function () {
                    if ($('html').scrollTop() == 0) {
                        return;
                    }
                    if (topResult) {
                        hfCommon.scrollToResult(topResult);
                    }
                    $('.scroller-initiailzed').scroller('handleResize');
                     hfCommon.resizeHandler();
                });
            } else {
                if ($this.hasClass('showAccount')) {
                    $('html').addClass('accountMenuOpen');
                }
                $this.closest('.myLinks').append($('<div/>').addClass('ui-widget-overlay')).one('click',
                    function () {

                        hfCommon.hideMenu();
                    });
                dialogs.setDialogOpen();
                 hfCommon.resizeHandler();
            }

            menu.addClass('overlay');
            menu.find('.menuContents').scrollTop(0);

            return false;
        });
        $('.menu').find('a, button, .controlElement').attr('tabindex', '-1');

        //the fixed header and footer are getting positioned wrong in ios when the
        //virtual keyboard appears
        if (browserCheck.isAndroid() || browserCheck.isIOS() /* && browserCheck.getIOSVersion() < 8 */ ) {

            var scrollTop;
            $(document).on({
                focus: function (e) {

                    $('html').addClass('disableScrollPageLoad');
                    $(e.target).addClass('focused');
                    if (browserCheck.isAndroid()) {
                        $('body').data('origScrollTop', $(document).scrollTop());
                    }

                    if (!e.relatedTarget || !$(e.relatedTarget).is('input, textarea')) {
                        scrollTop = $(document).scrollTop();
                    } else {
                        ignoreResize = true;
                    }

                    if (browserCheck.getIOSVersion() >= 8) {
                        return;
                    }
                    //if (isIOS() || isAndroidFirefox()) {
                    hfCommon.fixFixed(e);
                    //} 

                },
                blur: function (e) {

                    if (!e.relatedTarget || !$(e.relatedTarget).is('input, textarea')) {
                        $(document).scrollTop(scrollTop);
                    }
                    if (browserCheck.isIOS() && browserCheck.getIOSVersion() >= 8) {
                        return;
                    }
                    if ($(e.relatedTarget).is(
                            'input, textarea, #editComment, label, .eip_saveButtonOverlay, .eip_cancelButtonOverlay, .eip_saveButton, .eip_cancelButton'
                        )) {
                        $(e.target).removeClass('focused');
                        return;
                    }
                    hfCommon.resetFixedPos();
                }
            }, 'input:not(:radio), textarea'); //:text, input[type=email], :password, input[type=radio], textarea, select, :checkbox'
        }
        if (browserCheck.isIOS() /* && browserCheck.getIOSVersion() < 8 */ ) {

            //add touchstart event to force IOS to reset touch positions.
            //see: http://stackoverflow.com/questions/8809706/ios-5-safari-bug-with-html-touch-events-on-positionfixed-div
            $('body').on('touchstart', function () {});
        }

        $(".headerContainer").each(function () {
            $(this).data('origTop', 0 /*$(this).offset().top */ );
        });

        $(".buyMinutes").on('click', function () {
            location.href = 'buyMinutes';
        });

        $(window).scroll(function () {
            if ($('body').hasClass('home')) {
                return;
            }
            const scrollTop = $(window).scrollTop();
            if (window.innerWidth > HF_GLOBALS.fixedHeaderThreshold) {
                $("#pageHeader.headerContainer").each(function () {
                    let el = $(this);
                    const origTop = el.data('origTop');
                    if (scrollTop > origTop) {
                        el.addClass('fixed');
                        $('html').addClass('fixedHeader');
                    } else {
                        el.removeClass('fixed');
                        $('html').removeClass('fixedHeader');
                    }
                });
            }
        });
        videoWindow.find('.videoWindowContents').scroll(function () {
            const playerWindow = $(this),
                scrollTop = playerWindow.scrollTop();
            if (window.innerWidth > HF_GLOBALS.fixedHeaderThreshold) {
                playerWindow.find(".headerContainer").each(function () {
                    let el = $(this);
                    if (videoWindow.hasClass('forceFixedHeader')) {
                        videoWindow.removeClass('forceFixedHeader');
                        return;
                    }
                    const origTop = el.data('origTop');
                    if (scrollTop > origTop) {
                        el.addClass('fixed');
                        $('html').addClass('fixedHeader');
                    } else {
                        el.removeClass('fixed');
                        $('html').removeClass('fixedHeader');
                    }

                });
            }
        });
        
        

        $(document).on('keydown', function (e) {
            if (e.keyCode == 13) {
                let focus = $(':focus');
                if (focus.hasClass('controlElement')) {
                    focus.trigger('click');
                    if (focus.hasClass('editable')) {
                        return false;
                    }
                }
            }
        });

        
        $.when(function () {
            if (typeof (window.initPage) == 'function') {
                return window.initPage();
            }
            return true;
        }()).done(function () {
            
            $('.binMenuGroup').waitingBox();
            $.when(pushState.init()).always(function () {
                 console.log('finished pushstate init');
                $.when(bins.init()).always(function () {
                    console.log('finished bins init');
                    $('.binMenuGroup').trigger('stopWaiting');
                    pushState.updateClipNavData();

                    //modules loaded last
                    contactForm.init(videoWindow);
                    //videoOutput.init(videoWindow);
                }).fail(function() {
                    console.log('failed bins init');
                });
            });
        }).fail(function(e) {
            console.log(e);
            $('#layoutContainer').trigger('stopWaiting').data('keepWaiting', false);
        });
    }
}
