import {fieldDisplay} from './fieldDisplay.js';

function isTimecode(str) {
	return str.match(/^(\d+:)?(\d+:)?(\d+:)?\d+$/);
}

function truemod(num, mod) {
	return (mod + (num % mod)) % mod;
}

function roundNumber(num, dec) {
	var result = Math.round(num * Math.pow(10, dec)) / Math.pow(10, dec);
	return result;
}

function offsetToTimecode(startTimecode, offset) {
		var startFrame = startTimecode.toFrameNumber();
		var frameNumber = truemod(Math.round(offset * startTimecode.fps) + startFrame,
			Math.round((HF_GLOBALS.MAX_TIMECODE_SECONDS + 1) * startTimecode.fps), HF_GLOBALS.MAX_TIMECODE_SECONDS);
		return frameNumberToTimecode(frameNumber, startTimecode.fps);
	}
	/*
	D = frameNumber div 17982
	M = frameNumber mod 17982
	frameNumber +=  18*D + 2*((M - 2) div 1798)

	    (If -2 div 1798 doesn't return 0, you'll have to special-case M = 0 or 1.)

	frames  =    frameNumber mod 30
	seconds =   (frameNumber div 30) mod 60
	minutes =  ((frameNumber div 30) div 60) mod 60
	hours   = (((frameNumber div 30) div 60) div 60) mod 24

	*/
function positionToTimecode(pos, fps) {
	pos = truemod(pos, HF_GLOBALS.MAX_TIMECODE_SECONDS + 1);
	var frameNumber = Math.round(pos * fps);
	return frameNumberToTimecode(frameNumber, fps);
}

function frameNumberToTimecode(frameNumber, fps) {

	if (fps == 29.97 || fps == 23.976) {
		//CONVERT A FRAME NUMBER TO DROP FRAME TIMECODE
		//Code by David Heidelberger, adapted from Andrew Duncan
		//Given an int called framenumber and a double called framerate
		//Framerate should be 29.97, 59.94, or 23.976, otherwise the calculations will be off.
		//http://www.davidheidelberger.com/blog/?p=29

		var dropFrames = Math.round(fps * 0.066666); //Number of frames to drop on the minute marks is the nearest integer to 6% of the framerate
		var framesPerHour = Math.round(fps * 60 * 60); //Number of frames in an hour
		var framesPer24Hours = framesPerHour * 24; //Number of frames in a day - timecode rolls over after 24 hours
		var framesPer10Minutes = Math.round(fps * 60 * 10); //Number of frames per ten minutes
		var framesPerMinute = (Math.round(fps) * 60) - dropFrames; //Number of frames per minute is the round of the framerate * 60 minus the number of dropped frames

		if (frameNumber < 0) //Negative time. Add 24 hours.
		{
			frameNumber = framesPer24Hours + frameNumber;
		}

		//If frameNumber is greater than 24 hrs, next operation will rollover clock
		frameNumber = frameNumber % framesPer24Hours; //% is the modulus operator, which returns a remainder. a % b = the remainder of a/b

		var d = Math.floor(frameNumber / framesPer10Minutes);
		var m = frameNumber % framesPer10Minutes;

		//In the original post, the next line read m>1, which only worked for 29.97. Jean-Baptiste Mardelle correctly pointed out that m should be compared to dropFrames.
		if (m > dropFrames) {
			frameNumber = frameNumber + (dropFrames * 9 * d) + dropFrames * Math.floor((m - dropFrames) /
				framesPerMinute);
		} else {
			frameNumber = frameNumber + dropFrames * 9 * d;
		}

		var frRound = Math.round(fps);
		var frames = frameNumber % frRound;
		var seconds = Math.floor(frameNumber / frRound) % 60;
		var minutes = Math.floor(Math.floor(frameNumber / frRound) / 60) % 60;
		var hours = Math.floor(Math.floor(Math.floor(frameNumber / frRound) / 60) / 60);

		return fieldDisplay.pad(hours, 2) + ":" + fieldDisplay.pad(minutes, 2) + ":" + fieldDisplay.pad(seconds, 2);
	} else {
		return fieldDisplay.formatTimecode(frameNumber / fps);
	}
}


export function Timecode(str, fps) {
	if (str == 'END') {
		this.timecode = str;
	} else if (!str) {
		this.timecode = '00:00:00';
	} else {
		this.timecode = str;
	}
	if (fps === undefined) {
		this.fps = HF_GLOBALS.defaultFps;
	} else {
		this.fps = fps;
	}
	this.toPosition = function (includeFrames, round) {
		if (this.timecode == "END") {
			return Number.MAX_VALUE;
		}
		if (includeFrames === undefined) {
			includeFrames = true;
		}
		if (round === undefined) {
			round = true;
		}
		var pos = this.toFrameNumber() / this.fps;
		//round to 2 decimal places.
		if (round) {
			pos = Math.round(pos * 100) / 100;
		}

		if (!includeFrames) {
			return Math.round(pos);
		} else {
			return pos;
		}
	};

	this.toFrameNumber = function () {
		if (this.timecode == "END") {
			return Number.MAX_VALUE;
		}
		var frameNumber = 0;
		var parts = this.timecode.split(/:|;/);
		var hours = Number(parts[0]);
		var minutes = Number(parts[1]);
		var seconds = Number(parts[2]);
		var frames = parts.length == 4 ? Number(parts[3]) : 0;

		if (this.fps == 29.97 || this.fps == 23.976) {

			//CONVERT DROP FRAME TIMECODE TO A FRAME NUMBER
			//Code by David Heidelberger, adapted from Andrew Duncan
			//http://www.davidheidelberger.com/blog/?p=29
			var dropFrames = Math.round(this.fps * 0.066666); //Number of drop frames is 6% of framerate rounded to nearest integer
			var totalMinutes = 60 * hours + minutes;
			frameNumber = (Math.ceil(this.fps) * 60 * 60) * hours + (Math.ceil(this.fps) * 60) *
				minutes +
				Math.ceil(this.fps) * seconds + frames -
				dropFrames * (totalMinutes - Math.floor(totalMinutes / 10));

		} else {
			frameNumber = ((60 * 60 * hours) + (60 * minutes) + seconds) * this.fps + frames;

		}
		return frameNumber;

	};
	this.isBefore = function (tc) {
		return this.toPosition(false) < tc.toPosition(false);
	};
	this.isAfter = function (tc) {
		return this.toPosition(false) > tc.toPosition(false);
	};
	this.isEqualTo = function (tc) {
		return this.toPosition() == tc.toPosition();
	};
	this.isInRange = function (tc1, tc2, wrap) {
		if (wrap === undefined) {
			wrap = false;
		}
		var start = tc1.toPosition(false);
		var end = tc2.toPosition(false);
		var thisPos = this.toPosition();
		if (wrap && start > end) {
			end += HF_GLOBALS.MAX_TIMECODE_SECONDS;
			if (thisPos < start) {
				thisPos += HF_GLOBALS.MAX_TIMECODE_SECONDS;
			}
		}
		return thisPos >= start && thisPos <= end;
	};
}

export const timecodeFunctions = {
	isTimecode: isTimecode,
	offsetToTimecode: offsetToTimecode,
	positionToTimecode: positionToTimecode,
	frameNumberToTimecode:frameNumberToTimecode
};
