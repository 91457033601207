require("jquery-ui/ui/widgets/spinner");

$.widget("badmath.touchSpinner", $.ui.spinner, {

	_create: function () {
		this._super("_create");

		this._on({
			"touchstart .ui-spinner-button": function (event) {
				event.preventDefault();
				//$(event.target).trigger('mousedown');
				if (this._start(event) === false) {
					return;
				}

				this._repeat(null, $(event.currentTarget).hasClass("ui-spinner-up") ? 1 : -1, event);
				return false;
			},
			"touchend .ui-spinner-button": function (event) {
				$(event.target).trigger('mouseup');
				return false;
			}
		});
	},

	stop: function () {
		this._stop();
	}

});
