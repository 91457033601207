import {fieldDisplay} from './fieldDisplay.js';
import {bins} from './bins.js';
import {page} from './common.js';
import {pushState} from './pushState.js';
import {dialogs} from './dialogs.js';


require('./jquery.hoverVideoPlayer.js');
require('./jquery.scroller.js');
require('./facets.js');

require('./timecodes.js');


//filters
const filterSettings = {
	'genre': {
		fieldName: 'genre',
		'tag': 'genre',
		type: 'list',
		hashOrder: 0
	},
	'color': {
		fieldName: 'color',
		tag: 'color',
		type: 'list',
		hashOrder: 1
	},
	'specialty': {
		fieldName: 'specialtyCollection',
		tag: 'specialty',
		type: 'boolean',
		hashOrder: 2
	},
	'online': {
		fieldName: 'hasVideo',
		tag: 'online',
		type: 'boolean',
		hashOrder: 3
	},
	'year': {
		field1: 'yearStart',
		field2: 'yearEnd',
		tag: 'year',
		type: 'multiFieldRange',
		hashOrder: 4
	},
	'res': {
		fieldName: 'resolution',
		tag: 'res',
		type: 'simpleRange',
		hashOrder: 5
	}
	//'keywords': {fieldName: 'keywordId', tag: 'k'},
	//'keyword_json': {fieldName: 'keyword_json', 'tag': ''}
};

function escapeFqValue(val) {
	//force val to be a string.
	val = val + '';
	val = val.replace(/\u200B/g, "");
	return '"' + val.replace(/\"/g, '\\"') + '"';
}

function getMultifieldRangeFq(filter) {
	var q,
		start = !filter.value[filter.field1] ? '*' : filter.value[filter.field1],
		end = !filter.value[filter.field2] ? '*' : filter.value[filter.field2];

	//include the null values with any range query that does not specify both the start and end

	/*
	Note on including null value:
	    for some rease on the query "-field:[* TO *] OR field:[start TO end]" does not work -- returns no results
	    however the logically equivalent -(field:[* TO *] AND -field:[start TO end]) does work as expected
	*/
	if (start == "*") {
		q = '-(-(' + filter.field2 + ":[* TO " + end + "]" +
			" OR " + filter.field1 + ":[* TO " + end + "]" + ") AND " +
			filter.field2 + ":[* TO *])";
	} else if (end == "*") {
		q = '-(-(' + filter.field1 + ":[" + start + " TO *]" +
			" OR " + filter.field2 + ":[" + start + " TO *]" + ") AND " + filter.field1 +
			":[* TO *])";
	} else {
		q = filter.field2 + ":[" + start + " TO " + end + "] OR " +
			filter.field1 + ":[" + start + " TO " + end + "]";
	}
	return q;

}

function getFqPart(filter) {
	var fq = '';
	if ((filter.type != 'boolean' && !filter.value) || (Array.isArray(filter.value) && !filter.value
			.length))
		return '';

	if (filter.tag) {
		fq = '{!tag=' + filter.tag + '}';
	}
	switch (filter.type) {
	case 'multiFieldRange':
		fq += getMultifieldRangeFq(filter);
		break;
	case 'simpleRange':
		fq += filter.fieldName + ':[' + filter.value + ' TO *]';
		break;
	case 'boolean':
		fq += filter.fieldName + ':' + filter.value;
		break;
	default:
		if (Array.isArray(filter.value)) {
			$.each(filter.value, function (index, value) {
				fq += (index != 0 ? ' OR ' : '') + filter.fieldName + ':' + escapeFqValue(value);
			});
		} else {
			fq += filter.fieldName + ':' + escapeFqValue(filter.value);
		}

	}
	return fq;
}

function getFq(filters) {
	var fq = [];
	$.each(filters, function (index, filter) {
			if (!filter) {
				return true;
			}
			if (filter.fq) {
				fq.push(filter.fq);
			} else {
				var part = getFqPart(filter);
				if (part)
					fq.push(part);
			}
		});
		// if (fq.length == 1) {
		//     return fq[0];
		// }
	return fq;
}

function getFiltersFromHash(queryFilters) {
	var filters = {};
	$.each(queryFilters, function (index, values) {
		var valuesList = [],
			arr = [],
			obj_values = {};
		if (!values) {
			return true;
		}
		switch (index) {
		case 'year':
			arr = values.split('-');
			arr = arr.map(val => val == '*' ? '' : val);
			obj_values = {};
			obj_values[filterSettings[index].field1] = arr[0];
			obj_values[filterSettings[index].field2] = arr[1];
			filters[filterSettings[index].tag] = $.extend({},
				filterSettings[index], {
					value: obj_values
				});
			break;
		default:

			arr = values.split(',');
			if (arr && arr.length) {

				arr.forEach(function (value) {
					//remove quotes
					value = value.replace(/\"/g, '');
					if (!value) {
						return true;
					}
					return valuesList.push(value);
				});

				filters[filterSettings[index].tag] = $.extend({},
					filterSettings[index], {
						value: filterSettings[index].type == 'boolean' || filterSettings[index].type ==
							'simpleRange' ? valuesList[0] : valuesList
					});
			}
		}
	});
	return filters;

}

function truncateExcerpt(el) {
	var excerpt = el.find('.excerpt').html(),
		excerptFont = fieldDisplay.getCanvasFont(el.find('.excerpt')[0]),
		yearWidth = el.find('.year').outerWidth(),
		elipsisWidth = fieldDisplay.getTextDims(' ...', excerptFont).width,
		emStart = excerpt.indexOf('<em>'),
		emPadding = 0,
		str = '',
		substr = '',
		excerptWidth = 0,
		excerptStart = 0,
		availableWidth = el.find('.info').width() - elipsisWidth;

	if (emStart != -1) {
		emPadding = parseInt(el.find('em').first().css('padding-right')) + parseInt(el.find('em').first()
			.css('padding-left'));
		str = excerpt.substring(0, excerpt.indexOf('</em>'));
		str = str.replace(/<em>/g, '');

		excerptWidth = fieldDisplay.getTextDims(str, excerptFont).width + emPadding;
		while (yearWidth + excerptWidth > availableWidth) {
			if (excerptStart >= emStart) {
				break;
			}
			excerptStart++;
			var subStr = str.substr(excerptStart);
			excerptWidth = fieldDisplay.getTextDims('...' + subStr, excerptFont).width + emPadding;
		}
		if (excerptStart) {
			el.find('.excerpt').html('&hellip;' + excerpt.substr(excerptStart));
		}
	}
}

//More like this
function getMltDescription(data) {
	if (!data) {
		return '';
	}
	
	return $('<span/>').addClass('mltInfo')
		.append(' like ')

	.append(
		$('<a/>').on('click', function () {
			idSearch({
				reelId: data.reelId,
				log: data.logId,
				in : data.in,
				out: data.out
			});
		}).append(function () {
			if (data.hasThumbnails) {
				return fieldDisplay.getThumbnailFromIndex(data.reelId, data.thumbnailNum, 'small', "thumb");
			}
		}).append($('<span/>').html(fieldDisplay.escapeString(data.desc)))
	);
}

function getMltMatchInfo(searchData) {
	let match = null;

	if (searchData?.paginationInfo) {
		return searchData.paginationInfo;
	} 
	match = searchData.q.match(HF_GLOBALS.mltSearchRegexp);
	if (match) {
		let dfd = new $.Deferred();
		$.ajax({
			data: {
				action: 'getMltdescription',
				reelId: match[1],
				logId: match[2] !== undefined ? match[2] : '',
				in : match[3] !== undefined ? match[3] : '',
				out: match[4] !== undefined ? match[4] : ''
			},
			dataType: 'json'
		}).done(function (data) {

			dfd.resolve(data);
		});
		return dfd.promise();
	} else {
		return null;
	}
}

function getMoreLikeThis(reelData) {

	var dfd = new $.Deferred(),
		docId = 'tape_' + reelData.reelId + (reelData.log ? '_log_' + reelData.log : ''),
		fq = '',
		data = {};
		console.log('getMoreLikeThis', reelData)
	if (!reelData.log && (reelData.in || reelData.out || reelData.clip)) {
		data.clipText = fieldDisplay.getMltSearchExcerpt($("#videoWindow").videoWindow('getClipText'),
			HF_GLOBALS.maxSearchTextLength);
	}

	if (reelData.mlt && reelData.mlt.responseHeader.params.q == data.clipText) {
		console.log('mlt unchanged');
		//reelData.unchanged = true;
		dfd.resolve(reelData);
	} else {
		fq = getFq(reelData.mlt_filters);
		fq.push('-reelId:' + reelData.reelId);
		data = $.extend(data, {
			q: docId, //'id:' + docId + (str ? ' ' + str : ''),
			'hl.q': reelData.q,
			fq: fq,
			df: 'text'
		});
		console.log('mlt query', $.param(data, true));
		$.ajax({
			url: 'mlt/',
			data: $.param(data, true),
			dataType: 'json',
			method: 'GET'
		}).done(function (res) {
			console.log('mlt', res);

			if (reelData.log && res?.response && res.response.numFound == 0) {
				let reelDataCopy = JSON.parse(JSON.stringify(reelData));
				reelDataCopy.log = '';
				$.when(getMoreLikeThis(reelDataCopy)).done(function (reelRes) {
					reelData.mlt = reelRes.mlt;
					dfd.resolve(reelData);
				});
			} else {
				reelData.mlt = res;
				dfd.resolve(reelData);
			}
		});
	}
	return dfd.promise();
}

function showMoreLikeThis(reelData, mltContainer) {
	var dfd = new $.Deferred(),
		deferreds = [];

	$.when(getMoreLikeThis(reelData)).done(function (reelData) {
		if (reelData?.unchanged && !mltContainer.find(
				'.results').is(':empty')) {
			delete reelData.unchanged;
			dfd.resolve(reelData);
			return;
		}

		mltContainer.find('.results').empty().show();
		var res = reelData.mlt;

		if (res && res?.response && res.response?.docs && 
				Array.isArray(res.response.docs) && res.response.docs.length) {

			if (mltContainer.find('.filter.genre .list').hasClass('scroller-initialized')) {
				mltContainer.find('.filter.genre .list').scroller('destroy');
			}
			mltContainer.find('.filter').trigger('update', [res, reelData.mlt_filters]);

			mltContainer.find('.filter.genre .list').scroller();

			$.each(res.response.docs, function (index, doc) {
				deferreds.push(
					commonSearch.getResult(doc, res?.highlighting?.[doc.id] ?? {}).done(
						function (result) {
							mltContainer.find('.results').append(result);
							truncateExcerpt(result);
						})
				);

			});
			$.when.apply(null, deferreds).always(function () {
				mltContainer.show();
				dfd.resolve(reelData);
			});
		} else {
			mltContainer.hide();
			dfd.resolve(reelData);
		}
	});

	return dfd.promise();
}

//displaying video window

function renameSearchFields(tape) {
	if (tape?.reelId)
		if (!tape.searchDocId) {
			tape.searchDocId = tape.id;
		}
	tape.id = tape.reelId;
	return tape;
}

function getResultHelper(doc, highlighting, suffix) {
	var el = $('<div/>'),
		anchor = $('<a/>').addClass('resultLink'),
		excerptPreference = [`logText${suffix}`, `description${suffix}`],
		excerpt = '',
		yearWidth = 0,
		excerptWidth = 0,
		thumbnailEl = '',
		thumbnailUrl = '',
		mltQuery = `like:${doc.reelId}` + (doc.logId ? `-${doc.logId}` : ''),
		thumbnailEl = fieldDisplay.getThumbnailFromIndex(doc.reelId, doc.docType == 'log' ?doc.logThumbnailNum : doc.reelThumbnailNum, 'large', "thumb");
		thumbnailUrl = fieldDisplay.getThumbnailUrlFromIndex(doc.reelId, doc.docType == 'log' ?doc.logThumbnailNum : doc.reelThumbnailNum, 'large');

		
	el.addClass(
			`result ${doc.docType} ${doc.id} ${doc.hasVideo && !doc.temporailyUnavailable ? 'hasVideo' : 
			 ( 'noVideo' + (doc.hasThumbnails ? '' : ' noThumb'))}`
		)
		.addClass(doc.specialtyCollection ? 'specialtyCollection' : '')
		.addClass(doc.temporailyUnavailable ? 'temporailyUnavailable' : '')
		//.addClass(doc.mos ? 'mos' : '')
		.addClass(doc.aspectRatio ? `aspect${doc.aspectRatio * 10000}` : 'aspect0')
		.data({
			id: doc.id,
			reelId: doc.reelId,
			log: doc.logId
		});
	anchor.attr('href', 
		`search/?q=${history?.state?.query?.q ?? ''}&reel=${doc.reelId}${
			doc.docType == 'log' ? `&log=${doc.logId}` : ''}`);

	el.append(anchor);

	excerptPreference.forEach(field => {
		if (highlighting && highlighting.hasOwnProperty(field)) {
			excerpt = highlighting[field];
			return false;
		}
	});
	if (!excerpt) {
		excerptPreference.forEach(field => {
			if (doc.hasOwnProperty(field) && doc[field] !== '') {
				excerpt = doc[field];
			}
		});
	}

	if (Array.isArray(excerpt)) {
		excerpt = excerpt[0];
	}

	if (doc.hasThumbnails) {
		anchor.append($('<span  class="thumb"/>').append(thumbnailEl));
	} else {
		anchor.append($('<span class="thumb" />'));
	}

	anchor.append(fieldDisplay.getNotice(doc));

	anchor.append($('<span/>').addClass('info')
		.append($('<span/>').addClass('excerpt')
			.append($('<span class="tapeId"/>').text(doc.tapeId))
			.append($('<span class="text"/>').append(fieldDisplay.escapeString(excerpt))))
		.append($('<span/>').addClass('excerptFull')
			.append($('<span class="tapeId"/>').text(doc.tapeId))
			.append($('<span class="text"/>').append(fieldDisplay.escapeString(excerpt))))
	);

	if (doc.hasVideo && !doc.temporailyUnavailable && !doc.specialtyCollection) {
		anchor.hoverVideoPlayer({
			videoPath: fieldDisplay.getVideoPath(doc),
			poster: thumbnailUrl,
			offset: doc.offset
		});
	}

	el.append($('<a/>').data('id', doc.id).addClass('showMlt')
		.attr('alt', 'Show more like this..')
		.html('More like this&hellip;')
		.attr('href', `search/?q=${mltQuery}`)
		.on('click', function (e) {
			e.stopPropagation();
			e.preventDefault();
			const matchInfo = {
				desc: fieldDisplay.getMltSearchExcerpt(anchor.find('.excerpt .text').text(), HF_GLOBALS.maxSearchTextLength),
				reelId: doc.reelId,
				logId: doc.logId,
				thumbnailNum: doc.docType == 'log' ? doc.logThumbnailNum : doc.reelThumbnailNum,
				hasThumbnails: doc.hasThumbnails
			};

			$.when($('.searchBar').searchForm('performSearch', {
				q: mltQuery,
				searchType: 'all',
				paginationInfo: matchInfo
			})).done(function () {

			});
		})
	);
	el.append($('<a/>').addClass('saveClip').addClass('button').attr('alt', 'Add to Bin').text('Bin')
		.on('click', function (e) {
			e.stopPropagation();
			e.preventDefault();

			console.log(doc);
			$.when(bins.addClipToBin({
				tapeId: doc.reelId,
				logId: doc.logId,
				binId: 'current'
			})).done(function () {

			});
		}));
	return el;
}

function getResult(doc, highlighting, suffix) {
	suffix = suffix || '';
	var dfd = new $.Deferred();
	
	$.when(fieldDisplay.getVideoIsAvailable(fieldDisplay.getVideoPath(doc),!doc.hasVideo)).fail(function () {
		if (doc.hasVideo) {
			doc.temporailyUnavailable = true;
		}
		dfd.resolve(getResultHelper(doc, highlighting, suffix));
	}).done(function () {
		doc.temporailyUnavailable = false;
		dfd.resolve(getResultHelper(doc, highlighting, suffix));
	});

	return dfd.promise();
}

function showReel(reelData, listInfo) {
	const res = reelData.res;

	var doc = null,
		dfd = new $.Deferred();

	if (res && res?.response && res.response?.docs && Array.isArray(
			res.response.docs) &&
		res.response.docs.length > 0) {

		doc = res.response.docs[0];

		if (doc.log && !doc.hasOwnProperty('parsedJson')) {
			doc.log = JSON.parse(doc.log);
		}
		if (doc?.thumbnails && !doc.hasOwnProperty('parsedJson')) {
			doc.thumbnails = JSON.parse(doc.thumbnails);
		}
		doc.parsedJson = true;
		doc = renameSearchFields(doc);
		if (typeof (page.integrateHighlighting) == 'function') {
			doc = page.integrateHighlighting(doc, res.highlighting[doc.searchDocId]);
		}

		$.when(showTape(doc, reelData, listInfo)).done(function (data) {
			dfd.resolve(data);
		}).fail(function () {
			dfd.reject();
		});

	} else {
		if (!res || res.error) {
			dialogs.showAlert({
				title: "Error",
				message: 'There was an error executing your query.' + (res ? res.error.msg : '')
			});
		} else {
			dialogs.showAlert({
				title: "Error",
				message: 'Reel not found',
				width: 350
			});
		}
		dfd.reject();
	}
	return dfd.promise();
}

function initReelMoreLikeThis(reelData, mltContainer) {
	mltContainer.data('filter', {});
	mltContainer.find('.filter.genre').listFacet({
		fieldName: 'genre',
		tag: 'genre',
		selectedClass: 'selected',
		selectedList: true,
		drawItem: function (value, count) {
			var filter = mltContainer.data('filter');
			var facet = $('<div/>').addClass('facet').addClass(function () {

					if (reelData?.mlt_filters && reelData.mlt_filters?.genre &&
						reelData.mlt_filters.genre.value.indexOf(value) != -1) {
						return 'selected';
					}
				}).data('value', value)
				.append($('<span/>').addClass('name').html(fieldDisplay.displayField(value)))
				.append($('<span/>').addClass('count').html(fieldDisplay.formatNumber(count)));
			return facet;
		},
		callback: function (value) {
			var filter = {};
			if (value && value.length) {
				filter = {
					genre: $.extend({}, filterSettings.genre, {
						value: value
					})
				};
			}
			mltContainer.data('filter', filter);
			reelData.mlt_filters = filter;
			reelData.mlt = null;
			showMoreLikeThis(reelData, mltContainer);
			pushState.updateState({
				query: history.state.query,
				reelData: reelData
			});
		}
	});

}

function appendExtraReelData(data) {
	var dfd = new $.Deferred();
	if (!data?.mlt_filters) {
		data.mlt_filters = {};
	}

	initReelMoreLikeThis(data, $('#videoWindow .mlt'));
	$.when(showMoreLikeThis(data, $('#videoWindow .mlt'))).done(function (data) {
		$.when(showMoreFromReel(data, $('#videoWindow .reelMatches'))).done(function (data) {
			dfd.resolve(data);
		}).fail(function () {
			dfd.reject();
		});
	}).fail(function () {
		dfd.reject();
	});
	return dfd.promise();
}

function showTape(doc, reelData, listInfo) {
	var dfd = new $.Deferred();

	reelData = reelData == undefined ? {} : reelData;
	reelData = $.extend({
		q: '',
		reelId: doc.id,
		log: '',
		in : '',
		out: '',
		mlt_filters: {}
	}, reelData);
	listInfo = listInfo == undefined ? {} : listInfo;

	
	$.when($("#videoWindow").videoWindow('showReel',
		doc,
		reelData,
		listInfo

	)).done(function (data) {
		$.when(appendExtraReelData(data)).done(function () {
			$("#videoWindow .result").each(function () {
				truncateExcerpt($(this));
			});
			dfd.resolve(data);
		});
		
	}).fail(function () {
		dfd.reject();
	});
	return dfd.promise();
}

function idSearch(reelData, listInfo, page) {
	console.log('idSearch', arguments);
	$('#videoWindow').videoWindow('open', true);
	listInfo = listInfo ? listInfo : null;
	//const page = container.find('.results').data('page');
	reelData = $.extend({
		q: '',
		reelId: '',
		log: '',
		in : '',
		out: '',
		mlt_filters: {}
	}, reelData);

	var dfd = new $.Deferred(),
		data = $.extend({}, {
			'hl.q': reelData.q,
			fq: ['reelId:' + reelData.reelId, 'docType:reel'],
			df: $('.df input:checked').val()
		});

	if (!reelData.reelId) {
		return;
	}

	if (reelData.res) {
		//show stored result
		$.when(showReel(reelData, listInfo)).done(function (data) {
			pushState.updateState({
				query: history.state.query,
				reelData: data
			});
			dfd.resolve(data);
		}).fail(function() {
			dfd.reject();
		});
	} else {
		$.ajax({
			url: 'idsearch/',
			data: $.param(data, true),
			dataType: 'json',
			method: 'GET'
		}).done(function (res) {
			console.log('id search', res);
			reelData.res = res;
			$.when(showReel(reelData, listInfo)).done(function (data) {
				pushState.updateState({
					query: page !== undefined ? $.extend({}, history.state.query, {
						page: page
					}) : history.state.query,
					reelData: data
				});
				dfd.resolve(data);
			}).fail(function () {
				$('#videoWindow').videoWindow('close', true);
				dfd.reject();
			});
		}).fail(function () {
			$('#videoWindow').videoWindow('close', true);
			dfd.reject();
		});
	}
	return dfd.promise();
}

function displayMoreFromReel(reelData, container) {
	const res = reelData.moreFromReel;
	let dfd = new $.Deferred(),
		deferreds = [];

	// if (container.find('.results').hasClass('slick-slider')) {
	//     container.find('.results').slick('unslick');
	// }
	if (container.find('.results').hasClass('scroller-initiailzed')) {
		container.find('.results').scroller('destroy');
	}
	container.find('.results').empty().hide();
	if (res && res?.response && res.response?.docs &&
		Array.isArray(res.response.docs) && res.response.docs.length) {
		container.show();
		$.each(res.response.docs, function (index, doc) {
			deferreds.push(
				commonSearch.getResult(doc, res?.highlighting ? res.highlighting[doc.id] : {})
				.done(function (result) {
					container.find('.results').append(result);
					truncateExcerpt(result);
				})
			);

		});
		$.when.apply(null, deferreds).always(function () {
			container.find('.results').show().scroller();
			dfd.resolve();
		});

	} else {
		container.hide();
		dfd.resolve();
	}
	return dfd.promise();
}

function showMoreFromReel(reelData, container) {

	var dfd = new $.Deferred(),
		docId = 'tape_' + reelData.reelId + (reelData.log ? '_log_' + reelData.log : ''),
		data = $.extend({}, {
			q: reelData.q ? reelData.q : '',
			fq: ['reelId:' + reelData.reelId, '-id:' + docId],
			df: 'text'
		});

	if (reelData.moreFromReel) {
		$.when(displayMoreFromReel(reelData, container)).always(function () {
			dfd.resolve(reelData);
		});

	} else {
		if (!reelData.q || reelData.q.match(HF_GLOBALS.mltSearchRegexp)) {
			container.hide();
			dfd.resolve(reelData);
		} else {
			$.ajax({
				url: 'reelsearch/',
				data: $.param(data, true),
				dataType: 'json',
				method: 'GET'
			}).done(function (res) {
				reelData.moreFromReel = res;
				console.log('more from reel', res);
				$.when(displayMoreFromReel(reelData, container)).always(function () {
					dfd.resolve(reelData);
				});
			});
		}
	}
	return dfd.promise();
}

function showResultHelper(el, reelData, navData) {
	var list = el.closest('.results'),
		dfd = new $.Deferred();
	console.log(reelData.log, navData.index);
	$.when(idSearch(
		reelData, {
			index: navData.index,
			numResults: navData.numResults,
			objName: navData.objName,
			itemName: navData.itemName,
			searchText: navData.searchText,
			inlike: navData.inlike,
			// navDescription: $('<span/>').html(
			// 	`<span class="viewing">Viewing </span><span class="verb">${navData.itemName} </span>` +
			// 		`<span class="count">${fieldDisplay.formatNumber(navData.index)} ${navData.navSuffix} </span>`)
			// 	.append(navData.searchText ? `<span class="for">for </span><span class="searchText">${navData.searchText}</span>` : ''),
			step: function(dir) {
				return stepNav(dir,el,navData);
			}
		},
		el.closest('.results').data('page')
	)).done(function (data) {
		el.data('reelData', JSON.parse(JSON.stringify(data)));
		dfd.resolve();
	}).fail(function() {
		console.log("Failed Search:", reelData);
		dfd.reject();
	});
	return dfd.promise();
}

function stepNav(dir, el, navData) {
	var fixedHeader = $('#videoWindow .headerContainer').hasClass('fixed'),
		stepDfd = new $.Deferred().always(function () {
			if (fixedHeader) {
				$('html').addClass('fixedHeader');
				$('.headerContainer').addClass('fixed');
				$('#videoWindow .videoWindowContents').scrollTop(1);
				$('#videoWindow').removeClass('forceFixedHeader');
			}
		});
		if (fixedHeader) {
			$('#videoWindow').addClass('forceFixedHeader');
		}
	switch (dir) {
	case 'next':

		if (navData.resultsList.length && navData.index < navData.numResults - 1) {
			showResultHelper(el, navData.resultsList[navData.index], $.extend(navData, {
				index: navData.index + 1
			}));
		} else {
			if (el.nextAll(navData.resultSelector).length) {
				$.when(showResult(el.nextAll(navData.resultSelector).first(), navData.resultSelector)).always(function () {
					stepDfd.resolve();
				});

			} else if (typeof (page.performSearch) == 'function' && navData.index - 1 < navData.numResults) {
				$.when(page.performSearch($.extend({}, history.state.query, {
						page: list.data('page') + 1,
						res: null
					}), true, false, false))
					.done(function () {
						$.when(showResult(list.find(navData.resultSelector).first(), navData.resultSelector)).always(function () {

							stepDfd.resolve();
						});
					});
			}
		}
		break;
	case 'prev':
		if (navData.resultsList.length && navData.index > 1) {
			showResultHelper(el, navData.resultsList[navData.index - 1], $.extend(navData, {
				index: navData.index - 1
			}));
		} else {
			if (el.prevAll(navData.resultSelector).length) {
				$.when(showResult(el.prevAll(navData.resultSelector).first(), navData.resultSelector)).always(function () {
					stepDfd.resolve();
				});
			} else if (typeof (page.performSearch) == 'function' && navData.index > 0) {

				$.when(page.performSearch($.extend({}, history.state.query, {
						page: list.data('page') - 1,
						res: null
					}), true, false, false))
					.done(function () {
						$.when(showResult(list.find(navData.resultSelector).last(), navData.resultSelector)).always(function () {
							stepDfd.resolve();
						});
					});
			}
		}
		break;
	}
	return stepDfd.promise();
}

function getResultNavData(el, resultSelector) {
	resultSelector = resultSelector || el.data('resultselector') || '.result';
	let list = el.closest('.results'),
	jsList = false,
		navData = {
			index: (list.data('start') ? list.data('start') : 0) + el.prevAll(resultSelector).length + 1,
			numResults: list.data('numResults') ? list.data('numResults') : list.find(resultSelector).length,
			inlike: '',
			resultsList: [],
			itemName: 'match',
			objName: 'result',
			resultSelector: resultSelector,
			searchText: history.state?.query?.q
		};
	if (list.data('navdata')) {
		navData = $.extend(navData, list.data('navdata'));
	}
	if (el.closest('.mlt').length) {
		navData.inlike = `like ${el.closest('.mlt').data('tapeId')}`;
		jsList = true;

	} else {
		if (el.closest('.reelMatches').length) {
			navData.inlike = `in ${el.closest('.reelMatches').data('tapeId')}`;
			jsList = true;
		}

		if (history.state.query.paginationInfo) {
			navData.inlike = getMltDescription(history.state.query.paginationInfo);
		} else {
			//navData.navSuffix.append(history.state.query.q ? ` for ${history.state.query.q}` : '');
			// if (history.state.query.q) {
			// 	navData.navSuffix.append(' for ').append($('<span/>').addClass('searchText').text(
			// 		`${history.state.query.q}`));
			// }
		}
	}

	if (jsList) {
		list.find('.result').each(function () {
			let listEl = $(this);
			if (listEl.data('reelData')) {
				navData.resultsList.push(listEl.data('reelData'));
			} else {
				navData.resultsList.push({
					reelId: listEl.data('reelId'),
					log: listEl.data('log'),
					q: history.state.query.q
				});
			}
		});
	}
	navData.step = function(dir) {
		return stepNav(dir,el,navData);
	};
	return navData;
}

function showResult(el, resultSelector) {
	var list = el.closest('.results'),
		reelData = {},
		jsList = false,
		navData = navData || getResultNavData(el, resultSelector);

	if (el.data('reelData')) {
		reelData = el.data('reelData');
	} else {
		reelData = {
			reelId: el.data('reelId'),
			log: el.data('log'),
			q: history.state.query.q
		};
	}
	return showResultHelper(el, reelData, navData);

}

export const commonSearch = {
	filterSettings: filterSettings,
	getFq: getFq,
	getFiltersFromHash: getFiltersFromHash,
	getResult: getResult,
	showResult: showResult,
	truncateExcerpt: truncateExcerpt,
	idSearch: idSearch,
	showTape: showTape,
	appendExtraReelData: appendExtraReelData,
	getMltDescription: getMltDescription,
	getMltMatchInfo: getMltMatchInfo,
	showMoreLikeThis: showMoreLikeThis,
	getResultNavData:getResultNavData
};


