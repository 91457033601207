//(c)2014 Bad Math Inc. (http://www.badmath.com/)
import {Spinner} from 'spin.js';


jQuery.fn.waitingBox = function (options) {

	var settings = jQuery.extend({
		assignclass: 'waiting',
		autoStart: true,
		cancellable: false,
		boxClassName: 'waitingBox',
		image: 'template/logoReel.svg'
	}, options);

	return this.each(function () {
		var $this = jQuery(this);
		var spinner = null;

		if (!$this.data('watingBoxInitialized')) {
			$this.on('startWaiting', function () {
				if ($this.data('isWaiting')) {
					return;
				}
				$this.data('isWaiting', true);
				$this.addClass(settings.assignclass + (settings.cancellable ? ' cancellable' : '' ));
				spinner = $('<div/>')
					.addClass(settings.boxClassName)
					.append($('<img/>').attr('src', settings.image));
				$this.append(spinner);
			});

			$this.on('stopWaiting', function () {
				
				$this.data('isWaiting', false);
				$this.removeClass(settings.assignclass);
				spinner.remove();
			});
		}
		$this.data('watingBoxInitialized', true);
		if (settings.autoStart) {
			$this.trigger('startWaiting');
		}
	});

};
