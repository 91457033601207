
import {bins} from './bins.js';
import {commonSearch} from './commonSearch.js';
import {fieldDisplay} from './fieldDisplay.js';
import {binPage} from '../binPage/binPageFunctions.js';
const Cookies = require('js-cookie');

let stateHandlerDfd = null,
	defaultSearchType = '';

function handleClipState(state) {
	let clipEl = null;

	if (state.clip && state.clip.hashId) {
		
		$('.binMenu').find('li.clip').each(function () {
			if ($(this).data('hash') == state.clip.hashId) {
				clipEl = $(this);
				return false;
			}
		});
		if (clipEl) {
			return bins.showMenuClip(clipEl);
		}

		$('.clipDetails').each(function () {
			if ($(this).data('hash') == state.clip.hashId) {
				clipEl = $(this);
				return false;
			}
		});

		if (clipEl) {
			clipEl.trigger('click');
			//return binPage.showClip(clipEl);
		} else {
			return $("#videoWindow").videoWindow('showClip', {
				clipHashId: state.clip.hashId,
				isWritable: true,
				clipType: state.clip.clipType
			}, null).done(function (clip) {
				
				state.clip = clip.clip;
				history.replaceState(state, null);
				console.log('replace state', state);
				$.when(function () {
					if (typeof (commonSearch) !== 'undefined') {
						return commonSearch.appendExtraReelData(clip);
					} else {
						return true;
					}
				}());
			});
		}
	}
}

function handleReelState(data) {

	if (data.clip && data.clip.hashId) {
		return handleClipState(data);
	} else if (data.reelData && data.reelData.reelId) {
		var clipInfo = $("#videoWindow").videoWindow('getClipInfo');
		if (clipInfo && clipInfo.reelId == data.reelData.reelId) {
			if (data.reelData.in || data.reelData.out) {
				$("#videoWindow").videoWindow('setOffsets', data.reelData.in, data.reelData.out);
			}
			return true;
		}
		var el = getSearchResultEl(data);
		if (el.length) {
			return commonSearch.showResult(el, el.data('resultselector') ?? '.result');
		}
		return commonSearch.idSearch(data.reelData);
	} else {
		return $("#videoWindow").videoWindow('close');
	}
}

function getSearchResultEl(data) {
	let el = $(
		`.result.tape_${data.reelData.reelId}${data.reelData.log ? '_log_' + data.reelData.log : ''}`
	);
	if (el.length) {
		el.data('reelData', data.reelData);
	} else  {
		let id =
			`tape_${data.reelData.reelId}${data.reelData.in ? '_in_' + data.reelData.in : ''}${data.reelData.out ? '_out_' + data.reelData.out : ''}`;
		id = id.replaceAll('.', '_');
		el = $(`.result#${id}`);
		if (el.length) {
			el.data('reelData', data.reelData);
		}
	}
	return el;
}



export let pushState = {
	hideReel: false,
	updateClipNavData: function(state) {
		state = state || history.state;
		console.log('update nav data', state);
		if (state.reelData && state.reelData.reelId) {
			let el = getSearchResultEl(state);
			if (el.length) {
				$("#videoWindow").videoWindow('showNavigationControls', commonSearch.getResultNavData(el));
			}
		} else if (state.clip) {
			let listInfo = null;
			if (binPage.isBinPage()) {
				listInfo = binPage.getListInfo(state.clip.id);
			}
			if(!listInfo  && bins.isInitialized()) {
				listInfo = bins.getListInfo(state.clip.id);
			}
			if (listInfo) {
				$("#videoWindow").videoWindow('showNavigationControls', listInfo);
			}
		}
	},
	init: function (settings) {

		settings = $.extend({
			defaultSearchType: 'all'
		}, settings);

		defaultSearchType = settings.defaultSearchType;
		const params = new Proxy(new URLSearchParams(window.location.search), {
			get: (searchParams, prop) => searchParams.get(prop),
		});
		var keys = ['q', 'searchType', 'genre', 'color', 'specialty',
			'online', 'year', 'res', 'page', 'reel', 'log', 'in', 'out', 'mlt_genre', 'clip', 'clipType'
		];
		var data = {};

		keys.forEach((key, i) => {
			data[key] = params[key] ?? '';
		});

		let initialState = {
			query: {
				initialSearch: true,
				q: data.q,
				searchType: data.searchType,
				page: data.page ? data.page : 1,
				filters: commonSearch.getFiltersFromHash({
						'genre': data.genre,
						'color': data.color,
						'specialty': data.specialty,
						'online': data.online,
						'year': data.year,
						'res': data.res
					})

			},
			reelData: {
				q: data.q,
				reelId: pushState.hideReel ? window.reelId : data.reel,
				log: data.log,
				in : fieldDisplay.roundToTwoDecimals(data.in),
				out: fieldDisplay.roundToTwoDecimals(data.out),
				mlt_filters: commonSearch.getFiltersFromHash({
						genre: data.mlt_genre
					})
			},
			clip: {
				hashId: data.clip,
				clipType: data.clipType,
				binId: data.bin
			}
		};
		history.replaceState(initialState, null);

		window.addEventListener('popstate', function (e) {
			pushState.handleState(e.state);
		});
		return pushState.handleState(initialState);

	},
	updateState: function (state) {
		state = $.extend({
			query: null,
			reelData: null,
			clip: null
		}, state);
		const {
			query,
			reelData,
			clip
		} = state;
		var params = {},
			f;

		//don't update the hash while anothe hash is being handled.
		if (stateHandlerDfd && stateHandlerDfd.state() == 'pending') {
			console.log('Blocking state update because a state change is currently being handled.',
				arguments);
			return;
		}
		console.log('updateState', arguments);

		if (query) {
			if (query.q) {
				params.q = query.q;
			}
			if (query.searchType && query.searchType != defaultSearchType) {
				params.type = query.searchType;
			}
			f = Object.values(query.filters).sort((a, b) => {
				return a.hashOrder - b.hashOrder;
			});
			f.forEach(filter => {
				//$.each(f, function(filter) {
				var valueStr;
				if (Array.isArray(filter.value) && filter.value.length) {

					valueStr = filter.value.map(function (value) {
						// Wrap each element of the dates array with quotes
						return '"' + value + '"';
					}).join(",");

					if (valueStr) {
						params[filter.tag] = valueStr;
					}
				} else if (filter.tag == 'year') {
					var arr = Object.values(filter.value);
					arr = arr.map(val => val == '' ? '*' : val);
					params[filter.tag] = (arr[0] + '-' + arr[1]);
				} else {
					params[filter.tag] = filter.value;
				}
			});

			if (query.page !== undefined && query.page != 1) {
				params.page = query.page;
			}
		}

		if (reelData) {
			if (!pushState.hideReel)
				params.reel = reelData.reelId;
			if (reelData.log) {
				params.log = reelData.log;
			}
			if (reelData.in) {
				params.in = fieldDisplay.roundToTwoDecimals(reelData.in);
			}
			if (reelData.out) {
				params.out = fieldDisplay.roundToTwoDecimals(reelData.out);
			}
			if (reelData.mlt_filters) {
				f = Object.values(reelData.mlt_filters);
				f.forEach(filter => {
					if (Array.isArray(filter.value) && filter.value.length) {

						let valueStr = filter.value.map(function (value) {
							// Wrap each element of the dates array with quotes
							return '"' + value + '"';
						}).join(",");

						if (valueStr) {
							params[`mlt_${filter.tag}`] = valueStr;
						}
					} else {
						params[`mlt_${filter.tag}`] = filter.value;
					}
				});
			}
		}
		if (clip) {
			
			params.clip = clip.hashId;
			if (clip.clipType != 'Clip') {
				params.clipType = clip.clipType;
			}
		}
		const urlparams = new URLSearchParams(params);
		var url = urlparams.toString();
		history.pushState(state, null, `${window.location.pathname}${url ? `?${url}` : ''}`);
	},
	handleState: function (state) {
		var stateHandlerDfd = new $.Deferred(),
			clipEl = null;
			
			$.when(handleReelState(state)).always(function () {
				$.when(function() { 
					if (typeof (window.handlePageState) == 'function') {
						return window.handlePageState(state);
					}
				}()).always(function () {
					if (state.clip && state.clip.binId) {
						console.log('set current bin:' + state.clip.binId);
						Cookies.set('currentBinId', state.clip.binId);
					} 
					pushState.updateClipNavData(state);

					stateHandlerDfd.resolve();
				});
			});
		// if (typeof (window.handlePageState) == 'function') {
		// 	$.when(window.handlePageState(state)).done(function () {
		// 		$.when(handleReelState(state)).always(function () {
		// 			stateHandlerDfd.resolve();
		// 		});

		// 	}).fail(function () {
		// 		stateHandlerDfd.resolve();
		// 	});
		// } else {
		// 	$.when(handleReelState(state)).always(function () {
		// 		stateHandlerDfd.resolve();
		// 	});
		// }
		return stateHandlerDfd.promise();
	}
}

