import {browserCheck} from './browserCheck.js';

require("jquery-ui/ui/widgets/dialog");

export const dialogs = {
	showAlert: function(params) {
		if (typeof (params) == 'string') {
			params = {
				message: params
			};
		}
		const defaults = {
			//message: '',
			title: 'Alert!',
			buttonText: 'OK',
			buttonClickHandler: function () {},
			width: 450,
			height: 'auto',
			openEvent: function (obj) {},
			closeOnEscape: true
		};
		var dialog,
			dialog_buttons = {};

		params = $.extend(defaults, params);

		dialog = $('<div/>')
			.hide()
			.attr('title', params.title)
			.attr('id', 'alertDialog')
			.html(params.message);
		$('body').append(dialog);

		dialog_buttons[params.buttonText] = function () {

			if (typeof (params.buttonClickHandler) == 'function') {
				params.buttonClickHandler();
			}
			$(this).dialog("close");
		};
		dialogs.importDialogWidget().then(() => {
			dialog.dialog({
				modal: true,
				autoOpen: false,
				resizable: false,
				width: params.width ? params.width : '',
				height: params.height ? params.height : 'auto',
				title: params.title,
				open: function () {
					params.openEvent(dialog);
				},
				buttons: dialog_buttons,
				close: function () {
					dialog.remove();
				}
			});
			dialogs.openDialog(dialog);
		});
		
	},

	showConfirm: function(params) {
		var defaults = {
			message: '',
			title: 'Confirm',
			successButtonText: 'OK',
			successHandler: function () {},
			cancelButtonText: 'Cancel',
			cancelHandler: function () {},
			closeEvent: function () {},
			extraButtons: {},
			autoClose: true,
			width: 350,
			height: null,
			openEvent: function (event, ui) {},
			dialogClass: ''
		};
		params = $.extend(defaults, params);

		var dialog = $('<div/>')
			.hide()
			.attr('title', params.title)
			.html(params.message);

		$('body').append('dialog');

		var dialog_buttons = {};
		dialog_buttons[params.successButtonText] = function () {

			if (typeof (params.successHandler) == 'function') {
				params.successHandler();
			}
			if (params.autoClose) {
				dialog.dialog("close");
			}
		};
		dialog_buttons[params.cancelButtonText] = function () {

			if (typeof (params.cancelHandler) == 'function') {
				params.cancelHandler();
			}
			if (params.autoClose) {
				dialog.dialog("close");
			}
		};
		if (params.autoClose && params.extraButtons) {
			$.each(params.extraButtons, function (name, callback) {
				params.extraButtons[name] = function () {
					callback();
					dialog.dialog("close");
				};
			});
		}

		dialog_buttons = $.extend(dialog_buttons, params.extraButtons);
		dialogs.importDialogWidget().then(() => {
			dialog.dialog({
				modal: true,
				autoOpen: false,
				resizable: false,
				width: params.width ? params.width : '',
				height: params.height ? params.height : 'auto',
				title: params.title,
				open: function () {
					params.openEvent(dialog);
				},
				dialogClass: params.dialogClass,
				buttons: dialog_buttons,
				close: function () {
					dialog.remove();
					params.closeEvent();
				}
			});
			dialogs.openDialog(dialog);
		});
		
	},

	openDialog: function(el) {

		if (!el.data() || !el.data().hasOwnProperty('hasExtraEvents')) {

			el.on("dialogopen", function () {
				// if (!$('.ui-dialog-titlebar-close').closest('.closeContainer').length) {
				// 	$('.ui-dialog .ui-dialog-titlebar-close').wrap($('<div/>')
				// 		.addClass('closeContainer')
				// 		.on('click', function () {
				// 			el.dialog('close');
				// 		}));
				// }

				dialogs.setDialogOpen(el.closest('.ui-dialog'));
				el.closest('.ui-dialog').find('.ui-dialog-titlebar-close').on('touchstart', function() {
					el.dialog('close');
					return false;
				});
				$('.ui-widget-overlay').on('click', function () {
					el.dialog('close');
				});
			});

			el.on('dialogclose', function (event) {
				var dialog = el.closest('.ui-dialog');
				dialog.find('.ui-button').removeClass('clicked');
				dialog.removeData('origWidth')
					.removeData('origHeight');
				dialogs.setDialogClosed(dialog);
				$('html').removeClass('dialogOpen');

			});
			el.prepend($('<span autofocus>').addClass('hidden'));
			el.data('hasExtraEvents', true);
		}
		el.parent().css({
			position: "fixed"
		}).end().dialog('open');
		el.parent().find('.ui-button').on({

			click: function () {
				var button = $(this);
				$(this).addClass('clicked');
				setTimeout(function () {
					button.removeClass('clicked');
				}, 500);
			}
		});

	},

	closeDialog: function(el) {
		$('html').removeClass('fullScreenDialogOpen');
		el.dialog('close')
	},

	setDialogClosed: function(dialog) {
		if (dialog !== undefined) {
			dialog.removeClass('fullScreen');
		}
		var visibleDialogCount = $('.ui-dialog, menu.overlay').filter(':visible').length;
		var fullscreenDialogCount = $('.ui-dialog.fullScreen, .menu.overlay').filter(':visible').length;

		//if ($('html').hasClass('dialogOpen')) {
			if (!fullscreenDialogCount) {

				$('html').removeClass('fullScreenDialogOpen');
				$('.ui-dialog, .overlay').removeClass('fullScreen');
				if ($('body').data().hasOwnProperty('scrolltop')) {

					$(window).scrollTop($('body').data('scrolltop'));
					$('body').removeData('scrolltop', '');
				}
			}
			if (!visibleDialogCount) {
				$('html').removeClass('dialogOpen disableScrollPageLoad');

			}
		//}

	},

	setDialogOpen: function(dialog) {
		$('html').addClass('dialogOpen disableScrollPageLoad');
		if (dialog) {
			dialogs.adjustDialogPosition(dialog);
			setTimeout(function() {dialog.scrollTop(0)}, 1);
			setTimeout(function () {
				
				if (browserCheck.isIOS() || browserCheck.isAndroid() || !dialog.find('.autofocus').length) {

					if (dialog.find('.ui-dialog-buttonset .ui-button').length) {
						dialog.find('.ui-dialog-buttonset .ui-button').focus();
					} else {
						$('.ui-dialog-titlebar-close').focus();
					}
				} else {

					dialog.find('.autofocus').first().focus().select();
				}
			});
		}
	},

	adjustDialogPosition: function(openDialogs) {
		let windowWidth = window.innerWidth,
			windowHeight = window.innerHeight;
		openDialogs.each(function() {
			let dialog = $(this),
				width = dialog.width(),
				height = dialog.height(),
				outerWidth = dialog.outerWidth(),
				outerHeight = dialog.outerHeight(),
				dims = dialog.data('dims') ?? null,
				fullScreen = false;
			
			if (dialog.hasClass('fullScreen') || outerWidth >= windowWidth) {
				if (dialog.hasClass('fullScreen')) {
					if (windowWidth > dims.outerWidth) {
						fullScreen = false;
						dialog.removeClass('fullScreen')
						$('html').removeClass('fullScreenDialogOpen');
						outerWidth = dialog.outerWidth();
						
					} else {
						fullScreen = true;
					}
				} else {
					dialog.data('dims', {width: width, height: height, outerWidth: outerWidth, outerHeight: outerHeight})
						.css('height', 'auto')
						.addClass('fullScreen')
					$('html').addClass('fullScreenDialogOpen');
					fullScreen = true;
				}
			} 
			if (!fullScreen) {
				dialog.find('.ui-dialog-content').css('height', '');
				let outerHeight = dialog.outerHeight(),
					height = dialog.height(),
					padding = outerHeight- height,
					origHeight = dialog.data('origHeight') ?? null,
					contentHeight = dialog.find('.ui-dialog-content').height() + 
						dialog.find('.ui-dialog-titlebar').outerHeight() +
						dialog.find('.ui-dialog-buttonpane').outerHeight();
					
				if (outerHeight >= windowHeight - 2 * HF_GLOBALS.minDialogVerticalDistance) {
				
					if (origHeight && origHeight < windowHeight - padding - 2 * HF_GLOBALS.minDialogVerticalDistance) {
						outerHeight = origHeight + padding;
						//dims.outerHeight = outerHeight;
						dialog.data('origHeight', null).css({height: origHeight, overflow: 'auto'});
					} else {
						outerHeight = windowHeight - 2 * HF_GLOBALS.minDialogVerticalDistance;
						//dims.outerHeight = outerHeight;
						dialog.data('origHeight', height).css({height: outerHeight - padding, overflow: 'auto'});
					}
				} else if (contentHeight > height) {
					dialog.css('height', Math.min(
						windowHeight - padding - 2 * HF_GLOBALS.minDialogVerticalDistance, 
						contentHeight
					));
					outerHeight = dialog.outerHeight();
					dialog.find('.ui-dialog-content').css('height', outerHeight - padding -
						dialog.find('.ui-dialog-titlebar').outerHeight() -dialog.find('.ui-dialog-buttonpane').outerHeight());
					
				}
				let top = (windowHeight - outerHeight) / 2,
					left = (windowWidth - outerWidth) /2

				dialog.css({
					top: top,
					left:left
				})
			}
		});
	},

	adjustDialogPositionOld: function(dialog, animationDuration, minDistance, forceFullScreen) {

		if (animationDuration === undefined) {
			animationDuration = 300;
		}
		if (minDistance === undefined) {
			minDistance = 0;
		}
		if (forceFullScreen === undefined) {
			if (browserCheck.isIOS() && $(".ui-dialog-content", dialog).hasClass('iosFullScreen')) {
				//forceFullScreen = true;
			} else {
				forceFullScreen = false;
			}
		}

		if (dialog === undefined || !dialog) {
			return;
		}
		var pos = dialog.position();
		var bottomDistance = minDistance ? minDistance : (dialog.hasClass('videoPlayer') ? 0 : 25);
		var topDistance = minDistance ? minDistance : (dialog.hasClass('videoPlayer') ? 0 : 25);
		var leftDistance = minDistance;
		var rightDistance = minDistance;
		var scrollbarWidth = 16;
		var fullScreen = false;

		if (dialog.length !== 0 && pos) {
			var windowWidth = verge.viewportW(); //$(window).width();
			var windowHeight = window.innerHeight ? window.innerHeight : verge.viewportH(); //$(window).height();

			if (dialog.width() >= windowWidth || (dialog.hasClass('videoPlayer') && dialog.height() >=
					windowHeight) || forceFullScreen) {
				//the fullScreen_fixFixed is used in IOS when the keyboard is open so the top
				//position can be overriden when fixing the position of the dialog.
				if (!forceFullScreen && (dialog.hasClass('fullScreen') || dialog.hasClass('fullScreen_fixFixed')) &&
					dialog.data('origWidth') < windowWidth &&
					dialog.data('origHeight') < windowHeight) {
					dialog.removeClass('fullScreen').removeClass('fullScreen_fixFixed');

					$('html').removeClass('fullScreenDialogOpen');
					fullScreen = false;
				} else if (!dialog.hasClass('fullScreen') && !dialog.hasClass('fullScreen_fixFixed')) {
					if (!dialog.data('origWidth')) {
						dialog
							.data('origWidth', dialog.width())
							.data('origHeight', dialog.height());

					}
					dialog.addClass('fullScreen');
					$('html').addClass('fullScreenDialogOpen');
					$('body').data('scrolltop', $(document).scrollTop());
					fullScreen = true;
				} else {
					fullScreen = true;
				}

			}
			if ($(".ui-dialog-content", dialog).length) {
				var scrollTop = $(".ui-dialog-content", dialog).scrollTop();
				if ($(".ui-dialog-content", dialog)[0].scrollHeight > $(".ui-dialog-content", dialog).innerHeight()) {
					$(".ui-dialog-content", dialog).height($(".ui-dialog-content", dialog)[0].scrollHeight)
						.css('overflow-x', 'display');

				}

				if (!fullScreen && pos.top + dialog.height() + bottomDistance > windowHeight) {
					var difference = bottomDistance + (pos.top + dialog.height()) - windowHeight;
					if (pos.top - difference < topDistance) {
						var newHeight = windowHeight -
							(topDistance + bottomDistance +
								$(".ui-dialog-titlebar", dialog).outerHeight() +
								$(".ui-dialog-buttonpane", dialog).outerHeight() +
								parseInt($(".ui-dialog-content", dialog).css('padding-top')) +
								parseInt($(".ui-dialog-content", dialog).css('padding-bottom'))
							);
						$(".ui-dialog-content", dialog).height(newHeight);

						if ($(".ui-dialog-content", dialog)[0].scrollHeight > $(".ui-dialog-content", dialog)[0].offsetHeight) {
							if (!dialog.hasClass('scrollbars')) {
								dialog.width(dialog.width() + scrollbarWidth)
									.addClass('scrollbars');
							}
						} else {
							if (dialog.hasClass('scrollbars')) {
								dialog.width(dialog.width() - scrollbarWidth)
									.removeClass('scrollbars');
							}
						}
					}
				}
				$(".ui-dialog-content", dialog).scrollTop(scrollTop);
			}

			if (dialog.hasClass('videoPlayer')) {
				dialog.css({
					top: '',
					left: '',

				});
			}

			if (!fullScreen) {
				//ios safari is reporting the window height incroorecty. the correct value
				//is in window.innerheight, so use that instead.
				var height = window.innerHeight ? window.innerHeight : $(window).height();

				dialog.animate({
						top: Math.max(topDistance, Math.floor((height - dialog.height()) / 2)) + 'px',
						left: Math.max(leftDistance, Math.floor(($(window).width() / 2) - (dialog.width() / 2))) +
							'px'
					},
					animationDuration,
					'swing',
					function () {

					}
				);
			}

		}
	},

	importDialogWidget: function() {
		return import("jquery-ui/ui/widgets/dialog");
	}
};