//import {$,jQuery} from 'jquery';
require("jquery-ui/ui/widget");
(function ($, window, document, undefined) {

	$.widget("badmath.hoverVideoPlayer", {

		_video: null,
		_shouldPlay: false,
		_isPlaying: false,
		_playTimeout: null,

		options: {
			debug: true,
			videoPath: '',
			poster: '',
			offset: 0,
			end: 0,
			delay: 500
		},

		//helpers
		_log: function () {
			if (this.options.debug)
				console.log(arguments);
		},

		_playVideo: function () {
			let plugin = this;

			plugin._video.show();
			plugin._video.on('loadeddata', function () {
				if (!plugin._shouldPlay) {
					return;
				}
				try {
					$.when(plugin._video[0].play()).done(function () {

					});
				} catch (ex) {
					console.log(ex);
				}

			});
			plugin._video.find('source').attr('src', plugin.options.videoPath);
			plugin._video[0].load();
		},

		_appendVideoPlayer: function () {
			let plugin = this;

			plugin._video = $('<video muted="muted"  />')
				.attr('preload', 'none')
				.attr('poster', plugin.options.poster)
				.append($('<source/>')
					.attr('src', '')
					.attr('type', 'video/mp4')
				).on('play', function () {
					plugin._video[0].currentTime = plugin.options.offset;
					plugin._isPlaying = true;
				});
			if (plugin.options.end > 0) {
				plugin._video.on('timeupdate', function () {
					if (plugin._video[0].currentTime > plugin.options.end) {
						plugin._video[0].currentTime = plugin.options.offset;
					}
				});
			}
			plugin.element.append(plugin._video);
		},

		_initEvents: function () {
			let plugin = this;

			plugin.element.on({
				mouseenter: function () {
					if (plugin.element.hasClass('touched')) {
						return;
					}
					clearTimeout(plugin._playTimeout);
					plugin._playTimeout = setTimeout(function () {
						plugin._shouldPlay = true;
						plugin._playVideo();
					}, plugin.options.delay);

				},
				mouseleave: function () {

					plugin.stopPlayer();
				},
				touchstart: function() {
					plugin.element.addClass('touched');
				}
			});
		},

		_create: function () {

			this._appendVideoPlayer();
			this._initEvents();

		},

		// Destroy an instantiated plugin and clean up
		// modifications the widget has made to the DOM
		_destroy: function () {},

		// Respond to any changes the user makes to the
		// option method
		_setOption: function (key, value) {
			//store the option
			this._super("_setOption", key, value);
		},

		stopPlayer: function() {
			let plugin = this;
			plugin._shouldPlay = false;
			clearTimeout(plugin._playTimeout);
			plugin._video.off('loadeddata');

			if (plugin._isPlaying) {
				plugin._isPlaying = false;
				plugin._video[0].pause();

			}
			plugin._video.find('source').attr('src', '');
			plugin._video[0].load();
			plugin._video.hide();
		}

	});

})(jQuery, window, document);
