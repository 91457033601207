
var keycode = require('keycode');
import {fieldDisplay} from './fieldDisplay.js';

$.fn.toggleFacet = function (options) {
	var defaults = {
		fieldName: '',
		tag: '',
		displayName: '',
		defaultValue: true,
		offValue: "false",
		invert: false,
		callback: function (value) {}
	};

	var self = this;
	options = $.extend(defaults, options);

	return this.each(function () {
		var obj = $(this);

		obj.empty().append(
			$('<label/>')
			.append($('<input/>').attr('type', 'checkbox')
				.prop('checked', options.defaultValue)
				.on('change', function () {
					options.callback($(this).prop('checked'));
				})
			).append($('<span/>').addClass('name').text(options.displayName || options.fieldName))
			.append($('<span/>').addClass('count'))
		);

		obj.on('update', function (e, res, filters) {
			var count = 0;
			//index = res.facet_counts.facet_fields[options.fieldName].indexOf(options.offValue);
			if (res.hasOwnProperty('facet_counts') && res.facet_counts.hasOwnProperty('facet_fields') &&
				res.facet_counts.facet_fields.hasOwnProperty(options.fieldName)) {
				$.each(res.facet_counts.facet_fields[options.fieldName], function (index, value) {
					if (index == options.offValue) {
						count = value;
					}
				});

				// if (index !== -1 && index < res.facet_counts.facet_fields[options.fieldName].length -1) {
				//     count = res.facet_counts.facet_fields[options.fieldName][index+1];
				// }
				if (!filters.hasOwnProperty(options.tag)) {
					obj.find(':checkbox').prop('checked', options.defaultValue);
				} else if (filters[options.tag] && (options.invert && filters[options.tag].value !=
						options.offValue || !options.invert && filters[options.tag].value == options.offValue)) {
					obj.find(':checkbox').prop('checked', true);
				}

			}
			obj.find('.count').text(fieldDisplay.formatNumber(count));
			if (!obj.find(':checkbox').prop('checked')) {
				obj.toggleClass('disabled', count === 0);
				obj.find(':checkbox').prop('disabled', count === 0);
			}
		});
	});
};

$.fn.listFacet = function (options) {
	var defaults = {
		fieldName: '',
		displayName: '',
		itemSelector: '.facet',
		selectedClass: 'selected',
		callback: function (value) {},
		drawItem: function (value, count) {},
		selectedList: false,
		keepPosition: false,
		forceList: {}
	};

	var self = this;
	options = $.extend(defaults, options);

	return this.each(function () {
		var obj = $(this);

		obj.on('update', function (e, res, filters) {
			var list = null,
				selectedList = null,
				values = {};

			if (!res.hasOwnProperty('facet_counts') || !res.facet_counts.hasOwnProperty(
					'facet_fields') ||
				!res.facet_counts.facet_fields.hasOwnProperty(options.fieldName)) {
				values = options.forceList;
			} else {
				values = $.extend({}, options.forceList, res.facet_counts.facet_fields[options.fieldName]);
			}
			if (options.keepPoosition) {

			}
			obj.empty();
			if (options.selectedList) {
				list = $('<div/>').addClass('list');
				selectedList = $('<div/>').addClass('selectedList');
				obj.append(list, selectedList);
			} else {
				list = obj;
			}

			$.each(values, function (index, count) {
				if (index) {
					let item = options.drawItem(index, count)
						.data('value', index);
					list.append(
						item
					);

					item.toggleClass('disabled', count === 0);
				}
			});
			if (options.selectedList) {
				list.find(`.${options.selectedClass}`).each(function () {
					var item = $(this),
						clone = item.clone(true);
					selectedList.append(clone.data('clone', item));
					item.data('clone', clone);
				});
				if (selectedList.find(options.itemSelector).length > 1) {
					selectedList.append($('<button/>').addClass('facet clear').text('clear').on('click',
						function () {
							options.callback([]);
						}));
				}
			}
		});

		obj.on('click', options.itemSelector, function (e) {
			var item = $(this),
				values = [];
			if (options.selectedList && item.hasClass('clear') || (item.is('.disabled') && !item.hasClass(
					options.selectedClass))) {
				return;
			}
			e.preventDefault();
			item.toggleClass(options.selectedClass);
			if (item.data('clone')) {
				item.data('clone').toggleClass(options.selectedClass);
				item.remove();
			}
			if (options.selectedList) {
				obj.find('.list .' + options.selectedClass).each(function () {
					values.push($(this).data('value'));
				});
			} else {
				obj.find('.' + options.selectedClass).each(function () {
					values.push($(this).data('value'));
				});
			}
			
			options.callback(values);
		});

	});
};

$.fn.textboxRangeFacet = function (options) {
	var defaults = {
			field1: null,
			field1Label: null,
			field2: null,
			field2Label: null,
			tag: '',
			numeric: true,
			pattern: '\\d{4}',
			min: 1888,
			max:  new Date().getFullYear(),
			callback: function (value) {}
		},
		self = this;

	options = $.extend(defaults, options);

	function isNumeric(keyCode) {
		let name = keycode(keyCode);
		name = name.replace('numpad ', '');
		
		return !isNaN(name) || 
			(name == 'delete' || name == 'backspace' || name =='enter' ||
				name == 'left' || name == 'right' || name == 'tab' || name == 'shift');
	}

	function triggerChange(obj) {
		var value = {};
		value[options.field1] = obj.find('input.' + options.field1).val();
		value[options.field2] = obj.find('input.' + options.field2).val();
		//info.fq = getFq(info.value[options.field1], info.value[options.field2]);
		options.callback(value);
	}

	return this.each(function () {
		var obj = $(this);
		obj.empty();
		obj.append($('<label/>')
			.append(options.field1Label)
			.append($('<input/>').attr({
				type: options.numeric ? 'number' :'text',
				name: options.field1,
				min: options.numeric && options.min ? options.min : '',
				max: options.numeric && options.max ? options.max : '',
			}).addClass(options.field1)
				.on('change', function() {
					obj.find(`.${options.field2}`).attr('min', $(this).val());
					triggerChange(obj);
				}).on('keydown', function(e) {
					if (options.numeric) {
						return isNumeric(e.keyCode);
					}
				})
			)
		).append($('<label/>')
			.append(options.field2Label)
			.append($('<input/>').attr({
				type: options.numeric ? 'number' :'text',
				min: options.numeric && options.min ? options.min : '',
				max: options.numeric && options.max ? options.max : '',
				name: options.field2
			}).addClass(options.field2)
				.on('change', function() {
					obj.find(`.${options.field1}`).attr('max', $(this).val());
					triggerChange(obj);
				}).on('keydown', function(e) {
					if (options.numeric) {
						return isNumeric(e.keyCode);
					}
				})
			)
		);

		obj.on('update', function (e, res, filters) {

			if (filters.hasOwnProperty(options.tag)) {
				obj.find('input.' + options.field1).val(filters[options.tag].value[options.field1]);
				obj.find('input.' + options.field2).val(filters[options.tag].value[options.field2]);
			} else {
				obj.find('input').val('');
			}
		});

		obj.find('input').on('keydown', options.itemSelector, function (e) {
			if (e.keyCode == keycode('enter')) {
				triggerChange(obj);
			}
		});

	});
};

$.fn.sliderRangeFacet = function (options) {
	var defaults = {
		fieldName: null,
		fieldLabel: null,
		min: 1,
		direction: 'up', //up or down indicates teh direction of the search
		defaultVal: 4,
		minValue: 1,
		maxValue: 4,
		defaultDataVal: '',
		tag: '',
		range: [{
			value: '',
			label: '',
			dataval: 0,
		}],
		callback: function (value) {}
	};

	var self = this;
	options = $.extend(defaults, options);

	return this.each(function () {
		var obj = $(this),
			selectBox = $('<select/>').addClass(options.fieldName),
			slider = $('<div/>').addClass('slider'),
			labels = $('<div/>').addClass('labels'),
			onUpdate = false;

		obj.on('update', function (e, res, filters) {
			if (!res.hasOwnProperty('facet_counts') || !res.facet_counts.hasOwnProperty(
					'facet_fields') ||
				!res.facet_counts.facet_fields.hasOwnProperty(options.fieldName)) {
				return;
			}
			var map = res.facet_counts.facet_fields[options.fieldName],
				values = Object.keys(map),
				counts = Object.values(map),
				selectedValue = filters.hasOwnProperty(options.tag) ? filters[options.tag].value :
				options.defaultDataVal,
				selectedOption = obj.find(`.option[data-dataval="${selectedValue}"]`);
			onUpdate = true;

			//force values to be numeric
			values = values.map((element) => Number(element));

			if (filters.hasOwnProperty(options.tag)) {
				selectedOption = obj.find(`.option[data-dataval="${filters[options.tag].value}"]`);
			} else {
				selectedOption = obj.find(`.option[data-dataval="${options.defaultDataVal}"]`);

			}
			obj.find('.selected').removeClass('selected');

			selectedOption.prop('selected', true);
			obj.find(`.label[data-dataval="${selectedValue}"]`).addClass('selected');
			if (typeof(slider.slider) == 'function')
				slider.slider('value', selectedOption.data('info').value);

			//selectedOption.prop('selected', true);
			//inputField.val(selectedOption.data('info').value);

			obj.find('.option, .label').addClass('uncounted').data('count', null);
			if (counts) {

				for (var i = 0; i < values.length; i++) {
					//if (options.range.hasOwnProperty('res'+values[i])) {
					var opt = obj.find(`.option[data-dataval="${values[i]}"]`),
						label = obj.find(`.label[data-dataval="${values[i]}"]`),
						count = 0;
					if (!opt.length) {
						continue;
					}

					count = counts.reduce((accumulator, currentValue, currentIndex) => {

						if (currentIndex >= i) {
							return accumulator + currentValue;
						} else {
							return accumulator;
						}
					}, 0);

					opt.removeClass('uncounted')
						.data('count', count)
						.attr('label', opt.data('info').label + ' (' + fieldDisplay.formatNumber(count) + ')');
					label.empty().removeClass('uncounted')
						.data('count', count)
						.append(opt.data('info').label).append($('<span/>').addClass('count').html(
							fieldDisplay.formatNumber(count)));
						//}
				}
				obj.find('.option.uncounted').each(function (index) {
					var el = $(this),
						label = obj.find(`.label[data-dataval="${el.data('dataval')}"]`);

					if (el.nextAll(':not(.uncounted)').length) {
						var countedEl = el.nextAll(':not(.uncounted)').first();
						el.data('count', countedEl.data('count'))
							.removeClass('.uncounted')
							.attr('label', el.data('info').label + ' (' + fieldDisplay.formatNumber(countedEl.data('count')) +
								')');
						label.data('count', countedEl.data('count'))
							.removeClass('.uncounted')
							.empty().append(el.data('info').label).append($('<span/>').addClass('count').html(
								fieldDisplay.formatNumber(countedEl.data('count'))));

					} else {
						el.data('count', 0)
							.removeClass('.uncounted')
							.attr('label', el.data('info').label + ' (0)');
						label.data('count', 0)
							.removeClass('.uncounted')
							.empty().append(el.data('info').label).append($('<span/>').addClass('count').html(
								'0'));
					}
				});
				onUpdate = false;
			}
		});

		obj.on('change', '.' + options.fieldName, options.itemSelector, function (e) {
			var value = $(this).val(),
				option = obj.find('.option_' + value);

			if (onUpdate)
				return;

			obj.find('.option, .label').removeClass('selected');
			option.addClass('selected');
			obj.find(`.label[data-dataval="${option.data('dataval')}"]`).addClass('selected');
			if (value != options.defaultVal) {
				options.callback(option.data('dataval'));
			} else {
				options.callback('');
			}

		});

		obj.on('click', '.labels .label', function () {
			if (typeof(slider.slider) =='function') {
				slider.slider("value", $(this).data('info').value);
			}
		});

		obj.empty().append(selectBox, slider, labels);

		Object.values(options.range).forEach(range_obj => {

			selectBox.append($('<option/>')
				.attr({
					'value': range_obj.value,
					'label': range_obj.label,
					'data-dataval': range_obj.dataval
				}).text(range_obj.label)
				.data({
					info: range_obj
				})
				.addClass('option option_' + range_obj.value)
				.addClass(range_obj.value == options.defaultVal ? 'selected' : '')

			);
			labels.append($('<div/>')
				.addClass('label')
				.attr('data-dataval', range_obj.dataval)
				.data({
					info: range_obj
				})
				.addClass(range_obj.value == options.defaultVal ? 'selected' : '')
				.text(range_obj.label)
			);
		});

		import("jquery-ui/ui/widgets/slider").then((module) => {
			slider.slider({
				min: options.minValue,
				max: options.maxValue,
				value: options.defaultVal,
				range: "min",
				orientation: "vertical",
				change: function (event, ui) {
					if (onUpdate)
						return;
					selectBox[0].selectedIndex = options.maxValue - ui.value;
					selectBox.trigger('change');
				}

			});
		});
	});
};
