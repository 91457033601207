const Cookies = require('js-cookie');
var keycode = require('keycode');
import {fieldDisplay} from './fieldDisplay.js';

(function ($, window, document, undefined) {

	$.widget("badmath.searchForm", {

		_container: null,
		_searchBox: null,
		_seachButton: null,
		_searchTypeSelect: null,
		_searchTypeSelectShort: null,
		_searchForm: null,
		_clearButton: null,
		_suggestionContainer: null,
		_getSuggestionsDfd: null,
		_autoCompleteQuery: '',

		options: {
			defaultOption: 'all',
			searchTypes: {
				all: {
					title: 'ENTIRE ARCHIVE',
					shortTitle: "ALL",
					placeholder: 'Search over 50,000 hours of footage...'
				},
				music: {
					title: 'MUSICAL PERFORMANCES*',
					shortTitle: "MUSIC*",
					placeholder: 'Search over 40,000 musical performances...'
				}
			},
			suggestMinLength: 3,
			getSuggestions: function (data) {},
			performSearch: function (data) {},
			smallPrint: 'Historic Films does not provide music publishing, property, artist release or performance rights for its musical performance footage, nor does it license music videos or promos. All third party rights are the express responsibility of the licensee.'
		},

		getSearchData: function () {
			var plugin = this;
			return {
				initialSearch: true,
				q: plugin._suggestionContainer.find('.suggestion.selected').length ? plugin._suggestionContainer
					.find('.suggestion.selected').text() : plugin._searchBox.val(),
				searchType: plugin._searchTypeSelect.val() != plugin.options.defaultOption ? plugin._searchTypeSelect
					.val() : '',
				page: 1
			};
		},

		setSearchData: function (data) {
			var plugin = this;
			plugin._searchBox.val(data.q);
			if (plugin.options.searchTypes.hasOwnProperty(data.searchType)) {
				plugin._searchTypeSelect.add(plugin._searchTypeSelectShort).val(data.searchType);
			} else {
				plugin._searchTypeSelect.add(plugin._searchTypeSelectShort).val(plugin.options.defaultOption);
			}
			plugin._clearButton.toggleClass('hidden', data.q == '');
		},

		performSearch: function (searchData) {
			var plugin = this;
			plugin.setSearchData(searchData);
			plugin._suggestionContainer.hide();
			return plugin.options.performSearch(searchData);
		},

		_escapeSuggestionForQuery: function(q) {
			q = $('<div/>').html(q).text();
			q = q.replace(/\*/g, '');
			return q;
		},

		_showSuggestions: function (query, data) {
			var plugin = this;
			plugin._suggestionContainer.empty().hide();
			if (!data.hasOwnProperty('suggest'))
				return;

			$.each(data.suggest, function (dictionary, data) {
				if (!data.hasOwnProperty(fieldDisplay.escapeString(query.q))) {
					return true;
				}
				let suggestions = data[fieldDisplay.escapeString(query.q)];
				if (!suggestions.hasOwnProperty('numFound') || !suggestions.hasOwnProperty('suggestions') ||
					suggestions.numFound == 0) {
					return;
				}
				$.each(suggestions.suggestions, function (index, suggestion) {
					plugin._suggestionContainer.append($('<a/>')
						.addClass(`suggestion ${dictionary}`)
						.html(fieldDisplay.escapeString(suggestion.term))
						.attr('href',
							`search/?q=${encodeURIComponent(plugin._escapeSuggestionForQuery(suggestion.term))}`)
					);
				});

			});
			if (!plugin._suggestionContainer.is(':empty')) {
				plugin._suggestionContainer.show();
				$(document).one('click', function () {
					plugin._suggestionContainer.hide();
				});
			}
		},

		_initEvents: function () {
			var plugin = this;

			plugin._searchTypeSelect.add(plugin._searchTypeSelectShort).on('change', function () {
				var selectBox = $(this);
				plugin._container
					.removeClass(Object.keys(plugin.options.searchTypes).join(' '))
					.addClass(selectBox.find(":selected").attr('value'));

				plugin._searchBox.attr('placeholder', selectBox.find(":selected").data('option').placeholder);
				selectBox.siblings().val(selectBox.val());

			}).on('focus', function () {
				$(this).parent().addClass('focus');
			}).on('blur', function () {
				$(this).parent().removeClass('focus');
			});

			plugin._searchBox.on('keyup', function (e) {
				let query = plugin.getSearchData();
				plugin._clearButton.toggleClass('hidden', $(this).val() == '');

				if (e.keyCode == keycode('enter')) {
					plugin.performSearch(query);
				} else if (query.q.length >= plugin.options.suggestMinLength) {
					
					if (query.q == plugin._autoCompleteQuery) {
						return;
					}
					plugin._autoCompleteQuery = query.q;
					if (plugin._getSuggestionsDfd && plugin._getSuggestionsDfd.state() == 'pending') {
						console.log('blocking suggestion query until the last one is finished.');
						plugin._getSuggestionsDfd.abort();

					}
					$.when(plugin._getSuggestionsDfd = plugin.options.getSuggestions(query)).done(function (
						data) {
						plugin._showSuggestions(query, data);
					});
					
				} else {
					plugin._suggestionContainer.empty().hide();
				}

			}).on('keydown', function (e) {
				if (plugin._suggestionContainer.is(':visible')) {
					let selectedSuggestion = plugin._suggestionContainer.find('.suggestion.selected');

					switch (e.keyCode) {
					case 13: //enter. Keep selection
						break;
					case 40: //down arrow
						plugin._suggestionContainer.find('.suggestion').removeClass('selected');
						if (selectedSuggestion.length && selectedSuggestion.next('.suggestion').length) {
							selectedSuggestion.next('.suggestion').addClass('selected');
						} else {
							plugin._suggestionContainer.find('.suggestion').first().addClass('selected');
						}
						break;
					case 38: //up arrow
						plugin._suggestionContainer.find('.suggestion').removeClass('selected');
						if (selectedSuggestion.length && selectedSuggestion.prev('.suggestion').length) {
							selectedSuggestion.prev('.suggestion').addClass('selected');
						} else {
							plugin._suggestionContainer.find('.suggestion').last().addClass('selected');
						}
						break;
					default:
						plugin._suggestionContainer.find('.suggestion').removeClass('selected');
					}
				}
			});

			plugin._searchButton.click(function () {
				plugin.options.performSearch(plugin.getSearchData());
			});

			plugin._clearButton.click(function () {
				plugin._searchBox.val('');
				plugin._clearButton.addClass('hidden');
				plugin._searchBox.focus();
			});
		},

		_init: function () {
			var plugin = this;
			plugin._container.empty();
			plugin._searchBox = $('<input/>')
				.attr({
					type: 'text',
					placeholder: plugin.options.searchTypes[plugin.options.defaultOption].placeholder
				}).addClass('mainSearchBox searchBox searchInput');
			plugin._clearButton = $('<button/>')
				.addClass('button searchInput clear hidden')
				.text('Clear');
			plugin._searchTypeSelect = $('<select/>')
				.addClass('searchType')
				.append(function () {
					let options = [];
					$.each(plugin.options.searchTypes, function (index, type) {
						options.push($('<option/>')
							.data('option', type)
							.attr('value', index)
							.prop('selected', index == plugin.options.defaultOption)
							.append($('<span/>').addClass('full').text(type.title))

						);
					});
					return options;
				});
			plugin._searchTypeSelectShort = $('<select/>')
				.addClass('searchType_short')
				.append(function () {
					let options = [];
					$.each(plugin.options.searchTypes, function (index, type) {
						options.push($('<option/>')
							.data('option', type)
							.attr('value', index)
							.prop('selected', index == plugin.options.defaultOption)
							.append($('<span/>').addClass('short').text(type.shortTitle))
						);
					});
					return options;
				});
			plugin._searchTypeSelectWrapper = $('<span/>').addClass('searchSelect').append(plugin._searchTypeSelect)
				.append(plugin._searchTypeSelectShort);
			plugin._searchButton = $('<button/>')
				.addClass('button searchInput leavePage searchGo')
				.text('Search');
			if (!Cookies.get('skipSmallPrint')) {
				plugin._smallPrint = $('<div/>')
					.addClass('smallPrint')
					.append(plugin.options.smallPrint)
					.append($('<button/>').addClass('btnOk').text('Ok').click(function () {
						$(this).closest('.smallPrint').remove();
						Cookies.set('skipSmallPrint', true);
					}));
			}
			plugin._suggestionContainer = $('<div/>').addClass('suggestions').hide();
			plugin._searchForm = $('<div/>').addClass('searchForm');
			plugin._container.append(
				plugin._searchForm.append(plugin._searchBox, plugin._clearButton, plugin._searchTypeSelectWrapper,
					plugin._searchButton, plugin._suggestionContainer)
			).append(plugin._smallPrint);
			plugin._initEvents();
		},

		_create: function () {
			this._container = this.element;
			this._init();
		},

		// Destroy an instantiated plugin and clean up
		// modifications the widget has made to the DOM
		_destroy: function () {},

		// Respond to any changes the user makes to the
		// option method
		_setOption: function (key, value) {
			//store the option
			this._super("_setOption", key, value);
		}
	});

})(jQuery, window, document);
