import {Timecode, timecodeFunctions} from './timecodes.js';
import {hfCommon} from './common.js';
const moment = require('moment');
let reelVideoStates = {};
export const fieldDisplay = {

	displayField: function(value, useDot, title, allowNewLines) {
		allowNewLines = (allowNewLines === undefined) ? false : allowNewLines;
		if (!title) title = "Not Entered";
		if (useDot === undefined) {
			useDot = false;
		}
		if (!value) {
			if (useDot) {
				return '<img class="dot" src="template/icons/dot.png" alt="' + title +
					'" title="' +
					title +
					'" />';
			} else {
				return '';
			}
		} else {
			return allowNewLines ? fieldDisplay.nl2br(value) : fieldDisplay.escapeString(value);
		}
	},

	displayColor: function(value, useDot) {
		if ($.isArray(value)) {
			var displayValue = '';
			for (var i = 0; i < value.length; i++) {
				if (i > 0) {
					displayValue += '/';
				}
				displayValue += value[i];
			}
			return displayValue;
		}
		return fieldDisplay.displayField(value, useDot);
	},

	formatNumber: function(num) {
		var str = '';
		while (num > 1000) {
			var units = num % 1000;
			num = (num - units) / 1000;

			//make the units variable  a string
			units = units + '';
			while (units.length < 3) {
				units = "0" + units;
			}
			str = units + (str ? ',' : '') + str;
		}
		return num + (str ? ',' : '') + str;
	},

	log10: function(arg) {

		return Math.log(arg) / Math.LN10;
	},

	isNumber: function(n) {
		return !isNaN(parseFloat(n)) && isFinite(n);
	},

	roundToTwoDecimals: function(num) {
		if (!isNaN(num)) {
			return Math.round(num * 100) /100;
		}
		return num;
	},

	roundToSignificantDigits: function(number, numDigits) {
		if (!isNumber(number)) {
			return 0;
		}
		if (!number) {
			return 0;
		}
		number *= Math.pow(10, numDigits);
		number = Math.round(number) / Math.pow(10, numDigits);
		return number;
	},

	formatDate: function(dateStr, formatStr, timestamp) {
		if (!dateStr) return fieldDisplay.displayField(dateStr);
		if (formatStr === undefined) formatStr = "MMM D, YYYY <br/> h:mma";
		timestamp = timestamp === undefined ? false : timestamp;
		var date = timestamp ? moment.unix(Number(dateStr)) : moment(dateStr);
		if (date.isValid()) {

			return date.format(formatStr);
		} else {
			return "Unknown Date";
		}
	},

	relativeDate: function(dateStr) {
		let date = null;
		if (!dateStr) {
			date = moment();
		} else {
			date = moment.unix(Number(dateStr));
		}
		if (date.isValid()) {
			return date.fromNow();
		} else {
			return "Unknown Date";
		}
	},

	formatMoney: function(amount) {
		if (!amount) {
			return '--';
		}
		return '$' + Math.round(amount, 2);
	},

	getShortLengthDisplayString: function(length, abbreviations) {
		var words;
		if (abbreviations === undefined) {
			abbreviations = true;
		}

		if (abbreviations) {
			words = {
				'seconds': 'sec',
				'minutes': 'min',
				'hours': 'hr'
			};
		} else {
			words = {
				'seconds': 'second',
				'minutes': 'minute',
				'hours': 'hour'
			};
		}

		length = Math.round(length);

		if (length < 100) {
			return length + ' ' + words.seconds;
		}

		var minutes = length / 60;
		if (parseInt(minutes) != minutes) {
			return '~' + Math.round(minutes) + ' ' + words.minutes;
		} else {
			return minutes + ' ' + words.minutes;
		}

	},

	getLengthDisplayString: function(length, abbreviations) {
		var words;
		if (abbreviations === undefined) {
			abbreviations = true;
		}

		if (abbreviations) {
			words = {
				'seconds': 'sec',
				'minutes': 'min',
				'hours': 'hr'
			};
		} else {
			words = {
				'seconds': 'second',
				'minutes': 'minute',
				'hours': 'hour'
			};
		}

		length = Math.round(length);
		var seconds = length % 60;
		length -= seconds;
		var minutes = (length % (60 * 60)) / 60;
		length -= length % (60 * 60);
		var hours = length / (60 * 60);

		var minStr = "";
		var hourStr = "";
		var secondsStr = "";

		if (minutes > 0) {
			minStr = minutes + ' ' + words.minutes;
			if (minutes > 1 && !abbreviations) {
				minStr += "s";
			}
		}

		if (hours > 0) {
			hourStr = hours + ' ' + words.hours;
			if (hours > 1 && !abbreviations) {
				hourStr += "s";
			}
		}

		if (seconds > 0) {
			secondsStr = seconds + ' ' + words.seconds;
			if (seconds > 1 && !abbreviations) {
				secondsStr += "s";
			}
		}

		if (hourStr && minStr) {
			return hourStr + ", " + minStr;

		} else if (minStr && secondsStr) {
			return minStr + ", " + secondsStr;
		} else if (hourStr && secondsStr) {
			return hourStr;
		} else if (hourStr || minStr || secondsStr) {
			return hourStr + minStr + secondsStr;
		}
		return '0 ' + words.seconds;
	},

	formatBitField: function(value) {
		if (!value) {
			value = "N";
		} else if (value == 1) {
			value = "Y";
		}
		return fieldDisplay.displayField(value);
	},

	formatColor: function(color, icon) {

		var displayValue = '';

		if (!color) {
			return '';
		}
		if ($.isArray(color)) {

			for (var i = 0; i < color.length; i++) {
				if (i > 0) {
					displayValue += '/';
				}
				displayValue += color[i];
			}
		} else {
			displayValue = color;
		}

		switch (displayValue) {
		case 'b&w':
			return displayValue.toUpperCase() + (icon ?
				' <img src="template/icons/color-bw.png" alt ="' +
				displayValue.toUpperCase() + '"/>' : '');
		case 'color':
			return displayValue.toUpperCase() + (icon ?
				' <img src="template/icons/color-color.png" alt ="' +
				displayValue.toUpperCase() + '"/>' : '');
		default:
			return displayValue.toUpperCase() + (icon ?
				' <img src="template/icons/color-both.png" alt ="' +
				displayValue.toUpperCase() + '"/>' : '');
		}
	},

	formatCondition: function(condition, conditionId) {
		if (!condition) {
			return '<span class="noValue">Not entered</span>';
		}
		return condition + ' <img src="template/icons/condition-' + conditionId + '.png" alt ="' +
			condition.toUpperCase() + '"/>';

	},

	formatTags: function(tags) {
		var first = true;
		var tmpDiv = $("<div />");
		$.each(tags, function (name, value) {
			if (!first) {
				tmpDiv.append(", ");
			}
			first = false;
			tmpDiv.append(
				$('<span/>')
				.addClass("tag")
				.html(escapeString(value.tag))
			);
		});
		if (first)
			return fieldDisplay.displayField(null);
		return tmpDiv.html();
	},

	formatKeywords: function(keywords) {
		var first = true;
		var tmpDiv = $("<div />");

		$.each(keywords, function (name, value) {
			if (!first) {
				tmpDiv.append(", ");
			}
			first = false;

			tmpDiv.append(
				$('<a/>')
				.addClass('keyword')
				.append($('<abbr/>')
					.addClass('keywordAbbr')
					.attr('title',
						'(' + value.partOfSpeech + ') ' +
						value.definition
					)
					.append(
						$('<span>')
						.addClass('word')
						.html(escapeString(value.word))
					)

				).append(
					$('<span/>')
					.hide()
					.addClass('facetValue')
					.html(value.id)
				)
			);
		});
		if (first)
			return fieldDisplay.displayField(null);
		return tmpDiv.html();
	},



	escapeString: function(str) {
		let allowedTags = ['i', 'em', 'b', 'strong'];
		//str = $.trim(str);
		try {

			str = str === undefined || !str ? '' : str;
			str = "" + str;
			str = str.replace(/&(?!\w+;)/g, "&amp;");
			str = str.replace(/</g, "&lt;");
			str = str.replace(/>/g, "&gt;");
			str = str.replace(/\"/g, "&quot;");

			for (var i = 0; i < allowedTags.length; i++) {
				var tag = allowedTags[i];
				str = str.replace(new RegExp("&lt;" + tag + "\s*&gt;", 'ig'), '<' + tag + '>');
				
				str = str.replace(new RegExp("&lt;\/" + tag + "\s*&gt;", 'ig'), '</' + tag + '>');
			}
			str = str.replace(/&lt;\s*br\s*\/?&gt;/gi, '<br />');
		} catch (ex) {

			//console.log(ex, str);
		}
		return str;
	},

	nl2br: function(str, filler) {
		if (filler === undefined) {
			filler = false;
		}
		if (str === undefined || !str) {
			if (filler) {
				return fieldDisplay.displayField(str, false);
			}
			return '';
		}
		//str = $.trim(str);
		str = fieldDisplay.escapeString(str);
		str = str.replace(/&lt;\s*br\s*\/?&gt;/gi, '<br />');
		str = str.replace(/\n/g, '<br />');
		return str;
	},

	formatDateField: function(str, delimiter, order, useDot) {
		if (str === undefined) {
			return fieldDisplay.displayField(null, useDot);
		}
		if (delimiter === undefined) {
			delimiter = "/";
		}
		if (order === undefined) {
			order = "mdy";
		}
		if (str.match(/(\d\d\d\d)\-(\d\d)\-(\d\d)/)) {
			switch (order) {
			case 'mdy':
				return RegExp.$2 + delimiter + RegExp.$3 + delimiter + RegExp.$1;
			case 'ymd':
				return RegExp.$1 + delimiter + RegExp.$2 + delimiter + RegExp.$3;
			}

		} else {
			return "Unknown";
		}

	},

	displayFieldWbr: function(value, useDot) {
		if (!value) {
			return fieldDisplay.displayField(value, useDot);
		}
		value = fieldDisplay.escapeString(value);
		//value = value.replace(/([^\w<\/(?=em)])/gi, "$1<wbr>");
		value = value.replace(/(\s|\/(?!em)|\-|\.,)/gi, "$1&#8203;");

		return value;
	},

	displayRange: function(start, end, useDot) {
		if (start && end) {
			if (start != end) {
				return fieldDisplay.displayField(start) + "-" + fieldDisplay.displayField(end);
			} else {
				return fieldDisplay.displayField(start);
			}
		} else if (start) {
			return fieldDisplay.displayField(start);
		} else if (end) {
			return fieldDisplay.displayField(end);
		} else {
			return fieldDisplay.displayField(null, useDot);
		}
	},

	displayTCRange: function(start, end, useDot) {
		if (start && end && start != end) {
			//	var tcStart = new Timecode(start);
			//	var tcEnd = new Timecode(end);
			return '<span class="tcIn">' + start + '</span> - ' +
				'<span class="tcOut">' + end + '</span>';
		} else {
			return fieldDisplay.displayField(null, useDot);
		}
	},

	displayColorIcon: function(color) {

		switch (color) {
		case 'color':
			return '<img src="template/icons/color-color.png" alt="' + color +
				'" title="Color" />';
		case 'b&w':
			return '<img src="template/icons/color-bw.png" alt="' + color +
				'" title="Black & White" />';
		case 'color / b&w':
			return '<img src="template/icons/color-both.png" alt="' + color +
				'" title="Mixed Color / BW" />';
		default:
			return '<img src="template/icons/dot.png" alt="Not set" title="Not Set" />';

		}

	},

	displayConditionIcon: function(condition, caption) {
		if (condition) {
			return '<img src="template/icons/condition-' + condition + '.png" alt="' + caption + '" title="' +
				caption + '" />';
		}
		return '<img src="template/icons/dot.png" alt="Not set" title="Not Set" />';
	},

	displayCheckMark: function(value, yesCaption, noCaption) {
		if (value) {
			return '<img src="template/icons/checkmark.png" alt="' + yesCaption +
				'" title="' +
				yesCaption +
				'" />';
		}
		return '<img src="template/icons/dot.png" alt="' + noCaption + '" title="' +
			noCaption +
			'" />';

	},

	pad: function(number, length) {

		var str = '' + number;
		while (str.length < length) {
			str = '0' + str;
		}

		return str;

	},

	formatLength: function(value, useDot) {
		if (!value) {
			return fieldDisplay.displayField(null, useDot);
		}
		return formatTimecode(value);
	},

	formatTimecode: function(value, includeFrames) {
		if (includeFrames === undefined) {
			includeFrames = false;
		}
		var frames = 0;
		if (!includeFrames) {
			value = Math.floor(value);
		} else {
			frames = 0;
		}
		var seconds = value % 60;
		value -= seconds;
		var minutes = (value % (60 * 60)) / 60;
		value -= value % (60 * 60);
		var hours = value / (60 * 60);

		return fieldDisplay.pad(hours, 2) + ":" + fieldDisplay.pad(minutes, 2) + ":" + fieldDisplay.pad(seconds, 2);
	},

	trimFrames: function(timecodeStr) {
		if (!timecodeStr || timecodeStr.length < 8) {
			return timecodeStr;
		}
		return timecodeStr.substring(0, 8);

	},

	formatBytes: function(bytes) {
		if (!bytes) {
			return '<span class="error">N/A</span>';
		}

		var ext = ['B', 'KB', 'MB', 'GB', 'TB'];
		var unitCount = 0;
		for (; bytes > 1000; unitCount++) bytes /= 1024;
		if (unitCount < 3) {
			return Math.round(bytes) + "&nbsp;" + ext[unitCount];
		}

		return Math.round(bytes, 1) + "&nbsp;" + ext[unitCount];

		/*
		
	    var kb = Math.floor(bytes / 1024) + Math.round((bytes % 1024/ 1024)*10)/10 ;
	    if (kb < 1024) {
	    	return Math.round(kb) + "&nbsp;kB";
	    }
	    var mb = Math.floor(bytes / (1024*1024)) + Math.round((bytes % (1024*1024)/ (1024*1024))*10)/10;
	    if (mb < 1024) {
	    	return Math.round(mb) + "&nbsp;MB";
	    }
	    var gb = Math.floor(bytes / (1024*1024*1024)) + 
	    	Math.round((bytes % (1024*1024*1024)/ (1024*1024*1024))*10)/10;
	    return gb + "&nbsp;GB";
	    */
	},

	getThumbnailUrlFromOffset: function(id, offset, size) {

		return HF_GLOBALS.thumbnailsUrlPath + id + "/" + size + "/offset/" + offset + '.jpg';
	},

	getThumbnailFromOffset: function(id, offset, size, className, specialAccess) {

		specialAccess = specialAccess ?? false;
		var url = fieldDisplay.getThumbnailUrlFromOffset(id, offset, size);

		return '<img  class="' + className + '" src="' + url + '" alt="thumbnail" loading="lazy" />';
	},

	getThumbnailFromIndex: function(id, index, size, className, useStrip, clipHash, clipType,
		thumbnailHeight) {

		useStrip = useStrip ?? false;
		thumbnailHeight = thumbnailHeight ?? '';

		var url = fieldDisplay.getThumbnailUrlFromIndex(id, index, size, useStrip, clipHash, clipType);

		if (!useStrip) {
			return '<img data-height="' + thumbnailHeight + '" class="' + className + '" src="' + url +
				'"  alt="thumbnail" loading="lazy" />';
		} else {
			var position = fieldDisplay.getBackgroundPositionFromIndex(index, thumbnailHeight);
			return '<div data-height="' + thumbnailHeight + '" class="' + className +
				'" style="background-image:url(\'' + url +
				'\');background-position:' + position + '"  alt="thumbnail" loading="lazy" />';
		}
	},

	getBackgroundPositionFromIndex: function(index, thumbnailHeight) {
		thumbnailHeight = thumbnailHeight === undefined || !thumbnailHeight ? HF_GLOBALS.LARGE_THUMBNAIL_HEIGHT :
			thumbnailHeight;
		var pos = index % HF_GLOBALS.THUMBNAILS_PER_STRIP * thumbnailHeight;
		return "0px -" + pos + "px";
	},

	getThumbnailUrlFromIndex: function(id, index, size, useStrip, clipHash, clipType) {
		var url = '',
			basePath = HF_GLOBALS.thumbnailsUrlPath;

		useStrip = useStrip ?? false;
		clipHash = clipHash ?? false;
		clipType = clipType ?? 'Clip';

		if (clipHash) {
			basePath += clipType + '/' + clipHash + '/';
		} else {
			basePath += id + '/';
		}
		if (useStrip) {

			var stripNum = Math.floor(index / HF_GLOBALS.THUMBNAILS_PER_STRIP);
			url = basePath + size + "/strip/" + parseInt(stripNum) + '.jpg';
		} else {
			url = basePath + size + "/index/" + parseInt(index) + '.jpg';
		}
		return url;
	},

	getThumbnail: function(doc, number, size, className) {
		if (doc.hasvideo) {
			var url = HF_GLOBALS.thumbnailsUrlPath + doc.file + '_' + doc.id + '/' +
				doc.file + '_' + doc.id + '_thumb_' + size + '_' + fieldDisplay.pad(number, 3) + '.jpg';
			return '<img  class="' + className + '" src="' + url + '" alt="thumbnail" />';
		} else {
			//$("#result" + doc.id + ' .info').prepend('<img class="' +className + '" src="'+ url +'" alt="thumbnail" />');
			return '<img class="' + className +
				'" src="template/icons/thumbnail.png" alt="thumbnail" />';
		}
	},

	unCamelCaseify: function(string) {
		string = string.replace(/([A-Z])/g, ' $1');
		string = string.replace(/^./, function (str) {
			return str.toUpperCase();
		});
		return string;
	},

	capFirst: function(string) {
		//string = string.toLowerCase();
		string = string.substr(0, 1).toUpperCase() + string.substr(1);
		return fieldDisplay.escapeString(string);
	},

	createPositionBar: function(tapeLength, start, end) {
		let width = 100;
		tapeLength = Number(tapeLength);
		if (!tapeLength) {
			return '<div class="outside" style="width:100%">&nbsp;</div>';
		}
		tapeLength = Math.max(1, Math.floor(tapeLength / 60));
		start = Math.min(tapeLength, Math.max(0, Math.ceil(start / 60)));
		end = Math.ceil(end / 60);
		end = (end == -1) ? tapeLength : end;
		end = Math.min(tapeLength, Math.max(0, end));

		var beforeWidth = Math.floor((start / tapeLength) * width);
		var clipWidth = Math.max(2, Math.ceil(((end - start) / tapeLength) * width));
		//ar afterWidth = width - (beforeWidth + clipWidth);

		//Never display a bar that is wider than the width
		beforeWidth = Math.min(beforeWidth, width);
		clipWidth = Math.min(width - beforeWidth, clipWidth);

		// var afterPos = beforeWidth + clipWidth;
		// afterWidth = Math.min(width - afterPos, afterWidth);

		return $('<div/>').addClass('clip')
			.css({
				width: `${clipWidth}%`,
				left: `${beforeWidth}%`
			});
	},

	makeEditable: function(el, multiline, objectName, idValue, fieldName, defaultText, extraOpts) {
		if (defaultText === undefined) {
			defaultText = "<span>(Click to Edit)<\/span>";
		}
		if (extraOpts === undefined) {
			extraOpts = {};
		}
		el.addClass('editable');
		el.editInPlace($.extend({
			html: false,
			multiline: multiline,
			saveCallBack: function (obj, text, originalContents) {

				if (jQuery("<div/>").append(text).html() == jQuery("<div/>").append(defaultText).html()) {
					text = "";
				}
				if (!text) {
					obj.html(defaultText);
				}

				if (typeof (hfCommon) != 'undefined') {
					hfCommon.updateField(objectName, fieldName, text, originalContents, idValue, el);
					hfCommon.resetFixedPos(el);
				}

				
			},
			cancelCallBack: function (obj) {
				setTimeout(function () {
					hfCommon.resetFixedPos(el);
				}, 500);
			},
			outsideClick: 'cancel',
			defaultMarkUp: defaultText,

			wysiwyg: false
		}, extraOpts));
	},

	getRegions: function(countryId, type) {
		$.ajax({
			data: {
				action: 'getRegions',
				countryId: countryId
			},
			success: function (data) {
				var select;
				if (type == 'billing') {
					select = $("#billing_regionId");
				} else {
					select = $("#mailing_regionId");
				}
				var regions = $.parseJSON(data);
				select.empty();
				select.append($("<option/>")
					.attr('value', '')
					.text('Select...'));
				var hasRegions = false;
				$.each(regions, function (name, value) {
					hasRegions = true;
					select.append(
						$("<option/>")
						.attr('value', value.id)
						.text(value.name)
					);
				});
				if (!hasRegions) {
					select.prop('disabled', true);
				} else {
					select.prop('disabled', false);
				}
			}
		});
	},

	getVideoPath: function(tape) {
		let path = '';
		if (tape.webFilename) {
			path = HF_GLOBALS.videosUrlPath + tape.webFilename;
		} else if (tape.reelId) {
			path = HF_GLOBALS.videosUrlPath + tape.tapeId + '_' + tape.reelId + '_web.' + HF_GLOBALS.videoExtension;
		} else {
			path = HF_GLOBALS.videosUrlPath + tape.tapeId + '_' + tape.id + '_web.' + HF_GLOBALS.videoExtension;
		}
		return path;

	},

	getClipVideoPath: function(clip) {
		return 'clip/' + clip.clipType + '/' + clip.hashId + '.' + HF_GLOBALS.videoExtension;
	},

	getVideoIsAvailable: function(path, forceReject) {

		forceReject = forceReject === undefined ? false: forceReject;
		let dfd = new $.Deferred();

		if (forceReject) {
			reelVideoStates[path] = false;
			dfd.reject();
		} else if (reelVideoStates.hasOwnProperty(path)) {
			let state = reelVideoStates[path];
			if (typeof state == "boolean") {
				if (state) {
					dfd.resolve();
				} else {
					dfd.reject();
				}
			} else {
				return state.promise();
			}
		} else {
			
			reelVideoStates[path] = $.ajax({
				url: path,
				type: 'HEAD',
			}).done(function() {
				reelVideoStates[path] = true;
				dfd.resolve();
			}).fail(function() {
				reelVideoStates[path] = false;
				dfd.reject();
			});
		}
		return dfd.promise();
	},

	getNotice: function(doc, specialAccess, numDays) {
		specialAccess = specialAccess || false;
		numDays = numDays || 0;
		var notice = $('<span/>');

		if (doc.specialtyCollection) {
			notice.append($('<strong>').html('Specialty&nbsp;Collection')).append(' ');
			
			if (specialAccess) {
				if (doc.MOS && doc.includeAudio) {
                    notice.append(`Accessible online with audio for ${numDays} more day${(
                        numDays > 1 ? 's' : '')}.`);
                } else {
                    notice.append(`Accessible online for ${numDays} more day${(
                        numDays > 1 ? 's' : '')}.`);
                }
			} else if (numDays < 0) {
				notice.append('Temporary access has now expired.');
			} else if (HF_AUTH.proAccount) {
				notice.append('Must be downloaded to view.');
			} 
			if (!specialAccess && !HF_AUTH.proAccount) {
				notice.append(
					' Sign up for a <a href="buyMinutes">pro account</a> to download this footage.'
				);
			}
		} else if (doc.temporailyUnavailable) {
			notice.append('Temporarily unavailable for online viewing');
		} else if (!doc.hasVideo) {
			if (specialAccess) {
				if (doc.MOS && doc.includeAudio) {
                    notice.append(`<strong>Restricted Material:</strong> Accessible online with audio for ${numDays} more day${(
                        numDays > 1 ? 's' : '')}.`);
                } else {
                    notice.append(`<strong>Restricted Material:</strong> Accessible online for ${numDays} more day${(
                        numDays > 1 ? 's' : '')}.`);
                }
            } else if (numDays < 0) {
				notice.append('Temporary access has now expired.');
			} else {
				notice.append('Not currently available for online viewing.');
			}
		} else if (doc.MOS) {
			if (doc.includeAudio && specialAccess) {
                notice.append(`<strong>Restricted Material:</strong> Accessible online with audio for ${numDays} more day${(
                    numDays > 1 ? 's' : '')}.`);
            } else if (numDays < 0) {
				notice.append('Temporary access with audio has now expired.');
			}
		}
		var noticeWrapper = $('<span/>').addClass('notice').append(notice);
		return notice.is(':empty') ? null : noticeWrapper;
	},

	truncateStringAtWord: function(string, maxLength) {
		if (string.length <= maxLength) {
			return string;
		}
		string = string.substr(0, maxLength);
		//re-trim if we are in the middle of a word
		string = string.substr(0, Math.min(string.length, string.lastIndexOf(" ")));
		return string;
	},

	getMltSearchExcerpt: function(string, maxLength) {
		string = string.replace(/[\W\s]+/g, ' ');
		return fieldDisplay.truncateStringAtWord(string, maxLength);
	},

	//truncating search result text
	getTextDims: function(text, font, canvasWidth) {
		canvasWidth = canvasWidth ?? null;
		// re-use canvas object for better performance
		const canvas = fieldDisplay.getTextDims.canvas || 
			(fieldDisplay.getTextDims.canvas = document.createElement("canvas"));
		if (canvasWidth) {
			canvas.width = canvasWidth;
		}
		const context = canvas.getContext("2d");
		if (canvasWidth) {
			context.canvas.width = canvasWidth;
		}
		context.font = font;
		const metrics = context.measureText(text);
		
		return metrics;
	},

	getCssStyle: function(element, prop) {
		return window.getComputedStyle(element, null).getPropertyValue(prop);
	},

	getCanvasFont: function(el = document.body) {
		const fontWeight = fieldDisplay.getCssStyle(el, 'font-weight') || 'normal';
		const fontSize = fieldDisplay.getCssStyle(el, 'font-size') || '16px';
		const fontFamily = fieldDisplay.getCssStyle(el, 'font-family') || 'Times New Roman';

		return `${fontWeight} ${fontSize} ${fontFamily}`;
	},

};

