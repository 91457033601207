const Cookies = require('js-cookie');
require("jquery-ui/ui/widget");
require("./../../lib/eip/js/jquery.editInPlace.live.js");
//var Modernizr = require("modernizr");
import {Spinner} from 'spin.js';
import {dialogs} from './dialogs.js';
import {fieldDisplay} from './fieldDisplay.js';
import {bins} from './bins.js';
import {Timecode, timecodeFunctions} from './timecodes.js';
require ('../../lib/newPlayer/jquery.videoPlayer.js');


export const videoWindowWidget = {
init: function() {
$.widget("badmath.videoWindow", {

	_videoWindow: null,
	_shareDialog: null,
	_player: null,
	_tapeJSON: {},
	_highlightClipDfd: null,
	_loadingNewClip: false,
	_clipChanged: false,
	_lastPlayerClipUpdate: 0,
	_playerSpinner: null,
	_playerTimecodeOffset: null,
	_imageBuffer: null,
	_currentReelhasVideo: false,
	_shareUrl: null,
	_emptyReelData: {
		reelId: null,
		log: null,
		mlt: null,
		res: null,
		in : null,
		out: null,
		clip: null,
		writable: true,
		q: ''
	},
	_reelData: {
		reelId: null,
		log: null,
		in : null,
		out: null,
		clip: null,
		writable: true
	},
	_resizeObserver: null,

	options: {
		debug: true,
		logScrollOffset: 60,
		absoluteURL_SSL: '',
		defaultClipTitleMarkup: "<span class=\"empty\">(Add a label to this clip...)<\/span>",
		playerClipUpdateFrequency: 100, //time in millisecond between clip updates from teh player
		maxMiniLogMatchIndex: 40,
		saveClipChanges: function (callback) {},
		initialized: function() {},
		clipChangedCallback: function (reelData, clip) {},
		closeCallback: function () {},
		addNewClipToBin: function (reelId, offsetIn, offsetOut, title, binId, index, binName, newName,
			sourceClipId, sourceClipType) {},
		getClip: function (data) {},
		trackEvent: function () {},
		openWindowCallback: function () {},
		showContentCallback: function(clipData) {},
		layoutContainer: '#layoutContainer',
		showMailClipDialog: function (clipInfo) {},
		getTranscript: function (tape) {},
		logShare: function(tapeId, type) {},
	},

	//helpers
	_log: function () {
		if (this.options.debug)
			console.log.apply(console, arguments);
	},

	_fitStringToSize: function (str, width, height, className, container) {

		var plugin = this,
			result = str; // set the result to the whole string as default
		var span = $("<span/>")
			.addClass(className)
			.css({
				display: 'inline',
				padding: 0,
				overflow: 'visible',
				height: 'auto',
				margin: 0,
				border: 0,
				width: width
			})
			.html(str);
		container.append(span);

		if (span[0].offsetHeight > height) {

			//truncate beginning to force a match to be displayed.

			var matchIndex = str.indexOf('<em>');
			if (matchIndex > plugin.options.maxMiniLogMatchIndex) {
				var start = matchIndex - plugin.options.maxMiniLogMatchIndex;
				while (str[start] != ' ' && start < matchIndex) {
					//also split on line breaks
					if (str[start] == '>' && str.substr(start - 4, start) == '<br>') {
						break;
					}
					start++;
				}
				str = '&hellip;' + str.substring(start + 1);
			}

			var posStart = 0,
				posMid, posEnd = str.length;
			while (true) {
				// Calculate the middle position
				posMid = posStart + Math.floor((posEnd - posStart) / 2);

				//only split on spaces/elements, so find the next one
				while (str[posMid] != ' ' && posMid > posStart) {
					if (str[posMid] == '>' && str.substr(posMid - 4, posMid) == '<br>') {
						posMid++;
						break;
					}
					posMid--;
				}

				span.html(str.substring(0, posMid) + '&hellip;');
				// Break the loop if this is the last round
				if (posMid == posEnd || posMid == posStart) break;

				//if the string is too tall, shorten it by setting the end
				if (span[0].offsetHeight > height) {
					posEnd = posMid;
					//if the string is to short  adjust the start position; this
					//will result in a larger mid value and a longer string.
				} else {
					posStart = posMid;
				}
			}
		}
		result = span.html();
		span.remove();
		return result;
	},

	//Log Controls
	_findNearestLogEntry: function (seconds) {
		var plugin = this,
			rows = plugin._videoWindow.find(".tabPane.log .textLog tr"),
			first = 0,
			last = rows.length;

		seconds = Number(seconds);
		for (;;) {
			if (last <= first) {
				return $(rows[first]);
			}
			var mid = first + Math.ceil((last - first) / 2);
			var midPos = Number($(".timecode .offset", $(rows[mid])).text());
			if (midPos == seconds) {
				return $(rows[mid]);
			} else if (midPos < seconds) {
				first = mid;
			} else {
				last = mid - 1;
			}
		}
	},

	_scrollToNearestLogEntry: function (seconds, force) {
		var plugin = this,
			entry,
			index,
			currentEntry = plugin._videoWindow.find(".tabPane.log table.textLog tr.current"),
			nextTime;

		if (plugin._loadingNewClip) {
			return;
		}

		force = force === undefined ? false : force;

		if (!force && currentEntry.length &&
			seconds >= Number($('.timecode .offset', currentEntry)) &&
			(currentEntry.is(':last=child') ||
				seconds < Number($('.timecode .offset', currentEntry.next())))) {
			return;
		}

		//currentEntry.removeClass('current');
		entry = plugin._findNearestLogEntry(seconds);
		plugin._scrollToLogEntry(entry);

	},

	_scrollToLogEntry(entry) {
		let plugin = this;
		if (entry.length) {
			//console.log('Scroll to ' + entry.find('.timecode .tc').text(), entry.position().top - plugin.options.logScrollOffset + 'px');
			//entry[0].scrollIntoView();
			var logDiv = plugin._videoWindow.find(".tabPane.log div.textLog");
			logDiv.css('overflow', 'hidden')
				.stop(true).animate({
					scrollTop: (entry.position().top - plugin.options.logScrollOffset) + 'px'
				}, function () {
					logDiv.css('overflow', 'auto');
				});

		}
	},

	_showClipMarkers: function (logEntry) {
		var plugin = this;

		if (!plugin._reelData.writable || logEntry.hasClass('outsideClip')) {
			return;
		}

		if (!plugin._videoWindow.find(".markerContainer").is(":visible")) {
			console.log('markers are hidden');
			plugin._videoWindow.find(".markerContainer .logMarker").addClass('notransition');
			plugin._videoWindow.find(".markerContainer").show();
		}

		var nextLogEntry = null,
			top = plugin._videoWindow.find('.tabPane.log div.textLog').position().top +
			logEntry.position().top + logEntry.height() / 2 -
			plugin._videoWindow.find('.logMarker').outerHeight() / 2 -
			parseInt(plugin._videoWindow.find('.logMarker').css('margin-top')) -
			plugin._videoWindow.find('.tabPane.log div.textLog').scrollTop();

		if (!logEntry.is(":last-child")) {
			nextLogEntry = logEntry.next();
		}

		plugin._videoWindow.find('.logMarker')
			.css('top', top + "px")
			.off('click')
			.on('click', function () {

				var logStart = Math.max(0, Number(logEntry.find('.offset').text())),
					logEnd = nextLogEntry ? Number(nextLogEntry.find('.offset').text()) : plugin._tapeJSON.length;

				if ($(this).attr('id') == 'clipStart') {
					//if this log entry is after the current end of the clip set the clip
					//to be the log entry
					if (plugin._reelData.out !== null && logStart >= plugin._reelData.out) {
						plugin.setOffsets(logStart, logEnd, true, 'in');
					} else {
						plugin.setOffset('in', logStart, true, true, true);
					}
				} else {
					//if this log entry is before the current beginning of the clip set the clip
					//to be the log entry
					if (logEnd < plugin._reelData.in) {
						plugin.setOffsets(logStart, logEnd, true, 'out');
					} else {
						plugin.setOffset('out', logEnd, true, true, true);
					}
				}
				plugin._videoWindow.find(".markerContainer").hide();
				plugin._videoWindow.find('#jumpToPoint').hide();
				
			});
		if (plugin._videoWindow.find(".markerContainer .logMarker.notransition").length) {
			setTimeout(function () {
				plugin._videoWindow.find(".markerContainer .logMarker.notransition").removeClass(
					'notransition');
			}, 1);
		}

	},

	_highlightClip: function () {
		var plugin = this,
			outPoint = null,
			inPoint = null,
			cloneTable = null,
			tmp = null;
		plugin._log('_highlightClip');
		plugin._videoWindow.find(".tabPane.log tr").removeClass("inPoint outPoint subSelect partial");
		inPoint = plugin._findNearestLogEntry(plugin._reelData.in);
		inPoint.addClass('inPoint');
		if (plugin._reelData.in > Number(inPoint.find('.offset').text())) {
			inPoint.addClass('partial');
		}
		//setOffset('in', newInOffset, false);

		if (plugin._reelData.out !== null && plugin._reelData.out != -1) {
			outPoint = plugin._findNearestLogEntry(plugin._reelData.out);
			outPoint.addClass('outPoint');

			if (plugin._reelData.out > Number(outPoint.find('.offset').text())) {
				outPoint.addClass('partial');
			}

		}

		if ((!outPoint || outPoint.nextAll().length === 0) &&
			inPoint.prevAll().length === 0) {
			return;
		}

		cloneTable = plugin._videoWindow.find(".tabPane.log table.textLog").clone();
		tmp = cloneTable.find("tr.inPoint").length ? cloneTable.find("tr.inPoint").nextAll().addBack() :
			cloneTable.find("tr");
		tmp.each(function () {

			if ($(this).hasClass('outPoint')) {
				if ($(this).hasClass('partial')) {
					$(this).addClass('subSelect');
				}

				return false;
			}
			$(this).addClass('subSelect');

		});
		plugin._videoWindow.find(".tabPane.log table.textLog").replaceWith(cloneTable);
	},

	_clearLogHighlighting: function () {
		var plugin = this;
		plugin._videoWindow.find(".tabPane.log tr.current").removeClass('current');

	},

	_getCurrentLogEntry: function (offset) {
		var plugin = this,
			entry = plugin._findNearestLogEntry(offset);

		if (plugin._tapeJSON.hasVideo) {
			if (entry.hasClass('current')) {
				return;
			} else {
				entry.siblings().removeClass('current');
				entry.addClass('current');
			}
		}
		if (entry) {
			var desc,
				html = entry.html(),
				logId = entry.attr('id') ? entry.attr('id').substr('desc'.length) : 0,
				emSize = Number(plugin._player.find(".logDisplay")
					.css('fontSize').match(/(\d*(\.\d*)?)px/)[1]);

			if (entry.find('.logMusic').length) {
				desc = entry.find('.desc').html();
			} else {
				desc = plugin._fitStringToSize(
					entry.find('.desc').html(),
					plugin._player.find(".logDisplay").width() - (emSize * 5.5),
					plugin._player.find(".logDisplay").height(),
					'logDisplay',
					plugin._player.find('.playerDiv')
				);
			}

			return $('<div/>')
				.append(
					$('<span/>').addClass('timecode').html(entry.find('.tc').html())
				).append($('<span/>').addClass('desc').html(desc))
				.html();
		}
		return '';
	},

	_getOffsetsOfLogEntry(logId) {
		var plugin = this,
			highlightLogId = logId,
			logEntryEl = $('#desc' + logId, plugin._videoWindow),
			logEntryOffsetIn,
			logEntryOffsetOut;

		logEntryOffsetIn = Number($('.timecode .offset', logEntryEl).text());

		if ($('#desc' + logId).is(":last-child")) {
			logEntryOffsetOut = Number(plugin._getEndOffset());
		} else {
			logEntryOffsetOut = Number($('.timecode .offset', logEntryEl.next()).text());
		}

		if (logEntryOffsetOut < logEntryOffsetIn) {
			logEntryOffsetOut = null;
		}

		return {
			'in': logEntryOffsetIn,
			'out': logEntryOffsetOut
		};
	},

	//Public Video controls
	pauseVideo: function () {
		var plugin = this;
		if (!plugin._player) {
			return;
		}
		if (plugin._player.videoPlayer('videoIsLoaded')) {
			plugin._player.videoPlayer("pause");
		}
	},

	setVideoTime: function (seconds, switchTab) {
		var plugin = this;
		switchTab = (switchTab == undefined ? true : switchTab);
		
		if (switchTab) {
			plugin._videoWindow.find('.tab.video:visible').trigger('click');
		}

		seconds = Math.max(0, seconds);

		if (seconds > plugin._tapeJSON.length) {
			seconds = plugin._tapeJSON.length;
		}
		plugin._player.videoPlayer("externalSeek", seconds);
	},

	setPlayerOffsets: function (offsets) {
		this._player.videoPlayer("setClip", offsets.in, offsets.out);

	},

	unloadVideo: function () {
		this._player.videoPlayer("unload");

	},

	resize: function() {
		
		//this._player.videoPlayer("resize");
	},

	//video player callbacks
	_videoPlayerSetIn: function (event, data) {
		var plugin = this,
			currentTime = new Date();
		if (data.value != plugin._reelData.in &&
			currentTime.getTime() > plugin._lastPlayerClipUpdate + plugin.options.playerClipUpdateFrequency
		) {
			plugin.setOffset('in', data.value, true /*update share links */ , false /*don't update player */ );
		}
	},
	_videoPlayerSetOut: function (event, data) {
		var plugin = this,
			currentTime = new Date();
		if (data.value != plugin._reelData.out &&
			currentTime.getTime() > plugin._lastPlayerClipUpdate + plugin.options.playerClipUpdateFrequency
		) {
			plugin.setOffset('out', data.value, true /*update share links */ , false /*don't update player */ );
		}
	},
	_videoPlayerSetVolume: function (event, data) {
		Cookies.set('playerVolume', data.value, {
			expires: HF_GLOBALS.volumeCookieLifetime,
			path: HF_GLOBALS.COOKIE_PATH
		});
		if (data.value === 0) {
			Cookies.set('maualMute', 'true', {
				path: HF_GLOBALS.COOKIE_PATH
			});
		} else {
			Cookies.set('maualMute', null, {
				path: HF_GLOBALS.COOKIE_PATH
			});
		}
	},
	_videoPlayerTimeUpdate: function (event, data) {
		var plugin = this;
		if (!plugin._videoWindow)
			return;
		if (plugin._videoWindow.find("#lockLog").is(":checked") && !$(".log").hasClass('active')) {
			plugin._scrollToNearestLogEntry(data.offset);
		}
	},

	//Clip Controls

	setOffsets: function (newInOffset, newOutOffset, updatePlayer, setPlayerPos) {
		var plugin = this;

		updatePlayer = updatePlayer === undefined ? true : updatePlayer;
		setPlayerPos = setPlayerPos === undefined ? true : setPlayerPos;

		newInOffset = Number(newInOffset);
		newOutOffset = Number(newOutOffset);

		if (plugin._loadingNewClip || newInOffset != plugin._reelData.in || newOutOffset != plugin._reelData
			.out) {

			plugin._reelData.in = newInOffset; //Math.round(newInOffset);
			plugin._reelData.out = newOutOffset; //Math.ceil(newOutOffset);
			plugin._log('Set Offsets', plugin._reelData);
			if (updatePlayer) {
				this.setPlayerOffsets(plugin._reelData);
				if (setPlayerPos) {
					switch (setPlayerPos) {
					case 'in':
						plugin.setVideoTime(plugin._reelData.in, false);
						break;
					case 'out':
						plugin.setVideoTime(plugin._reelData.out, false);
						break;
					}
				}
			}

			plugin._highlightClip();
			plugin._updateShareURL();
			if (!plugin._loadingNewClip && plugin._reelData.writable && plugin._reelData.clip) {
				plugin._clipChanged = true;
				plugin._videoWindow.find(".save").show();
				plugin.options.clipChangedCallback(plugin._reelData);
			}
			plugin._videoWindow.find(".accessDetails .thumb").attr('src', fieldDisplay.getThumbnailUrlFromOffset(
				plugin._reelData.reelId, newInOffset, 'large'));
		}
	},

	setOffset: function (which, value, updateShare, updatePlayer, jumptoPos) {

		var plugin = this,
			newValue = false;

		updateShare = updateShare === undefined ? true : updateShare;
		updatePlayer = updatePlayer === undefined ? true : updatePlayer;
		jumptoPos = jumptoPos === undefined ? true : jumptoPos;

		switch (which) {
		case 'in':
			if (value != plugin._reelData.in) {

				plugin._reelData.in = value;

				if (updatePlayer /* && plugin._currentReelhasVideo */ ) {
					plugin._player.videoPlayer("setIn", value, true);
				}
				newValue = true;
			}
			break;
		case 'out':
			if (value != plugin._reelData.out) {
				plugin._reelData.out = value;

				if (updatePlayer /* && plugin._currentReelhasVideo */ ) {
					plugin._player.videoPlayer("setOut", value, true);
				}
				newValue = true;
			}
			break;
		}

		if (newValue) {
			plugin._highlightClip();
			if (updateShare) {
				plugin._updateShareURL();
			}
			if (!plugin._loadingNewClip && plugin._reelData.writable && plugin._reelData.clip) {
				plugin._clipChanged = true;
				plugin._videoWindow.find(".save").show();
			}
			plugin._videoWindow.find(".accessDetails .thumb").attr('src', fieldDisplay.getThumbnailUrlFromOffset(
				plugin._reelData.reelId, plugin._reelData.in, 'large'));

			plugin.options.clipChangedCallback(plugin._reelData);
		}
		if (jumptoPos) {
			plugin.setVideoTime(value, false);
		}

	},

	//sharing

	_getEndOffset: function () {
		var plugin = this,
			endTc,
			endOffset;

		if (plugin._tapeJSON.length) {
			endOffset = Number(plugin._tapeJSON.length);
		} else if (plugin._tapeJSON.endTimecode) {
			endTc = new Timecode(plugin._tapeJSON.endTimecode, plugin._tapeJSON.fps);
			if (plugin._playerTimecodeOffset === null) {
				var tc = new Timecode(plugin._tapeJSON.startTimecode, plugin._tapeJSON.fps);
				endOffset = endTc.toPosition() - tc.toPosition(true, false);
			} else {
				endOffset = endTc.toPosition() - plugin._playerTimecodeOffset;
			}
		} else {
			endOffset = null;
		}
		if (endOffset <= 0) {
			endOffset = null;
		}
		return endOffset;
	},

	_updateShareURL: function () {
		var plugin = this,
			clipInfo = plugin.getClipInfo(),
			title, truncated, maxLength;

		plugin._shareUrl = bins.getClipUrl(clipInfo, null, true);
	
	},

	_postToFeed: function (obj) {

		// calling the API ...
		var plugin = this,
			postObj = {
			method: 'share',
			//display: 'popup',
			//redirect_uri: $('base').attr('href') + 'redirect.html?tapeId=' + currentTapeId,
			href: obj.data('shareUrl') ? obj.data('shareUrl') : location.href
		};

		FB.ui(postObj, function (response) {
			if (response && !response.error_message) {
				plugin.options.logShare(plugin._tapeJSON.id, 'fb');
			} else {
				// Nothing was posted
			}
		});
	},

	_initShareDialog: function (container) {
		let plugin = this;
		if (!container || !container.length) {
			return;
		}

		container.dialog({
			autoOpen: false,
			modal: true,
			width: 375,
			resizable: false,
			open: function (event, ui) {},
			close: function () {}
		});

		container.find(".fb-feed").on('click', function () {
			plugin._postToFeed($(this));
		});

		container.find('.twitter-share-button').on('click', function () {
			var windowWidth = 550;
			var windowHeight = 225;
			var left = (screen.width - windowWidth) / 2;
			var top = (screen.height - windowHeight) / 2;
			var popup = window.open($(this).attr('href'), '',
				'width=' + windowWidth +
				',height=' + windowHeight +
				',top=' + top +
				',left=' + left);
			return popup ? false : true;

		});

		container.find('.emailClip').on('click', function () {
			plugin.options.showMailClipDialog(plugin.getClipInfo());
			return false;
		});

		container.find('.copyLink').on('click', function() {
			 navigator.clipboard.writeText(plugin._shareUrl);
		})

		plugin._videoWindow.find('.mobileTools .share').on('click', function () {
			dialogs.openDialog(container);
		});
		container.find('a').on('click', function () {
			container.dialog('close');
		});
	},

	getClipInfo: function () {
		var plugin = this;
		if (!plugin._reelData.reelId) {
			return null;
		}
		return {
			reelId: plugin._reelData.reelId,
			clip: plugin._reelData.clip,
			in : Math.round(plugin._reelData.in * 100) / 100,
			out: plugin._reelData.out ? Math.round(plugin._reelData.out * 100) / 100 : plugin._getEndOffset(),
			comment: plugin._videoWindow.find("#clipTitle").html() == plugin.options.defaultClipTitleMarkup ?
				'' : plugin._videoWindow.find("#clipTitle").text(),
			specialtyCollection: plugin._tapeJSON.specialtyCollection,
			temporailyUnavailable: plugin._tapeJSON.temporailyUnavailable,
		};
	},

	getClipText: function () {
		var plugin = this,
			text = '';

		plugin._videoWindow.find('.tabPane.log .textLog .subSelect .desc').each(function () {
			text += (text ? ' ' : '') + $(this).text();
		});

		return text;

	},

	//video player thumbnail preview
	_getPlayerThumbnailSrc: function (pos) {
		var plugin = this,
			stripNum, playerFile;
		if (!plugin._tapeJSON || !plugin._tapeJSON.thumbnails) {
			return '';
		}
		if (plugin._reelData.clip && plugin._reelData.clip.hasSpecialAccess && plugin._tapeJSON.thumbnails
			.playerThumbnails) {
			stripNum = Math.max(0, Math.floor((pos / HF_GLOBALS.PLAYER_THUMBNAIL_INCREMENT) /
				HF_GLOBALS.PLAYER_THUMBNAILS_PER_STRIP));
			playerFile = HF_GLOBALS.thumbnailsUrlPath + plugin._reelData.clip.clipType + '/' + plugin._reelData.clip.hashId +
				'/player/' +
				stripNum + '.jpg';
		} else if (plugin._tapeJSON.thumbnails.playerThumbnails) {
			stripNum = Math.max(0, Math.floor((pos / HF_GLOBALS.PLAYER_THUMBNAIL_INCREMENT) /
				HF_GLOBALS.PLAYER_THUMBNAILS_PER_STRIP));
			playerFile = HF_GLOBALS.thumbnailsUrlPath + plugin._tapeJSON.id + '/player/' + stripNum + '.jpg';
		} else {
			Math.max(0, stripNum = Math.floor((pos / HF_GLOBALS.THUMBNAIL_INCREMENT) / HF_GLOBALS.THUMBNAILS_PER_STRIP));
			playerFile = HF_GLOBALS.thumbnailsUrlPath + plugin._tapeJSON.id + '/large/strip/' + stripNum + '.jpg';
		}
		return playerFile;
	},

	_getThumbnailBackgroundPos: function (container, index, aspectRatio) {

		var plugin = this,
			width = container[0].getBoundingClientRect().width,
			height = width * plugin._getPlayerThumbnailAspectRatio(aspectRatio),
			pos = index * height;
		return "0px -" + pos + "px";
	},

	_getPlayerThumbnailAspectRatio: function (aspectRatio) {
		if (aspectRatio == 0.7272) {
			return 175 / 240;
		}
		return aspectRatio;
	},

	_bufferNextThumbnail: function (pos) {

		var plugin = this;

		if (!plugin._imageBuffer) {
			plugin._imageBuffer = $('<img/>');
		}
		if (plugin._tapeJSON.thumbnails.playerThumbnails) {
			plugin._imageBuffer.attr('src', plugin._getPlayerThumbnailSrc(pos +
				HF_GLOBALS.PLAYER_THUMBNAILS_PER_STRIP *
				HF_GLOBALS.PLAYER_THUMBNAIL_INCREMENT));
		} else {
			plugin._imageBuffer.attr('src', plugin._getPlayerThumbnailSrc(pos + HF_GLOBALS.THUMBNAILS_PER_STRIP *
				HF_GLOBALS.THUMBNAIL_INCREMENT));
		}

	},

	_updateThumbnail: function (container, pos) {
		var plugin = this,
			stripPos;
		if (!plugin._tapeJSON || !plugin._tapeJSON.thumbnails) {
			return '';
		}
		if (plugin._reelData.clip && plugin._reelData.clip.hasSpecialAccess && plugin._tapeJSON.thumbnails
			.playerThumbnails) {
			pos += Math.floor(plugin._reelData.clip.parentOffsetIn);
		}
		if (plugin._tapeJSON.thumbnails.playerThumbnails) {
			stripPos = Math.floor(pos / HF_GLOBALS.PLAYER_THUMBNAIL_INCREMENT) % HF_GLOBALS.PLAYER_THUMBNAILS_PER_STRIP;
		} else {
			var thumbsPerStrip = HF_GLOBALS.THUMBNAILS_PER_STRIP;
			stripPos = Math.floor(pos / HF_GLOBALS.THUMBNAIL_INCREMENT) % HF_GLOBALS.THUMBNAILS_PER_STRIP;
		}

		var thumbnailSrc = plugin._getPlayerThumbnailSrc(pos);
		if (thumbnailSrc) {
			var backgroundPos = plugin._getThumbnailBackgroundPos(container, stripPos, plugin._tapeJSON
				.aspectRatio);
			if (!container.css('background-image').match(thumbnailSrc)) {
				container.css('background-image', "url('" + thumbnailSrc + "')");
			}
			container.data('pos', pos);
			container.css('background-position', backgroundPos);

			if (pos % HF_GLOBALS.THUMBNAILS_PER_STRIP === 0) {
				plugin._bufferNextThumbnail(pos);
			}
		}
	},

	//Player History

	setPlayerHistory: function (data) {
		var plugin = this,
			oldestItem;
		try {
			Cookies.set('playerHistory', null);
			if (Modernizr.localStorage) {
				if (!data) {
					data = {
						index: []
					};
				}
				localStorage.playerHistory = JSON.stringify(data);
			}
		} catch (e) {
			oldestItem = data.index.pop();
			delete data['t' + oldestItem];
			if (data.index.length) {
				plugin.setPlayerHistory(data);
			}

		}
	},

	getPlayerHistory: function () {
		if (Modernizr.localStorage) {
			if (!localStorage.playerHistory) {
				localStorage.playerHistory = JSON.stringify({
					index: []
				});
			}

			return $.parseJSON(localStorage.playerHistory);
		}
		return null;
	},

	storePlayerPosition: function (tapeId, time) {
		var plugin = this,
			oldestItem = null,
			oldIndex = -1,
			playerHistory = plugin.getPlayerHistory();

		if (!playerHistory) {
			return;
		}

		time = Math.floor(time);
		if (!time) {
			return;
		}
		while (playerHistory.index.length >= HF_GLOBALS.PLAYER_HISTORY_MAX_COUNT - 1) {
			oldestItem = playerHistory.index.pop();
			delete playerHistory['t' + oldestItem];
		}

		playerHistory['t' + tapeId] = time;
		oldIndex = playerHistory.index.indexOf(tapeId);
		if (oldIndex != -1) {
			playerHistory.index.splice(oldIndex, 1);
		}
		playerHistory.index.unshift(tapeId);
		try {
			plugin.setPlayerHistory(playerHistory);
		} catch (ex) {

		} finally {
			if (HF_AUTH.isLoggedIn) {
				$.ajax({
					data: {
						action: 'updatePlayerhistory',
						json: JSON.stringify(playerHistory)
					}
				});
			}
		}

	},

	//displaying clips -- Helpers

	_makeClipTitleEditable: function () {
		var plugin = this,
			clipTitle = plugin._videoWindow.find("#clipTitle");

		clipTitle.attr('title', "Click here to edit this clip's label")
			.editInPlace({
				html: false,
				multiline: false,
				saveCallBack: function (obj, text, originalContents) {

					var newClipTitle;
					plugin._videoWindow.find(".save").removeClass('hideForEdit');

					if ($("<div/>").append(text).html() ==
						$("<div/>").append(plugin.options.defaultClipTitleMarkup).html()) {
						text = "";
					} else {
						text = $("<div/>").append(text).text();
					}
					if (!text) {
						obj.html(plugin.options.defaultClipTitleMarkup);
						if (originalContents == plugin.options.defaultClipTitleMarkup) {
							return;
						}
					} else if (originalContents == text) {
						return;
					}
					newClipTitle = text;
					//if (!loadingNewClipDfd && currentClipId && !shareBin) {
					if (plugin._reelData.clip) {
						plugin._clipChanged = true;
						plugin._videoWindow.find(".save").show();
					}
					// plugin._videoWindow.find("#clipTitleTouchOverlay").css({
					//     width: clipTitle.width() + 25

					// }).show();
					plugin._updateShareURL();

				},
				cancelCallBack: function (obj) {
					plugin._videoWindow.find(".save").removeClass('hideForEdit');
					plugin._videoWindow.find("#clipTitleTouchOverlay").show();
				},
				startCallBack: function (obj) {
					plugin._videoWindow.find(".save").addClass('hideForEdit');
				},
				outsideClick: 'cancel',
				defaultMarkUp: plugin.options.defaultClipTitleMarkup,

				wysiwyg: false
			});
		// plugin._videoWindow.find("#clipTitleTouchOverlay").on('click', function(e) {
		//     e.stopPropagation();
		//     clipTitle.trigger('click');
		//     $(this).hide();
		//     return false;
		// });
	},

	_drawReelInfo: function () {
		var plugin = this,
			reelInfo = this._videoWindow.find('.reelInfo'),
			year = fieldDisplay.displayRange(plugin._tapeJSON.yearStart, plugin._tapeJSON.yearEnd),
			genre = fieldDisplay.displayField(plugin._tapeJSON.genre);

		this._videoWindow.find(".tapeId").show().text(plugin._tapeJSON.tapeId);
		reelInfo.find(".tapeField").hide();

		if (year) {
			reelInfo.find(".year").show().html(year);
		} else {
			reelInfo.find(".year").hide().html('');
		}

		if (genre) {
			reelInfo.find(".genre").show().html(genre);
		} else {
			reelInfo.find(".genre").hide().html('');
		}

		if (plugin._tapeJSON.description) {
			reelInfo.find(".description").css('display', 'block');
			reelInfo.find("span.description").empty().append($('<span/>').addClass('content').html(
				fieldDisplay.nl2br(plugin._tapeJSON.description)));
		}

		if (HF_GLOBALS.DISPLAY_KEYWORDS) {
			var keywordStr = fieldDisplay.formatKeywords(plugin._tapeJSON.keywords);
			if (!keywordStr) {
				reelInfo.find(".keywords").hide();

			} else {
				reelInfo.find(".keywords").show();
				reelInfo.find("span.keywords").html(keywordStr);
			}
		}
	},

	_clearFiller: function (string, escapsStr, filler) {
		escapsStr = escapsStr === undefined ? true : escapsStr;
		filler = filler === undefined ? '|||' : filler;
		if (escapsStr) {
			return fieldDisplay.escapeString(string && string != filler ? string : '');
		} else {
			return string && string != filler ? string : '';
		}
	},

	_drawTapeLog: function (showThumbnails) {
		var plugin = this;
		if (plugin._tapeJSON.log && !$.isEmptyObject(plugin._tapeJSON.log)) {
			plugin._videoWindow.removeClass('no-log');
			var table = $("<table />").addClass('textLog');
			var inClip = false;
			var count = 0;
			$("#lockLog").prop('checked', Cookies.get('lockLog') !== 'false');
			$.each(plugin._tapeJSON.log, function (name, value) {
				var offset = Number(value.offset);
				if (plugin._tapeJSON.logOffset) {
					offset -= Number(plugin._tapeJSON.logOffset);
				}
				var tr = $("<tr>")
					.attr('id', 'desc' + value.id)
					.data('id', value.id)
					.append(
						$("<td />")
						.addClass("timecode")
						.append($('<span>').addClass('tc').text(fieldDisplay.trimFrames(value.timecode)))
						.append($('<span>').addClass('offset').hide().text(offset))

					),
					logContents = $("<td/>")
					.addClass("logContents")
					.addClass("desc");
				tr.append(logContents);
				if (showThumbnails) {
					var url = value.smallThumbnail !== undefined ?
						value.smallThumbnail :
						fieldDisplay.getThumbnailUrlFromOffset(plugin._tapeJSON.id, value.offset, 'small');
					logContents.append($('<img/>')
						.addClass('smallThumb')
						.attr('alt', 'thumbnail')
						.attr('src', url)
						.attr('loading', 'lazy')

					);
				}
				if (value.artist || value.title) {
					var artist = plugin._clearFiller(value.artist, false),
						title = plugin._clearFiller(value.title, false),
						performanceType = plugin._clearFiller(value.performanceType);
					if (performanceType) {
						performanceType = ' (' + performanceType + ')';
					}

					logContents.append($("<div />")
						.addClass("logMusic")
						.attr('title', "Musical Performance")
						.append($("<span />")
							.addClass("artist")
							.attr('id', 'logArtist' + value.id)
							.html(artist)
						)
						.append(artist && title ? ' - ' : '')
						.append($("<span />")
							.addClass("title")
							.attr('id', 'logTitle' + value.id)
							.html(title)
						)
						.append($("<span />")
							.addClass("type")
							.attr('id', 'logType' + value.id)
							.text(performanceType)
						)
					);
				}
				if (value.description) {
					logContents.append($("<div />")
						.addClass("logDescription")
						.attr('id', 'logDescription' + value.id)
						.html(fieldDisplay.nl2br(plugin._clearFiller(value.description, false)))
					);
				}
				if (offset < -1 || offset > plugin._tapeJSON.length) {
					tr.addClass('outsideClip');
				}
				table.append(tr);

				if (count % 2 === 0) {
					tr.addClass('even');
				}
				count++;

			});

			// $("subSelect:first", table).addClass("inPoint");

			// if ($("tr:not(.subSelect)", table).length === 0) {
			//     $("tr.subSelect", table).removeClass('subSelect');
			// }

			plugin._videoWindow.find(".tabPane.log table.textLog").replaceWith(table).scrollTop(0);

			plugin._videoWindow.find(".tabs a.log").trigger('click');
		} else {
			plugin._videoWindow.addClass('no-log');
			plugin._videoWindow.find(".tabPane.log table.textLog").empty();
		}
	},

	_drawTranscript: function () {
		var plugin = this;
		if (plugin._tapeJSON.hasTranscript) {
			$.when(plugin.options.getTranscript(plugin._tapeJSON)).done(function (data) {
				var table = $("<table />").addClass('textLog'),
					startTimecode = new Timecode(plugin._tapeJSON.startTimecode === undefined ? '00:00:00' :
						plugin._tapeJSON.startTimecode, plugin._tapeJSON.fps);

				$.each(data.transcripts, function (name, value) {
					var offset = Number(value.start_offset) / 16000;

					if (plugin._tapeJSON.logOffset) {
						offset -= Number(plugin._tapeJSON.logOffset);
					}
					var tr = $("<tr>")
						.data('id', value.id)
						.append(
							$("<td />")
							.addClass("timecode")
							.append($('<span>').addClass('tc').text(fieldDisplay.trimFrames(timecodeFunctions.offsetToTimecode(startTimecode,
								offset))))
							.append($('<span>').addClass('offset').hide().text(offset))

						),
						logContents = $("<td/>")
						.addClass("logContents")
						.addClass("desc");
					tr.append(logContents);

					logContents.append($("<div />")
						.addClass("logDescription")
						.text(value.transcript)
					);

					if (offset < -1 || offset > plugin._tapeJSON.length) {
						tr.addClass('outsideClip');
					}
					table.append(tr);
				});
				plugin._videoWindow.find(".tabPane.transcript table.textLog").replaceWith(table).scrollTop(
					0);
			}).fail(function () {
				plugin._videoWindow.addClass('no-transcript');
				plugin._videoWindow.find(".transcript table.textLog").empty();
			});

		} else {
			plugin._videoWindow.addClass('no-transcript');
			plugin._videoWindow.find(".tabPane.transcript table.textLog").empty();
		}
	},

	_getThumbnails: function () {
		var plugin = this,
			dfd = new $.Deferred();
		if (plugin._tapeJSON.thumbnails) {
			dfd.resolve(plugin._tapeJSON.thumbnails);
		} else {
			$.ajax({
				data: {
					action: 'getThumbnails',
					tapeId: plugin._reelData.reelId,
					clipId: plugin._reelData.clip ? plugin._reelData.clip.id : '',
					clipType: plugin._reelData.clip ? plugin._reelData.clip.clipType : ''
				}
			}).done(function (data) {
				var obj = $.parseJSON(data);
				dfd.resolve(obj);
			}).fail(function () {
				dfd.reject();
			});
		}
		return dfd.promise();
	},

	_drawThumbnails: function (thumbnails) {
		var plugin = this;
		plugin._videoWindow.find(".tabPanel .thumbnails").empty();
		plugin._videoWindow.find(".tabs .thumbnails").show();
		if (thumbnails.hasOwnProperty('smallThumbnails')) {
			var numThumbnails = thumbnails.smallThumbnails.length;

			for (let i = 0; i < numThumbnails; i++) {

				plugin._videoWindow.find(".tabPanel .thumbnails").append(
					$('<span/>').attr('class', 'thumbBtn').data('index', i).append(
						$('<img />')
						.attr('src', thumbnails.smallThumbnails[i])
						.attr('alt', 'Thumbnail ' + i)
						.attr('id', 'thumb' + i)
					)
				);
			}
		} else {
			plugin._videoWindow.find(".tabs .thumbnails").hide();
		}
	},

	_drawTapeInfo: function (data) {

		data = $.extend({
			tape: null,
			specialAccess: false,
			temporailyUnavailable: false,
			includeAudio: true
		}, data);
		

		var plugin = this,
			dfd = new $.Deferred(),
			thumbnailOffset = data.in ? data.in : (data.tape.thumbnailNum ? data.tape.thumbnailNum :
				data.tape.reelThumbnailNum);
			//var timecodes = fieldDisplay.displayTCRange(data.tape.startTimecode, data.tape.endTimecode);

		if (plugin._tapeJSON && plugin._tapeJSON.id != data.tape.id) {
			plugin._player.videoPlayer("unload");
		}
		plugin._tapeJSON = data.tape;

		//set defaults
		plugin._videoWindow.find(".lockWithLog").remove();
		plugin._videoWindow.removeClass('no-video specialty restricted specialAccess standardAccess specialAccessExpired mos temporailyUnavailable');
		//plugin._videoWindow.find(".downloadClip").removeClass('disabled');
		plugin._videoWindow.find(".accessDetails .thumb").remove();
		plugin._videoWindow.find(".save").hide();

		if (data.tape.startTimecode) {
			var tc = new Timecode(data.tape.startTimecode, data.tape.fps);
			plugin._playerTimecodeOffset = tc.toPosition(true, false);
		} else {
			plugin._playerTimecodeOffset = 0;
		}
		
		if (data.tape.MOS) {
			plugin._videoWindow.addClass('mos');
		}
		if (data.tape.specialtyCollection) {
			plugin._videoWindow.addClass('specialty');
			if (!data.specialAccess) {
				plugin._videoWindow.addClass('no-video');
			}
		}
		
		
		if (!data.showPlayer) {
			plugin._videoWindow.addClass('no-video');
		} else {
			plugin._videoWindow.find(".tabPane.log").prepend(
                    '<div class="lockWithLog"><span class="checkbox lockLog"><input class="lockLog" type="checkbox" id="lockLog"/><label for="lockLog"></label></span><label class="lockLog"  for="lockLog">Scroll with video playback</label></div>'
             );
		}
		
		if (data.temporailyUnavailable) {
			plugin._videoWindow.addClass('no-video temporailyUnavailable');
		} else {
			if (data.specialAccess) {
				plugin._videoWindow.find(".accessDetails .remaining").text(
							`${data.accessExpiry} more day${(data.accessExpiry > 1 ? 's' : '')}`);
				plugin._videoWindow.addClass('specialAccess');
				if (!data.tape.specialtyCollection) {
					plugin._videoWindow.addClass('restricted');
				}
			} else if (data.accessExpiry < 0) {
				plugin._videoWindow.addClass('specialAccessExpired');
				if (!data.tape.specialtyCollection) {
					plugin._videoWindow.addClass('restricted');
				}
			} else {
				plugin._videoWindow.addClass('standardAccess');
			}
		}


		if (!data.temporailyUnavailable && (data.showPlayer || data.tape.specialtyCollection)) {
			plugin._videoWindow.find(".downloadClip").removeClass('disabled');
			plugin._videoWindow.find(".tools .downloadClip span").text('Download a low-res version');
		} else {
			plugin._videoWindow.find(".downloadClip").addClass('disabled');
			plugin._videoWindow.find(".downloadClip span").text('Not available for download');
		}

		if (data.tape.length) {
			plugin._videoWindow.find(".clipTools .length").html(fieldDisplay.getLengthDisplayString(data.tape.length));
		} else {
			plugin._videoWindow.find(".clipTools .length").html('');
		}

		plugin._drawReelInfo();
		if (data.isWritable) {
			plugin._makeClipTitleEditable();
		}

		plugin._drawTapeLog(data.tape.hasThumbnails);
		
		if (data.tape.hasThumbnails) {
			$("#hiddenPlayerContent .tabPane.thumbnails").appendTo(plugin._videoWindow.find(".info .tabPanel"));
			$("#hiddenPlayerContent a.thumbnails").prependTo(plugin._videoWindow.find(".info .tabs"));
			plugin._videoWindow.find(".accessDetails").prepend(
				$('<img/>').addClass("thumb").attr('src', fieldDisplay.getThumbnailUrlFromOffset(data.tape.id,
					thumbnailOffset, 'large'))
			);
			$.when(plugin._getThumbnails()).done(function (thumbnails) {
				plugin._drawThumbnails(thumbnails);

				plugin._tapeJSON.thumbnails = thumbnails;
				if (thumbnails.smallThumbnails.length) {
					plugin._videoWindow.addClass('has-thumbnails');
				} else { //this is an edge case when there is video but no thumbnails.
					plugin._videoWindow.removeClass('has-thumbnails');
					plugin._videoWindow.find(".info .tabPane.thumbnails").appendTo($("#hiddenPlayerContent"));
					plugin._videoWindow.find(".info .tabs a.thumbnails").appendTo($("#hiddenPlayerContent"));
				}
				dfd.resolve();
			}).fail(function () {

				plugin._videoWindow.removeClass('has-thumbnails');
				plugin._videoWindow.find(".info .tabPane.thumbnails").appendTo($("#hiddenPlayerContent"));
				plugin._videoWindow.find(".info .tabs a.thumbnails").appendTo($("#hiddenPlayerContent"));
				dfd.reject();
			});
		} else {

			plugin._videoWindow.removeClass('has-thumbnails');
			plugin._videoWindow.find(".info .tabPane.thumbnails").appendTo($("#hiddenPlayerContent"));
			plugin._videoWindow.find(".info .tabs a.thumbnails").appendTo($("#hiddenPlayerContent"));
			dfd.resolve();
		}

		return dfd.promise();
	},

	_showVideo: function (opts) {

		opts = $.extend({
			path: '',
			startTimecode_str: '',
			endTimecode_str: '',
			fps: '',
			play: false,
			newInOffset: null,
			newOutOffset: null,
			receiveAudio: true,
			clipTrimming: true,
			showFineTuningBar: true,
			aspectRatio: 0.75
		}, opts);

		var plugin = this,
			endTc = new Timecode(opts.endTimecode_str, opts.fps),
			startTc = new Timecode(opts.startTimecode_str, opts.fps),
			duration = 0;

		plugin._videoWindow.find(".tabs").show();

		plugin._playerTimecodeOffset = startTc.toPosition(true, false); //don't round this

		if (!opts.newOutOffset) {
			opts.newOutOffset = endTc.toPosition(true, false) - plugin._playerTimecodeOffset;
		}
		plugin.setOffsets(opts.newInOffset, opts.newOutOffset, false /*don't update palyer */ );

		duration = endTc.toPosition(true, false) - plugin._playerTimecodeOffset;

		var options = {
			file: Modernizr.hls && !HF_GLOBALS.DISABLE_HLS ? opts.path.replace(HF_GLOBALS.videoExtension, 'm3u8') : opts.path,
			mimeType: Modernizr.hls && !HF_GLOBALS.DISABLE_HLS ? 'application/x-mpegurl' : 'video/mp4',
			duration: endTc.toPosition() - plugin._playerTimecodeOffset,
			inPoint: plugin._reelData.in,
			outPoint: plugin._reelData.out,
			startOffset: plugin._playerTimecodeOffset,
			fps: opts.fps,
			disableClipTrimming: !opts.clipTrimming,
			disableAudio: !opts.receiveAudio,
			showFineTuningBar: opts.showFineTuningBar,
			aspectRatio: opts.aspectRatio,
			showVideo: opts.showVideo
		};

		try {
			return $.when(plugin._player.videoPlayer("load", options)).done(function () {
				if (plugin._reelData.in === 0 && plugin._reelData.out == endTc.toPosition() - startTc.toPosition()) {

					var playerHistory = getPlayerHistory();
					if (playerHistory['t' + $("#videoWindow").data('tapeId')]) {
						plugin.setVideoTime(playerHistory['t' + $("#videoWindow").data('tapeId')]);
					}

				}
			});
		} catch (err) {

		}
	},

	_setCurrentTab: function() {
		let plugin = this,
			tabClasses ='video thumbnails details log transcript';
		plugin._videoWindow.find('.playerAreaInner').removeClass('noTabs');
		if (!plugin._videoWindow.hasClass('no-video')) {

			if (plugin._videoWindow.width() < HF_GLOBALS.collapsedPlayerThreshold) {
				if (!plugin._videoWindow.find('.player').data('setTop')) {
					plugin._videoWindow.removeClass(tabClasses).addClass('video');
					plugin._videoWindow.find('.info').removeClass(tabClasses).addClass('video');
					//console.log(plugin._videoWindow.find('.accessDetails').height(),plugin._videoWindow.find('.tabPanel').offset().top,plugin._videoWindow.offset().top);
					plugin._videoWindow.find('.player').data('setTop', true).css('top', 
						plugin._videoWindow.find('.tabPanel').offset().top - plugin._videoWindow.offset().top + 25);
				}
			} else {
				if (plugin._videoWindow.find('.player').data('setTop')) {
					plugin._videoWindow.removeClass(tabClasses).addClass('log');
					plugin._videoWindow.find('.info').removeClass(tabClasses).addClass('log');
					plugin._videoWindow.find('.player').data('setTop', false).css('top', '');
				}

			}
		} else if (!plugin._videoWindow.hasClass('no-log')) {
			plugin._videoWindow.removeClass(tabClasses).addClass('log');
			plugin._videoWindow.find('.info').removeClass(tabClasses).addClass('log');
		} else if (plugin._tapeJSON.hasThumbnails) {
			plugin._videoWindow.removeClass(tabClasses).addClass('thumbnails');
			plugin._videoWindow.find('.info').removeClass(tabClasses).addClass('thumbnails');
			
		} else {
			plugin._videoWindow.find('.playerAreaInner').addClass('noTabs');
			if (plugin._videoWindow.width() < HF_GLOBALS.collapsedPlayerThreshold) {
				plugin._videoWindow.removeClass(tabClasses).addClass('video');
				plugin._videoWindow.find('.info').removeClass(tabClasses).addClass('video');
			}
		} 	

		/* else if (plugin._tapeJSON.hasTranscript) {
			    plugin._videoWindow.addClass('transcript');
			    plugin._videoWindow.find('.info').addClass('transcript');
			} */
	},

	_drawPlayerContents: function (data) {
		var plugin = this,
			dfd = new $.Deferred();

		if (data.notes && data.notes != data.tape.tapeId) {
			plugin._videoWindow.find(".clipTools #clipTitle").html(fieldDisplay.escapeString(data.notes));
		} else {
			plugin._videoWindow.find(".clipTools #clipTitle").html('');
		}

		$.when(
			plugin._drawTapeInfo(data)
		).done(function () {

			plugin._videoWindow.data({
				'tapeId': data.tape.id
			});
			plugin._setExpandableDescription();
			plugin._videoWindow.removeClass(function (index, css) {
				return (css.match(/(^|\s)aspect\d+/g) || []).join(' ');
			});
			if (data.tape.aspectRatio)
				plugin._videoWindow.addClass('aspect' + data.tape.aspectRatio * 10000);
			
			if (!data.clipId) {
				plugin._reelData.clip = null;
			} 
			plugin._videoWindow.find('.player').css('top','').data('setTop', false);
			plugin._setCurrentTab();

			$(".binSelect").val(Cookies.get('currentBinId'));
			plugin._updateShareURL();

			//if (data.showPlayer) {

			$.when(plugin._showVideo({
				path: data.path,
				startTimecode_str: data.tape.startTimecode,
				endTimecode_str: data.tape.endTimecode,
				fps: data.tape.fps,
				play: data.autoPlay,
				newInOffset: data.in,
				newOutOffset: data.out,
				receiveAudio: !data.disableAudio && (!data.tape.MOS || data.specialAccess),
				clipTrimming: data.isWritable,
				showFineTuningBar: data.tape.thumbnails ? data.tape.thumbnails.playerThumbnails : false,
				aspectRatio: data.tape.aspectRatio,
				showVideo: data.showPlayer
			})).done(function () {
				
				if (data.showPlayer) {
					plugin._videoWindow.removeClass('no-video');
				}
				
				
			}).fail(function () {
				
				//data.temporailyUnavailable = true;
				//data.tape.hasVideo = false;
				plugin._videoWindow.addClass('no-video');
				//plugin._videoWindow.addClass('.temporailyUnavailable');
				
			}).always(function() {
				plugin._log('finished _showVideo');
				plugin._loadingNewClip = false;
				console.log(plugin._videoWindow.width(), plugin._videoWindow.width() <= HF_GLOBALS.collapsedPlayerThreshold);
				if (plugin._videoWindow.width() <= HF_GLOBALS.collapsedPlayerThreshold) {
					plugin._videoWindow.find('.playerPlaceholder').css('padding-top', plugin._videoWindow.find('.accessDetails').height());
				} else {
					plugin._videoWindow.find('.playerPlaceholder').css('padding-top', '');
				}
				dfd.resolve();
				//TODO: figure out why I need this here!!! 
				//bins.populateBinSelect();
			});
		}).fail(function () {
			dfd.reject();
		});
		return dfd.promise();
	},

	_setExpandableDescription: function () {
		let plugin = this,
			div = plugin._videoWindow.find(".reelInfo div.description"),
			content = plugin._videoWindow.find(".reelInfo span.description .content"),
			width = content.innerWidth(),
			font = fieldDisplay.getCanvasFont(content[0]),
			lineHeight = content.length? parseInt(window.getComputedStyle(content[0]).lineHeight) : '';
		if (!content.length) {
			return;
		}
		plugin._videoWindow.find(".reelInfo div.description .more").remove();
		if (content.height() > 3 * lineHeight) {
			let descEl = plugin._videoWindow.find(".reelInfo div.description");
			descEl.append($('<span/>').addClass('more').text('More...').on({
				mouseenter: function() {
					descEl.addClass('hover');
					
				}}));
			descEl.addClass('expandable').on({
			
				mouseleave: function() {
				 	descEl.removeClass('hover');
				},
				touchstart: function() {
					
					if (descEl.hasClass('hover')) {
						descEl.removeClass('hover');
						
					} else {
						descEl.addClass('hover');
						$(document).one('touchstart', function() {
							descEl.removeClass('hover');
						})
					}
					//return false;
					
				}
			});
		} else {
			plugin._videoWindow.find(".reelInfo div.description .more").remove();
			plugin._videoWindow.find(".reelInfo div.description").removeClass('expandable').off();
		}
	},

	_showVideoPlayer: function (data) {
		//tape, inPoint, outPoint, title, clipId, autoPlay, isWritable, specialAccess, specialAccessExpiryDays, disableAudio, path) {

		let plugin = this,
			dfd = new $.Deferred();
		
		plugin._loadingNewClip = true;
		plugin._videoPopup = true;
		plugin._currentReelhasVideo = false;
		$('body').css('overflow', 'hidden');
		plugin._log(plugin._reelData);
		data = $.extend({
			tape: null,
			in : null,
			out: null,
			notes: '',
			clipId: null,
			autoPlay: false,
			isWritable: true,
			specialAccess: false,
			disableAudio: false,
			path: fieldDisplay.getVideoPath(data.tape),
			showPlayer: false,
			temporailyUnavailable: false
		}, data);

		data.showPlayer = (data.tape.hasVideo && !data.tape.specialtyCollection) || data.specialAccess;
		//plugin._videoWindow.css('visibility', 'hidden');
		//plugin.open();
		plugin._resizeObserver.observe(plugin._videoWindow[0]);
		if (data.showPlayer) {
			
			$.when(fieldDisplay.getVideoIsAvailable(data.path, !data.showPlayer)).done(function () {
				data.temporailyUnavailable = false;
				plugin._currentReelhasVideo = true;
				plugin._tapeJSON.temporailyUnavailable = false;
			}).fail(function () {
				data.temporailyUnavailable = true;
				plugin._tapeJSON.temporailyUnavailable = true;
				//data.tape.hasVideo = false;
				//data.specialAccess = false;
				data.showPlayer = false;
				plugin.unloadVideo();

			}).always(function() {
				$.when(plugin._drawPlayerContents(data)).done(function() {
					plugin._log('finished _drawPlayerContents');
					dfd.resolve();
				});
			});
		} else {
			plugin.unloadVideo();
			data.temporailyUnavailable = plugin._tapeJSON.temporailyUnavailable;
			$.when(plugin._drawPlayerContents(data)).done(function() {
				plugin._log('finished _drawPlayerContents');
				dfd.resolve();
			});
		}
		return dfd.promise();
	},

	_showVideoPlayerClip: function (clipTape) {

		var plugin = this,
			dfd = new $.Deferred(),
			path = fieldDisplay.getClipVideoPath(clipTape);

		plugin._videoWindow.find(".downloadClip").attr('id', 'downloadClip' + clipTape.id);
		plugin._videoWindow.find("label[for=playerSelectBin] span").text("Save a copy to...");
		var specialAccess = false;
		var diffDays = 0;
		if (clipTape.accessExpires) {
			var expiryDate = new Date(Date.parse(clipTape.accessExpires, "Y-m-d H:i:s"));
			var now = new Date();
			specialAccess = expiryDate > now;
			var timeDiff = (expiryDate.getTime() - now.getTime());
			diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
		}
		clipTape.tape.logOffset = clipTape.offset;
		plugin._log(clipTape);
		$.when(plugin._showVideoPlayer({
			tape: clipTape.tape,
			in : clipTape.offsetIn,
			out: clipTape.offsetOut,
			notes: clipTape.notes,
			clipId: clipTape.id,
			autoPlay: false,
			isWritable: clipTape.isWritable,
			specialAccess: specialAccess,
			accessExpiry: diffDays,
			disableAudio: !clipTape.includeAudio,
			temporailyUnavailable: clipTape.temporailyUnavailable,
			path: path,
			debug: HF_GLOBALS.debug
		})).always(function () {
			plugin._log('finished _showVideoPlayer');
			dfd.resolve();
		});
		return dfd.promise();
	},

	_handleChangedClip: function (callback) {
		var plugin = this;

		if (plugin._clipChanged) {
			plugin._clipChanged = false;
			// successHandler, cancelHandler, successBtnText, cancelBtnText, autoClose, closeHandler
			dialogs.showConfirm({
				message: "You have made changes to this clip. Would you like to save it?",
				successHandler: function () {

					$.when(plugin.options.saveClipChanges(plugin.getClipInfo())).done(function () {
						callback();
					});

				},
				cancelHandler: callback,
				successButtonText: "Save",
				cancelButtonText: "Continue without Saving"
			});
			return false;
		}
	},

	showNavigationControls: function (listInfo) {
		var plugin = this,
			navControls = plugin._videoWindow.find("#navigationControls");

		if (!listInfo || listInfo.numResults == null) {
			navControls.find('.navBtn').hide();
			return;
		}
		console.log(listInfo);
		navControls.show();
		navControls.find('.objName').text(listInfo.objName);
		navControls.find(".matchInfo").show().empty()
			.append($('<span/>').addClass('viewing').text('Viewing'))
			.append($('<span/>').addClass('noun').text(listInfo.itemName))
			.append($('<span/>').addClass('count').text(fieldDisplay.formatNumber(listInfo.index) + 
				(listInfo?.numResults ? ` of ${fieldDisplay.formatNumber(listInfo.numResults)}` : '')))
			.append(listInfo?.inlike ? $('<span/>').addClass('inlike').html(listInfo.inlike) : '')
			.append(!listInfo?.inlike && listInfo?.searchText ? $('<span/>').addClass('for').text('for') : '')
			.append(!listInfo?.inlike && listInfo?.searchText ? $('<span/>').addClass('searchText').text(listInfo.searchText) : '')
		//.html(listInfo.navDescription);
		navControls.find(".navBtn").show().removeClass('disabled').off().on('click', function () {
			var btn = $(this);
			if (btn.hasClass('disabled')) {
				return;
			}
			listInfo.step(btn.data('dir'), plugin._reelData.clip);
		});

		if (listInfo.index == listInfo.numResults) {
			navControls.find(".next").addClass('disabled');
		}

		if (listInfo.index == 1) {
			navControls.find(".prev").addClass('disabled');
		}
	},

	_displayClip: function (clipTape) {
		var plugin = this,
			numClips;

		plugin._videoWindow.removeClass('search').addClass('clip').data('clipid', clipTape.id);

		//TODO: REMOVE THIS!!!
		//$(".bin li").removeClass('selected');
		//$("li#clip" + clipTape.id).addClass('selected');

		return plugin._showVideoPlayerClip(clipTape).promise();
	},

	//displaying clips -- Public functions

	showReel: function (tape, reelData, listInfo) {
		var plugin = this,
			dfd = new $.Deferred();
		reelData.writable = true;
		plugin._reelData = $.extend({}, Object.assign({},plugin._emptyReelData), reelData);
		plugin._tapeJSON = tape;
		if (reelData.log) {
			plugin._drawTapeLog();
			plugin._reelData = $.extend(plugin._reelData, plugin._getOffsetsOfLogEntry(reelData.log));
		}
		console.log('show Reel', reelData, plugin._reelData);
		//plugin._videoWindow.waitingBox();
		$.when(plugin._showVideoPlayer({
			tape: tape,
			in : plugin._reelData.in,
			out: plugin._reelData.out,
			isWritable: true
		})).done(function () {
			plugin.showNavigationControls(listInfo);

			plugin._videoWindow.find('.mlt').data('tapeId', plugin._tapeJSON.tapeId);
			plugin._videoWindow.find('.reelMatches').data('tapeId', plugin._tapeJSON.tapeId);
			plugin.options.trackEvent("View", {
				collection: plugin._tapeJSON.specialtyCollection ? 'Specialty Collection' : 'Standard Collection',
				reelId: plugin._tapeJSON.tapeId
			});
			$.when(plugin.options.showContentCallback(plugin._reelData)).done(function () {
				if (plugin._reelData.in) {
					plugin._scrollToLogEntry(plugin._videoWindow.find('.tabPane.log .textLog .inPoint'));
				}
				dfd.resolve(plugin._reelData);
				plugin._videoWindow.trigger('stopWaiting');
			}).fail(function() {
				plugin._videoWindow.trigger('stopWaiting');
				
			});

		}).fail(function() {
			plugin._videoWindow.trigger('stopWaiting');
		});

		return dfd.promise();
	},

	showClip: function (clipInfo, listInfo) {
		var plugin = this,
			dfd = new $.Deferred().always(function () {
				plugin._loadingNewClip = false;

			});
		plugin._loadingNewClip = true;
		plugin.open(true);
		clipInfo = $.extend({
			clip: null,
			clipId: '',
			clipType: '',
			clipHashId: ''
		}, clipInfo);

		if (!clipInfo.clipId && !clipInfo.clipHashId && !clipInfo.clip) {
			dfd.reject();
		} else {
			//plugin._videoWindow.waitingBox();
			$.when(plugin.options.getClip(clipInfo))
				.done(function (clipData) {
					plugin._tapeJSON = clipData.tape;
					plugin._reelData = {
						reelId: clipData.tape.id,
						in : clipData.offsetIn,
						out: clipData.offsetOut,
						writable: clipData.isWritable,
						clip: clipData
					};
					$.when(plugin._displayClip(clipData)).done(function () {
						plugin._log('finished _displayClip');
						plugin.showNavigationControls(listInfo);
						plugin.options.trackEvent("View", {
							collection: plugin._tapeJSON.specialtyCollection ? 'Specialty Collection' : 'Standard Collection',
							reelId: plugin._tapeJSON.tapeId
						});
						$.when(plugin.options.showContentCallback(plugin._reelData)).done(function () {
							plugin._scrollToLogEntry(plugin._videoWindow.find('.tabPane.log .textLog .inPoint'));
							plugin._videoWindow.trigger('stopWaiting');
							dfd.resolve(plugin.getClipInfo());
						}).fail(function() {
							plugin._videoWindow.trigger('stopWaiting');
							plugin.close();
						});
					});

				}).fail(function () {
					dfd.reject();
					plugin._videoWindow.trigger('stopWaiting');
					plugin.close();
				});
		}
		return dfd.promise();
	},

	showProDownload: function (tape, reelData) {
		var plugin = this;
		// $("#navigationControls .navBtn").hide();
		//$("#navigationControls .matchInfo").hide();
		plugin.showReel(tape, reelData, null);
	},

	close: function () {
		var plugin = this,
			dfd = new $.Deferred();
		if (!plugin._videoWindow.is(':visible')) {
			return;
		}

		function helper() {
			plugin._resizeObserver.unobserve(plugin._videoWindow[0]);
			plugin._videoWindow.find('.eip_cancelButton').trigger('click');
			plugin.pauseVideo();

			if (plugin._clipChanged) {
				plugin._handleChangedClip(function () {
					plugin.close();
				});
				return;
			}
			plugin.unloadVideo();

			plugin._reelData = null;
			plugin._tapeJSON = null;
			plugin._playerTimecodeOffset = null;

			$('body').css('overflow', 'auto');
			plugin._videoWindow
				.removeAttr('style')
				.data('tapeId', '')
				.hide();
			
			$("li.clipDetails, li.clip, #resultsList .displayed").removeClass('displayed');
			plugin._videoWindow.find(".save").hide();
			return plugin.options.closeCallback();

		}

		if (plugin._player.videoPlayer('videoIsLoaded')) {
			$.when(plugin._player.videoPlayer('getCurrentTime')).done(function (time) {
				plugin.storePlayerPosition(plugin._videoWindow.data('tapeId'), time);
				$.when(helper(), function () {
					dfd.resolve();
				});
			});
		} else {
			$.when(helper(), function () {
				dfd.resolve();
			});
		}
		return dfd.promise();
	},

	open: function(startWaiting) {
		console.log('open video player');
		let plugin = this;
		plugin._videoWindow.show();
		if (startWaiting)
			plugin._videoWindow.waitingBox();
		plugin.options.openWindowCallback();
	},

	//initialization

	_initTabs: function () {
		var plugin = this;

		plugin._videoWindow.find(".tabs a.tab").on('click', function () {
			var tabEl = $(this),
				tab = tabEl.data('tab'),
				onVideoTab = tabEl.closest('.info').hasClass('video');

			plugin._videoWindow.removeClass('details thumbnails log video transcript').addClass(tab);
			tabEl.closest('.info').removeClass('details thumbnails log video transcript').addClass(
				tab);

			switch (tab) {
			case 'log':
				if (onVideoTab) {
					plugin.pauseVideo();
				}
				if (plugin._videoWindow.find("#lockLog").is(":checked") || onVideoTab) {
					$.when(plugin._player.videoPlayer('getCurrentTime')).done(function (time) {
						plugin._scrollToNearestLogEntry(time, true);
					});
				}
				break;
			}

		});

		plugin._initThumbnailsTab();
		plugin._initLogTab();
	},

	_initThumbnailsTab: function () {
		var plugin = this;
		plugin._videoWindow.on({
			'click': function () {
				var num = $(this).data('index');
				var time = num * 60;
				plugin.setVideoTime(time);
				if ($(this).data('touch')) {
					setTimeout(function () {
						$('.thumbBtn').removeClass('hover');
					}, 50);
				}
				$(this).data('touch', '');
				return false;
			},
			'touchstart': function () {
				$(this).data('touch', true);
			},
			'mouseover touchstart': function () {
				$(this).addClass('hover');

			},
			'mouseout touchend': function () {
				$(this).removeClass('hover');
			}
		}, '.thumbBtn');
	},

	_initLogTab: function () {
		var plugin = this,
			touchTimeout = null;
		plugin._videoWindow.on({
			mouseover: function (event) {
				if ($(this).closest('div.textLog').data('touchStarted') == 'true') {
					return;
				}
				plugin._showClipMarkers($(this));

			},

			touchstart: function (e) {
				console.log('touchStarted');
				if (!$(e.target).closest('.markerContainer').length && !$(e.target).closest('#jumpToPoint').length) {
					console.log($(e.target).closest('.markerContainer'), $(e.target).closest('#jumpToPoint'))
					plugin._videoWindow.find(".markerContainer").hide();
					plugin._videoWindow.find('#jumpToPoint').hide();
				}
				e.stopPropagation();
				clearTimeout(touchTimeout);
				var $logEntry = $(this);
				$logEntry.closest('div.textLog').data('touchStarted', 'true');
				


				$('#jumpToPoint').hide();

				$logEntry.on('click.afterTouch', function (event) {

					$logEntry.off('click.afterTouch');
					var top = $logEntry.position().top + $('.tabPane.log div.textLog').position().top -
						$('.tabPane.log div.textLog').scrollTop();

					$('#jumpToPoint')
						.css('top', top + 'px')
						.show()
						.off('touchstart')
						.on('touchstart', function (e) {
							e.stopPropagation();
							var offset = $("td.timecode .offset", $logEntry).text();
							plugin.setVideoTime(offset);
							$logEntry.closest('div.textLog').data('touchStarted', 'false');
							console.log('hide markers: jump to point');
							plugin._videoWindow.find(".markerContainer").hide();
							plugin._videoWindow.find('#jumpToPoint').hide();
							
							return false;
						});
					plugin._showClipMarkers($logEntry);
					// $(document).one('touchstart', function (e) {

					// 	if ($(e.target).closest('.textLog tr').length) {
					// 		return;
					// 	}
					// 	$logEntry.closest('div.textLog').data('touchStarted', 'false');
					// 	console.log('hide markers: document touch');
					// 	// plugin._videoWindow.find(".markerContainer").hide();
					// 	// plugin._videoWindow.find('#jumpToPoint').hide();
					// });
					return false;
				});

				touchTimeout = setTimeout(function () {
					$logEntry.off('click.afterTouch');

				}, 1000);
			},
			touchend: function (e) {

			},
			click: function (event) {
				if ($(this).closest('div.textLog').data('touchStarted') == 'true') {
					return;
				}
				event.stopPropagation();
				var offset = $("td.timecode .offset", $(this)).text();
				plugin.setVideoTime(offset);
				return false;
			}
		}, '.textLog tr');

		plugin._videoWindow.on({
			mouseleave: function (e) {

				if (!$(e.relatedTarget).closest('.markerContainer').length) {
					plugin._videoWindow.find(".markerContainer").hide();
				}
			}
		}, '.tabPane.log div.textLog');

		plugin._videoWindow.find(".markerContainer").mouseleave(function (e) {
			if (!$(e.relatedTarget).is(".tabPanel .log tr") && !
				$(e.relatedTarget).hasClass("logMarker")) {
				plugin._videoWindow.find(".markerContainer").hide();

			}
		});

		plugin._videoWindow.on({
			mouseenter: function () {
				$(this).addClass('active');
			},
			mouseleave: function () {
				$(this).removeClass('active');
			}
		}, '.log');

		plugin._videoWindow.on('change', '#lockLog', function () {
			if (!$(this).is(':checked')) {
				plugin._clearLogHighlighting();
				Cookies.set('lockLog', 'false', {
					path: HF_GLOBALS.COOKIE_PATH
				});
				return;
			}
			$.when(plugin._player.videoPlayer('getCurrentTime')).done(function (time) {
				plugin._scrollToNearestLogEntry(time, true);
			});
			Cookies.set('lockLog', 'true', {
				path: HF_GLOBALS.COOKIE_PATH
			});
		});
	},

	_initPlayer: function () {
		var plugin = this;
		plugin._player.videoPlayer({
			volume: Cookies.get('playerVolume') ?? HF_GLOBALS.DEFAULT_PLAYER_VOLUME,

			useVideoElements: function () {
				return true;
			},
			getTimecode: function (startOffset, offset, fps, ignoreFrames) {
				if (ignoreFrames === undefined) {
					ignoreFrames = false;
				}
				return timecodeFunctions.positionToTimecode(startOffset + offset, fps);

			},
			getOffset: function (startOffset, timecodeStr, fps) {
				//ignore the frame rate -- get a full second offset.
				//these timecodes don't have a frame compontent, so match the in one
				var startFrames = Math.floor((startOffset - Math.floor(startOffset)) * fps);
				timecodeStr = timecodeStr + ':' + startFrames;
				var tc = new Timecode(timecodeStr, fps);

				return tc.toPosition() - startOffset;
			},
			flashObj: {},
			browserSupport: {
				volumeControl: Modernizr.volume,
				fullscreen: Modernizr.fullscreen /*&& !isAndroid() && !isIOS()*/ ,
				numberInput: Modernizr.inputtypes.number,
				touch: Modernizr.touch
			},
			thumbnailPreview: true,
			updateThumbnail: function (container, pos) {
				return plugin._updateThumbnail(container, pos);
			},
			showThumbnailOnPause: (navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(
				/iPod/i)),
			videoBasePath: HF_GLOBALS.ABSOLUTE_URL,
			debug: false,
			aspectRatio: 4 / 3,
			getCurrentLogEntry: function (offset) {
				return plugin._getCurrentLogEntry(offset);
			},
			showLog: function () {
				plugin._videoWindow.find(".tabs .log").trigger('click');
			},
			clipSelectionContainer: plugin._videoWindow.find('.clipTools')
		});

		plugin._player.on({
			'videoplayersetin': function (event, data) {
				plugin._videoPlayerSetIn(event, data);
			},
			'videoplayersetout': function (event, data) {
				plugin._videoPlayerSetOut(event, data);
			},
			'videoplayersetvolume': function (event, data) {
				plugin._videoPlayerSetVolume(event, data);
			},
			'videoplayertimeupdate': function (event, data) {
				plugin._videoPlayerTimeUpdate(event, data);
			},
			'videoplayerstartloading': function () {

				if (!plugin._playerSpinner) {

					plugin._playerSpinner = new Spinner(HF_GLOBALS.playerSpinnerOpts).spin(
						plugin._player.find('.player-widget .spinnerContainer')[0]
					);
				}
			},
			'videoplayerstoploading': function () {

				if (plugin._playerSpinner) {
					plugin._playerSpinner.stop();
					plugin._playerSpinner = null;
				}
			}
		});
	},

	_initControls: function () {
		var plugin = this;

		plugin._videoWindow.find(".closeContainer").on({

			click: function (e) {
				e.preventDefault();
				e.stopPropagation();
				setTimeout(function () {
					plugin.close();
				}, 0);
				return false;
			}
		});

		plugin._videoWindow.find('.save').on({
			click: function () {
				var button = $(this);
				$.when(plugin.options.saveClipChanges(plugin.getClipInfo())).done(function (data) {
					plugin._reelData.clip = data;
					plugin._clipChanged = false;
					button.hide();
				});

			}
		});

		plugin._videoWindow.find('.saveToBin .binSelect').change(function () {
			$(this).closest('.saveToBin').trigger('click');

		});
		plugin._videoWindow.find('.saveToBin').on({
			click: function (e) {
				var el = $(this);
				if ($(e.target).closest('select').length !== 0) {
					return;
				}

				plugin._clipChanged = false;

				$.when(plugin.options.addNewClipToBin({
					tapeId: plugin._tapeJSON.id,
					offsetIn: plugin._reelData.in,
					offsetOut: plugin._reelData.out,
					comment: plugin._videoWindow.find("#clipTitle .empty").length ? '' : plugin._videoWindow
						.find("#clipTitle").text(),
					binId: el.children('.binSelect:visible').length ?
						el.children('.binSelect:visible').val() : 0
				})).done(function(data) {
					
				});
			}
		});

		plugin._videoWindow.find('.downloadClip').click(function () {
			let button = $(this),
				clipInfo = plugin.getClipInfo();
			if (button.hasClass('disabled') || button.hasClass('activeDownload')) {
				return;
			}
			
			button.addClass('activeDownload');
			import('./videoOutput.js').then((module) => {
	            module.videoOutput.downloadClip(clipInfo);
	        });
		});

		plugin._initShareDialog(plugin._shareDialog);

	},

	_initResizeObserver: function() {
		let plugin = this;
		plugin._resizeObserver = new ResizeObserver((entries) => {
			
			plugin._setExpandableDescription();
			plugin._setCurrentTab();
			if (entries[0].contentBoxSize[0].inlineSize <= 960) {
				plugin._videoWindow.find('.playerPlaceholder').css('padding-top', plugin._videoWindow.find('.accessDetails').height());
			} else {
				plugin._videoWindow.find('.playerPlaceholder').css('padding-top', '');
			}

		});
	},

	_create: function () {
		let plugin = this;
		this._videoWindow = this.element;
		this._shareDialog = this._videoWindow.find('#shareDialog');
		this._player = this._videoWindow.find('.player');
		this._initTabs();
		this._initPlayer();
		this._initControls();
		this._initResizeObserver();
		this.options.initialized();
	},

	// Destroy an instantiated plugin and clean up
	// modifications the widget has made to the DOM
	_destroy: function () {},

	// Respond to any changes the user makes to the
	// option method
	_setOption: function (key, value) {
		//store the option
		this._super("_setOption", key, value);
	}

});

}};
