
import './jquery.waitingBox.js';
import {hfCommon} from './common.js';
import {fieldDisplay} from './fieldDisplay.js';
import {commonSearch} from './commonSearch.js';
import {dialogs} from './dialogs.js';
import {contactForm} from './contactForm.js';
import {binPage} from '../binPage/binPageFunctions.js';
var keycode = require('keycode');
const Cookies = require('js-cookie');


//import Sortable from 'sortablejs';
//require("jquery-ui/ui/widgets/droppable");
//require("jquery-ui/ui/widgets/sortable");
//require("jquery-ui/ui/widgets/accordion");



var binListJson = {},
	numBins = 0,
	currentBinId = 0,
	binSelect = null,
	binMenu = null,
	binMenuList = null,
	activationDfd = null,
	ready = false;

//misc 

function getBinUrl(bin) {
	return `bins/${bin.hashId}`;
}

function getCSVUrl(bin, log) {
		log = log === undefined ? false : log;
		let url ='';
		if ( bin.binType == 'ShareBin') {
			url = `share/${bin.hashId}`;
		} else if (bin.binType == 'Order') {
			url = `orders/${bin.id}`;
		} else {
			url = `bins/${bin.hashId}`;
		}
		if (log) {
			url += '_log';
		}
		url += '.csv';
		return url;
	}

function getClipUrl(clip, bin, absolute) {
	absolute = absolute || false;
	bin  = bin || null;

	let url = (absolute ? HF_GLOBALS.absoluteURL_SSL : '');

	if (clip.hashId) {
		if (bin) {
			return url + `bins/${bin.hashId}?clip=${clip.hashId}`;
		}
		return url + `clips/${clip.hashId}`;
	} 
	 
	let title = clip.title ? clip.title : clip.notes;
	url += `tapes/${clip.reelId ? clip.reelId : clip.libraryId}`;
	
	if (clip.in) {
		url += `_${clip.in}`;
	} else if (clip.offsetIn) {
		url += `_${clip.offsetIn}`;
	}

	if (clip.out || clip.offsetOut) {
		if (!clip.in && !clip.offsetIn) {
			url += '_0';
		}
		if (clip.out) {
			url += `_${clip.out}`;
		} else {
			url += `_${clip.offsetOut}`;
		}
	}

	if (title) {
		title = encodeURIComponent(title).replace(/%20/g, "+");
		let	truncated = encodeURIComponent('... (truncated)').replace(/%20/g, "+"),
			maxLength = HF_GLOBALS.maxURLLength - truncated.length + 1;
		if (title.length > maxLength) {
			let curLength = title.length;
			while (curLength > 1) {
				if (title[curLength - 1] == '+') {
					curLength--;
					break;
				}
				curLength--;
			}
			if (curLength) {
				url += '_' + title.substr(0, curLength) + truncated;
			}
		} else {
			url += '_' + title;
		}
	}
	return url;
}

function setBinCookie(binId) {
	Cookies.set('currentBinId', binId, {
		path: HF_GLOBALS.COOKIE_PATH
	});
}

function setCurrentBin(binId) {
	var binHeader = $(".binHeader" + binId);

	setBinCookie(binId);
	currentBinId = binId;
	binSelect.val(binId);
	binMenu.children('.header').html(binHeader.html()).show();
	$('.showBins').html($('<a>').attr('href', getCurrentBinUrl()).html(binHeader.html()));
	if (!binHeader.hasClass('ui-state-active')) {
		binHeader.trigger('setActive');
	}

}

function getCurrentBinUrl() {
	let bin = binListJson && binListJson.hasOwnProperty('bins') && binListJson.bins.hasOwnProperty(
			`bin${currentBinId}`) ?
		binListJson.bins[`bin${currentBinId}`] : null;
	if (bin) {
		return getBinUrl(bin);
	} else {
		return '';
	}
}

//ajax calls

function getBins() {
	
	console.log('get bins');
	if (HF_AUTH.isLoggedIn && HF_AUTH.showAdoptionPrompt) {
			console.log('showAdoptionPromptDialog');
		return showAdoptionPromptDialog();
	}

	var dfd = new $.Deferred(),
		container= binMenu.is(':visible') ? binMenu : $('.showBins').parent();
	
	$.ajax({
		
		data: {
			action: 'getBinlist'
		},
		dataType: 'json'
	}).done(function (data) {
		binListJson = data;
		$.when(displayBinList()).done(function () {
			ready = true;
			dfd.resolve();
		}).fail(function() {
			console.log('displayBinList failed');
			dfd.reject();
		});

	}).fail(function() {
		console.log('getbinList ajax call failed');
		dfd.reject();
	})
	// .always(function() {
	// 	container.trigger('stopWaiting');
	// });
	return dfd.promise();
}

function getBinContents(binId) {
	if (!binId) {
		return;
	}
	return $.ajax({
		container: binMenu.is(':visible') ? binMenu : $('.showBins').parent(),
		data: {
			action: 'getBinMenu',
			binId: binId
		},
		dataType: 'json'
	});

}

function addExistingClipToBin(data) {
	$.ajax({
		global: false,
		data: {
			action: 'binaddClip',
			binId: !data.binId ? '' : data.binId,
			clipId: data.clipId,
			index: data.index,
			binName: data.binName,
			fromShareBin: data.shareBin,
			fromOrder: data.isOrder,
			menu: (binPage.isBinPage() || binPage?.displayBinId != data.binId) ?
				1 : 0
		},
		dataType: 'json'
			// complete: function(data) {
			// 	$('#layoutContainer').trigger('stopWaiting');
			// },
			// success: function(data) {
			// 	var binInfo = $.parseJSON(data);
			// 	dfd.resolve();
			// 	handleClipCreated(binInfo, binName !== undefined, {
			// 		keepOpen: true
			// 	});
			// },
			// error: function(jqXHR, textStatus, thrownError) {
			// 	dfd.resolve();
			// }
	});
}

function addNewClipToBin(data) {
	return $.ajax({
		data: {
			action: 'binaddNewclip',
			binId: !data.binId ? '' : data.binId,
			libraryId: data.tapeId,
			offsetIn: data.offsetIn,
			offsetOut: data.offsetOut,
			comment: data.comment,
			index: data.index,
			binName: data.binName,
			currentClipId: data.sourceClipId,
			currentClipType: data.sourceClipType,
			menu: (binPage.isBinPage() || binPage?.displayBinId != data.binId) ?
				1 : 0
		},
		dataType: 'json'
	});
}

function addLogToBin(data) {
	return $.ajax({
		data: {
			action: 'binaddLog',
			binId: !data.binId ? '' : data.binId,
			logId: data.logId,
			index: data.index,
			binName: data.binName,
			menu: (binPage.isBinPage() || binPage?.displayBinId != data.binId) ?
				1 : 0
		},
		dataType: 'json'
	});
}

function addClipToBinAjax(data) {
	//tapeId, offsetIn, offsetOut, comment, binId, index, binName, sourceClipId, sourceClipType

	data = $.extend({
		tapeId: '',
		offsetIn: 0,
		offsetOut: -1,
		comment: '',
		clipId: 0,
		logId: 0,
		binName: '',
		sourceClipId: '',
		sourceClipType: ''
	}, data);

	if (data.clipId) {
		return addExistingClipToBin(data);
	} else if (data.logId) {
		return addLogToBin(data);
	} else {
		return addNewClipToBin(data);
	}

}

function createBin(binName, options) {

	options = $.extend({
		redirect: false,
		highlightBin: false,
		tempBin: false
	}, options);

	return $.ajax({
		data: {
			'action': 'createBin',
			'title': binName,
			'tempBin': options.tempBin
		},
		dataType: 'json'
	}).done(function (data) {
		binListJson = data;
		setCurrentBin(binListJson.bin.id);
		if (options.redirect) {
			location.replace(getBinUrl(binListJson.bin));
		} else {
			displayBinList(false /*create bin */ );

			if (options.highlightBin) {
				$('.showBins').trigger('click');
				$('.binList').scrollTop(0);
				hfCommon.blink($('#binContainer' + binListJson.bin.id), hfCommon.hideMenu);
			}
		}
	});
}

function deleteClip(clipId) {

	return $.ajax({
		data: {
			action: 'deleteClip',
			clipId: clipId,
			menu: (binPage.isBinPage() || currentBinId !=
				binPage?.displayBinId) ? 1 : 0
		},
		dataType: 'json'
	}).done(function (binInfo) {
		if (binInfo.id == currentBinId) {
			displayBinContents(binInfo);
		}
	});
}

function sortClips(binId, clipId, newPos) {
	var dfd = new $.Deferred();
	$.ajax({
		data: {
			'action': 'sortClips',
			'binId': binId,
			'clipId': clipId,
			'newPos': newPos
		},
		dataType: 'json'
	}).done(function (binInfo) {
		//setCurrentBin(binInfo.id);
		if (currentBinId == binId) {
			displayBinContents(binInfo);
		}
		dfd.resolve(binInfo);

	});
	return dfd.promise();
}

function moveClipToBin(binId, oldBinId, clipId, binName, index) {
	var dfd = new $.Deferred();
	if (!clipId) {
		return;
	}

	$.ajax({
		data: {
			action: 'moveClip',
			newBinId: binId,
			clipId: clipId,
			binName: binName,
			//fromShareBin: shareBin,
			//fromOrder: isOrder,
			index: index,
			menu: (binPage.isBinPage() || binPage?.displayBinId != binId) ? 1 : 0
		},
		dataType: 'json',
		success: function (binInfo) {
			$('#binContainer' + binInfo.oldBin.id).replaceWith(getBinMenuItem(binInfo.oldBin));
			if (typeof (binPage) !== 'undefined' && binInfo.oldBin.id == binPage?.displayBinId) {
				binPage.displayBinDetails(binInfo.oldBin);
			}
			$(`.binContent${oldBinId}`).data('numClips', $(`.binContent${oldBinId}`).data('numClips') -1 );
			$(`.binHeader${oldBinId} .count`).text($(`.binContent${oldBinId}`).data('numClips'));
			if (binMenuList.hasClass('ui-accordion')) {
				binMenuList.accordion('refresh');
			}
			handleClipCreated(binInfo, binName !== undefined && binName !== null, {
				keepOpen: true,
				action: 'move'
			});
			dfd.resolve();

		},
		error: function () {
			dfd.resolve();
		}
	});
	return dfd.promise();
}

function saveClipChanges(clipData) {
	var dfd = new $.Deferred();
	$.ajax({
		data: {
			action: 'saveClip',
			offsetIn: clipData.in,
			offsetOut: clipData.out,
			comment: clipData.comment,
			id: clipData.clip ? clipData.clip.id : ''
		},
		dataType: 'json'
	}).done(function (obj) {
		if ($('.clip' + obj.id).length) {
			displayMenuClip(binMenuList.find('.clip' + obj.id), obj);
		}
		if (typeof (binPage) !== 'undefined') {
			if ($("#binContents #clipDetails" + obj.id).length) {
				binPage.getClipDetails(obj, $("#binContents #clipDetails" + obj.id +
					' .clipDetailsContents'), obj.isWritable);
			}
			if (typeof (binPage?.updateModifiedDate) !== 'undefined' && obj.binId == binPage?.displayBinId) {
				binPage.updateModifiedDate(obj);
			}
		}
		dfd.resolve(obj);
	});
	return dfd.promise();
}

function getClip(data) {
	if (data.clip) {
		return data.clip;
	} else {
		return $.ajax({
			dataType: 'json',
			data: {
				action: 'getClip',
				clipId: data.clipId,
				clipHashId: data.clipHashId,
				clipType: data.clipType
			}
		});
	}
}

function addClipsToBinHelper(data, binId, binName) {
	var clipIds = [],
		clipType = 'Clip';
	$.each(data.clips, function (index, clip) {
		trackEvent("Create Clip", {
			collection: clip.specialty ? 'Specialty Collection' : 'Standard Collection',
			reelId: clip.reelId
		});
		clipIds.push(clip.id);
		clipType = clip.clipType;
	});
	return $.ajax({
		data: {
			action: 'binaddClips',
			clips: clipIds.join(),
			binId: (!binId ? '' : binId),
			binName: binName,
			clipType: clipType ?? 'Clip',
			//fromOrder: data.isOrder,
			menu: (binPage.isBinPage() ||
				binPage?.displayBinId != binId) ? 1 : 0
		},
		dataType: 'json'
	}).done(function (binInfo) {
		handleClipCreated(binInfo, !binId);
	});
}

//dialogs

function createSelectBinDialog() {
	let dialog = $('<div/>').addClass('selectBinDialog singleInput')
		.attr('title', 'Create New Bin')
		.append($('<p/>').addClass('extraCopy'))
		.append($('<p/>').text('Select the bin you\'d like to add the clip to:'))
		.append($('<select/>').addClass('binSelect'))
		.append($('<div/>').addClass('error'));
	$('body').append(dialog);
	return dialog;
}

function createAddBinDialog() {
	let dialog = $('<div/>').addClass('newBinDialog singleInput')
		.attr('title', 'Create New Bin')
		.append($('<p/>').addClass('extraCopy'))
		.append($('<p/>').text('Enter a name for the new bin:'))
		.append($('<h3/>').text('Name:'))
		.append($('<input type="text"/>').addClass('dialogSingleInput txtNewBinName'))
		.append($('<div/>').addClass('error'));
	$('body').append(dialog);
	return dialog;
}

function showAddBinDialog(callback, extraCopy) {

	if (!hfCommon.cookiesEnabled()) {
		hfCommon.showCookiesAlert();
		return;
	}
	if (extraCopy === undefined) {
		extraCopy = '';
	}
	var dfd = new $.Deferred(),
		dialog = createAddBinDialog();
	
	dialog.find('.extraCopy').text(extraCopy);
	dialogs.importDialogWidget().then(() => {
		dialog.dialog({
			autoOpen: false,
			modal: true,
			width: 350,
			resizable: false,
			open: function (event, ui) {
				var textbox = dialog.find('.txtNewBinName');
				$(".ui-draggable", $(".bin .listBox")).remove();
				if (!textbox.val()) {
					textbox.val('Bin ' + (numBins + 1));
				}
				textbox.select().focus();
			},
			close: function () {
				dialog.remove();
			},
			buttons: {
				"Create New Bin": function () {
					var textbox = dialog.find('.txtNewBinName');
					if (!textbox.val()) {
						$(".error", $(this)).text("You must enter a name.");
						return;
					}
					$.when(callback(textbox.val())).done(function (data) {
						dfd.resolve(data);
					});
					$(this).dialog('close');
				},
				"Cancel": function () {
					$(this).dialog('close');
					dfd.resolve(null);
				}
			}
		});
		dialogs.openDialog(dialog);
	});
	return dfd.promise();
}

function showSelectBinDialog(callback) {
	if (numBins === 0) {
		showAddBinDialog(function (binName) {
				callback(null, binName);
			},
			"First, you'll need to create a bin to put your clip into.  You can create as many bins as you'd like, so it may be helpful to give your first bin a name."
		);
		return;
	}
	var dialog = createSelectBinDialog();
	populateBinSelect();
	dialogs.importDialogWidget().then(() => {
		dialog.dialog({
			autoOpen: false,
			modal: true,
			width: 350,
			resizable: false,
			open: function (event, ui) {
				$(this).find('.binSelect').val(currentBinId);
			},
			close: function () {
				dialog.remove();
			},
			buttons: {
				"Add Clip": function () {

					var selectbox = dialog.find('select'),
						binId = selectbox.val();
					if (binId == -1 || !binId) {
						dialog.dialog('close');
						showAddBinDialog(function (binName) {
							callback(null, binName);
						});
					} else {
						dialog.closest('.ui-dialog').find('ui-dialog-buttonpane ui-button')
							.prop('disabled', true)
							.addClass('ui-state-disabled');
						dialog.closest('.ui-dialog').waitingBox({
							autoStart: true
						});
						$.when(callback(binId)).done(function () {

							dialog.closest('.ui-dialog').trigger('stopWaiting')
								.find('ui-dialog-buttonpane ui-button').prop('disabled', false).removeClass(
									'ui-state-disabled');
							dialog.dialog('close');
						});
					}

				},
				"Cancel": function () {
					dialog.dialog('close');
				}
			}
		});
		dialogs.openDialog(dialog);
	});
}

function moveClipToNewBin(oldBinId, clipId) {
	if (!clipId) {
		return;
	}
	showAddBinDialog(function (newName) {
		return moveClipToBin(null, oldBinId, clipId, newName);
	});
}

function showAdoptionPromptDialog() {
	var dfd = new $.Deferred();

	dialogs.showConfirm({
		message: 'You have bins that were created prior to logging in.  Would you like to import them to your account?',
		successButtonText: 'Yes, import them',
		successHandler: function () {
			$.ajax({
				data: {
					action: 'adoptCookiebins'
				},
				dataType: 'json',
				success: function (binList) {
					binListJson = binList;
					displayBinList();
					dfd.resolve();
				}

			});
		},
		cancelHandler: function () {
			$.ajax({
				data: {
					action: 'clearCookiebins'
				},
				dataType: 'json',
				success: function (binList) {
					binListJson = binList;
					Cookies.set('cookieBins', null, {
						path: HF_GLOBALS.COOKIE_PATH
					});

					if (typeof (binPage) !== 'undefined' && binPage?.displayBinId) {
						binPage.getBinDetailsById(binPage.displayBinId);
					}

					displayBinList();
					dfd.resolve();
				}

			});
		},
		cancelButtonText: 'No, discard them',
		title: 'Import Existing Bins',
		dialogClass: 'no-close'
	});
	return dfd.promise();
}

//select boxes

function populateBinSelect() {
	binSelect = $('.binSelect');
	binSelect.empty()
		.append(
			$("<option/>")
			.addClass('action newBinOption')
			.text("...a new bin")
			.attr('value', '-1')
		).append(function () {
			let options = [];
			$.each(binListJson.bins, function (name, value) {
				options.push($("<option/>")
					.addClass(value.id == currentBinId ? 'currentBin' : '')
					.text(
						`${value.title}${binPage.isBinPage && binPage?.displayBinId == value.id ? ' (this bin)' : ''}`
					)
					.attr('value', value.id)
					.prop('selected', value.id == currentBinId)
				);
			});
			return options;
		});
}

function getListInfo(clipId, bin,index) {
	bin = bin || null;
	if (!bin) {
		let clip = $(`.clip${clipId}`);
		if (clip.length) {
	 		index = clip.prevAll().length;
			bin = clip.closest('.binContent .listBox').data('bin');
		} else {
			return null;
		}

	}
	return {
		index: index+1,
		numResults: bin ? bin.numClips : 0,
		objName: 'clip',
		inlike: bin ? `in ${bin.title}` : '',
		//navDescription: `<span class="viewing">Viewing </span><span class="verb">clip </span>` +
		//`<span class="count">${fieldDisplay.formatNumber(index +1)} of ${fieldDisplay.formatNumber(bin.numClips)} in ${bin.title}</span>`,
		step: function (dir, clip) {
			var newIndex = index + (dir == 'next' ? 1 : -1);
			if (newIndex >= 0 && newIndex < bin.numClips) {

				showMenuClipHelper(bin, newIndex, bin.clipIds[newIndex]);

			}
		}
	};
}

//bin menu
function showMenuClipHelper(bin, index, clipId) {
	var dfd = new $.Deferred();
	$('#videoWindow').videoWindow('open', true);
	$.when($("#videoWindow").videoWindow('showClip', {
		clipId: clipId,
		isWritable: bin.isWritable,
		clipType: ''
	}, bins.getListInfo(clipId, bin, index))).done(function (clipData) {
		$.when(function () {
			if (typeof (commonSearch) !== 'undefined') {
				return commonSearch.appendExtraReelData(clipData);
			} else {
				return true;
			}
		}()).always(function () {
			$("#layoutContainer").data('keepWaiting', '').trigger('stopWaiting');
			dfd.resolve();
		});
	});
	return dfd.promise();
}

function showMenuClip(li) {
	var menuList = li.closest('.listBox'),
		index = li.prevAll().length,
		clipId = li.data('id');
	return showMenuClipHelper(menuList.data('bin'), index, clipId);
}

function blinkClips(binInfo, prefix, callback) {
	if (binInfo.newClip) {
		hfCommon.blink($("." + prefix + binInfo.newClip.id), callback);
		
	} else if (binInfo.newClips) {
		var selector = '';
		for (var i = 0; i < binInfo.newClips.length; i++) {
			var clip = binInfo.newClips[i];
			selector += (i === 0 ? '' : ', ') + '.' + prefix + clip.id;

			// if (options.action == 'create') {
			// 	//track with analytics
			// 	trackEvent(
			// 		"Create Clip", {
			// 			collection: clip.specialtyCollection ? 'Specialty Collection' : 'Standard Collection',
			// 			reelId: clip.tapeId
			// 		});
			// }
		}
		hfCommon.blink($(selector), callback);
	}
}

function handleClipCreated(binInfo, isNewBin, options) {

	options = $.extend({
		keepOpen: false,
		action: 'create'
	}, options);

	//a new bin was created, update the binList
	if (isNewBin) {
		//getBinList();
		var newBinMenuItem = getBinMenuItem(binInfo.bin);
		binMenuList.prepend(newBinMenuItem)
		if (binMenuList.hasClass('ui-accordion')) {
			binMenuList.accordion('refresh');
		}
		

		binSelect.append($("<option/>")
			.text(binInfo.bin.title)
			.attr('value', binInfo.bin.id)
		);
		binListJson.bins[`bin${binInfo.bin.id}`] = binInfo.bin;
	}
	if (options.action == 'create') {
		if (binInfo.newClip) {
			trackEvent(
				"Create Clip", {
					collection: binInfo.newClip.specialtyCollection ? 'Specialty Collection' : 'Standard Collection',
					reelId: binInfo.newClip.tapeId
				});
		} else {
			binInfo.newClips.forEach((newClip) => {
				trackEvent(
					"Create Clip", {
						collection: newClip.specialtyCollection ? 'Specialty Collection' : 'Standard Collection',
						reelId: newClip.tapeId
					});
			});
		}
	}

	//$("#videoWindow").videoWindow('close');
	displayBinContents(binInfo.bin);
	setCurrentBin(binInfo.bin.id);

	if (typeof(binPage) != 'undefined' && binPage?.displayBinId && currentBinId == binPage.displayBinId) {
		binPage.displayBinDetails(binInfo.bin);
		$.when(binPage.initializeBinClips(0)).done(function () {
			blinkClips(binInfo, 'clipDetails', function () {});
		});
	}

	if (!$('html').hasClass('binSidebarOpen')) {
		$('.binMenu').one('transitionend', function () {
			$.when(expandSidebarBin()).done(function () {
				$('.binList')
					.scrollTop(0)
					.one('mousedown.stopClose touchstart.stopClose', function () {
						options.keepOpen = true;
						//return false;
					});
				blinkClips(binInfo, 'clip', function () {
					if (!options.keepOpen) {
						hfCommon.hideMenu($('.binMenu'));
					}
					$('.binList').off('.stopClose');
				});
			});
		});
		$('.showBins').trigger('click');
	} else {
		$.when(expandSidebarBin()).done(function () {
			$('.binList').scrollTop(0);
			blinkClips(binInfo, 'clip');
		});
	}

}

function displayMenuClip(li, value, bin) {
	let titleSpan = null,
		link = $('<a/>').attr('href', getClipUrl(value, bin)).on('click', function(e) {
            e.preventDefault();
        });
	li.empty();

	li.append($("<span>").addClass('handle')).append($("<span>")
		.addClass('thumbnail')
		.append(fieldDisplay.getThumbnailFromIndex(
			value.libraryId,
			value.thumbnailNum === null ? 0 : value.thumbnailNum,
			'small',
			'smallThumb',
			false,
			value.hashId,
			value.clipType))
		.append($("<div>")
			.addClass('positionBar')
			//.width(SMALL_THUMBNAIL_WIDTH)
			.html(
				value.accessExpires ? fieldDisplay.createPositionBar(
					value.parentOffsetOut - value.parentOffsetIn,
					value.offsetIn - value.parentOffsetIn, value.offsetOut - value.parentOffsetIn) :
				fieldDisplay.createPositionBar(value.tapeLength, value.offsetIn, value.offsetOut)

			)
		)
	);

	li.find('.thumbnail').wrapInner(link.clone(true));
	titleSpan = $('<span/>').addClass('title')
		.append($("<span />").addClass('tapeId').text(value.tapeId));

	if (value.notes) {

		titleSpan.append(': ')
			.append($("<span/>")
				.addClass('comment')
				.addClass('label')
				.html(fieldDisplay.escapeString(value.notes))
			);
	} else if (value.description) {
		titleSpan.append(': ')
			.append($("<span/>")
				.addClass('comment')
				.addClass('label')
				.html(fieldDisplay.escapeString(value.description))
			);
	}
	li.append(titleSpan.wrapInner(link.clone(true)));
	li.append($("<span />")
		.addClass("timecodes label")
		.text(fieldDisplay.trimFrames(value.timecodeIn) +
			' + ' + fieldDisplay.getLengthDisplayString(value.length)).wrapInner(link.clone(true))
	);
	if (value.specialtyCollection) {
		li.addClass('specialty');
	}
	li.attr("title", value.notes ? value.notes : value.tapeId);

	li.append($('<span/>')
		.addClass('closeContainer')
		.append(
			$('<a>')
			.addClass("close")
		)
		.attr("title", "Delete this clip")
		.on({
			click: function (e) {
				e.preventDefault();
				e.stopPropagation();

				if ($(this).hasClass('disabled')) {
					return;
				}
				$('.clip.delPrompt').removeClass('delPrompt');
				$('.clip .confirmDelete').remove();
				var title = $(this).parents('li').attr('title');
				li.addClass('delPrompt');
				li.append($('<div/>')
					.addClass('confirmDelete')
					.append($('<span/>').text('Delete this clip?'))
					.append($('<a/>')
						.addClass('button')
						.text('Yes')
						.on('click', function (e) {
							e.stopPropagation();
							$.when(deleteClip(value.id)).done(function (binInfo) {

							});
							return false;
						})
					).append($('<a/>')
						.addClass('button')
						.text('No')
						.on('click', function (e) {
							e.stopPropagation();
							$(this).closest('.clip.delPrompt').removeClass('delPrompt');
							$(this).closest('.confirmDelete').remove();
							return false;
						})
					)
				);
				return false;
			}
		})
	);

}

function displayBinClips(el, bin) {
	var count = 0,
		listBox = el.find('.listBox');

	if (bin.numClips == 0) {
		listBox.removeClass('hasClips');
		el.append($('<div/>')
			.addClass('noClips')
			.text('(This bin is empty)'))
		// el.droppable({
		// 	accept: '.clip.menuItem',
		// 	hoverClass: "dropHover",
		// 	tolerance: "touch",
		// 	drop: function (event, ui) {
		// 		console.log('empty bin');
		// 		if (ui.draggable.data('binId') != bin.id) {
		// 			moveClipToBin(bin.id, ui.draggable.data('binId'), ui.draggable.data('id'));
		// 		} else {
		// 			addBinContentsToMenu(ui.draggable.data('binId'));
		// 			//$("#binContainer" + ui.draggable.data('binId') + ' .listBox' ).sortable( "cancel" );
		// 		}
		// 	}
		// });
		return;
	}
	// if (el.hasClass('ui-droppable')) {
	// 	el.droppable('destroy');
	// }
	listBox.addClass('hasClips').data('bin', bin);
	$.each(bin.clips, function (name, value) {
		if (count >= HF_GLOBALS.numMenuClips) {
			return false;
		}
		var li = $("<li />")
			.addClass(
				`controlElement menuItem binSubMenu clip clip${value.id} aspect${value.aspectRatio * 10000}`
			)
			.attr('data-id', value.id)
			.data({
				binId: bin.id,
				//id: value.id,
				hash: value.hashId
			});

		if (value.deletedTape) {
			li.addClass('deletedTape');
		}

		displayMenuClip(li, value, bin);
		li.on({
			click: function (event) {
				var confirmDelete = false;
				if ($(event.target).closest('.closeContainer').length) {
					return;
				}
				if ($(event.target).closest('.confirmDelete').length) {
					confirmDelete = true;
				}
				$('.clip.delPrompt').removeClass('delPrompt');
				$('.clip .confirmDelete').remove();
				if (confirmDelete) {
					return false;
				}
				if ($('.clipDetails.displayed, .clip.displayed,  #resultsList .displayed').length) {
					return;
				}
				//TODO: handle global functions better
				$.when(showMenuClip(li /*listBox, li.prevAll().length, value.id*/ )).done(function (
					clipData) {
					hfCommon.hideMenu();
				});
			}
		});
		listBox.append(li);
		count++;
	});

	if (listBox.hasClass('.ui-sortable')) {
		listBox.sortable('refresh');
	}

	//container.append(listBox);
	if (count < bin.numClips) {
		el.append($('<div/>')
			.addClass('moreClips')
			.append($('<a/>')
				.addClass('leavePage')
				.addClass('manageBin')
				.attr('href', getBinUrl(bin))
				.html('&hellip;and ' + (bin.numClips - count) + ' more&hellip;')
			)
		);
	}
}

function displayBinContents(bin) {
	var numClipsStr = '',
		contentEl = binMenuList.find(`.binContent${bin.id}`),
		headerEl = binMenuList.find(`.binHeader${bin.id}`);
	switch (bin.numClips) {
	case 0:
		numClipsStr = '';
		break;
	default:
		numClipsStr = `(${bin.numClips})`;
		break;
	}
	displayBinHeader(headerEl, bin);
	contentEl.data('populated',true);
	contentEl.data('numClips', bin.numClips);
	contentEl.find('.listBox').empty().siblings().remove();
	displayBinClips(contentEl, bin);
	contentEl.prepend($("<div/>")
		.addClass('menuItem')
		.addClass('binSubMenu binBtns')
		.append($('<a/>')
			.addClass('button manageBin openBin leavePage')
			.text("Open this bin")
			.attr('href', getBinUrl(bin))
			.on('click', function () {
				location.href = getBinUrl(bin);
			})
		).append($('<a/>')
			.addClass('button manageBin orderBin')
			.text("Order this Footage!")
			.on('click', function () {

				contactForm.showContactResearcherBinDialog(bin);
			})
		)
	);
}

function addBinContentsToMenu(binId, force) {
	force = force === undefined ? true : force;

	let headerEl = binMenuList.find(`.binHeader${binId}`),
		contentEl = binMenuList.find(`.binContent${binId}`),
		dfd = new $.Deferred();

	if (!force && contentEl.data('populated')) {
		console.log('already populated')
		if (contentEl.find('.listBox').hasClass('.ui-sortable')) {
			contentEl.find('.listBox').sortable('refresh');
		}
		
		headerEl.trigger('setActive');
		dfd.resolve();
	}
	$.when(getBinContents(binId)).done(function (data) {
		if (data) {
			displayBinContents(data);
			if (contentEl.find('.listBox').hasClass('.ui-sortable')) {
				contentEl.find('.listBox').sortable('refresh');
			}
			headerEl.trigger('setActive');
			dfd.resolve(data);

			// $.when(displayBinContents(data)).done(function () {
			// 	contentEl.find('.listBox').sortable('refresh');
			// 	headerEl.trigger('setActive');
			// 	dfd.resolve(data);
			// }).fail(function() {
			// 	console.log('displayBinContents failed');
			// 	dfd.reject();
			// });
		} else {
			dfd.reject();
		}
	}).fail(function() {
		console.log('getBinContents failed');
		dfd.reject();
	});
	return dfd.promise();
}

function displayBinHeader(el, bin) {
    var numClipsStr = ''

    if (bin.numClips > 0) {
        numClipsStr = `${bin.numClips}`;
    }

    el.empty()
        .append($('<span/>')
            .addClass('name')
            .append(function() {
                if (typeof binPage !== 'undefined' && binPage?.displayBinId == bin.id) {
                    return $('<span/>').addClass('displayedBinIndicator').html('This Bin: ')
                }
            }).append(
                $('<span/>')
                .addClass('title')
                .html(fieldDisplay.escapeString(bin.title))
            )
        ).append(' ')
        .append($('<span/>').addClass('count').addClass(bin.numClips ==1 ? 'single' :'').html(numClipsStr))
        .append($('<span/>')
            .addClass('note')
            .html(fieldDisplay.nl2br(bin.note))
        );
    if (el.hasClass('ui-state-active')) {
        $('.showBins')
            .html($('<a>').attr('href', getCurrentBinUrl())
                .html(el.html())).show();
    }
}

function expandSidebarBin() {
	let dfd = new $.Deferred(),
		activeBin = $('.binHeader.ui-state-active').parent(),
		contents = activeBin.find('.binContent');
	if (!activeBin.hasClass('collapsed')) {
		dfd.resolve();
	} else {
		activeBin.toggleClass('collapsed');
		contents.animate({
				height: contents.data('origHeight')
			}, {
				complete: function () {
					contents.css('height', '');
					dfd.resolve();
				}
			}

		);
	}
	return dfd.promise();

}

function getBinMenuItem(bin) {
	let binContainer =$('<div/>')
		.addClass(`binContainer menuItem binContainer${bin.id}`)
		.data('id', bin.id),
		expandTimeout = null,
		binHeader = $("<div/>")
			.addClass(`binHeader binHeader${bin.id}`)
			.data('id', bin.id),
		binContent = $('<div/>')
			.addClass(`binContent binContent${bin.id}`)
			.data({
				binTitle: bin.title,
				numClips: bin.numClips,
				binId: bin.id
			}),
		listBox = $('<ul/>')
			.addClass('listBox'),
		sortingList = null;
	binContainer.append(binHeader, binContent.append(listBox));

	displayBinHeader(binHeader, bin);

	binHeader.on('click', function (event) {
		if (!$(this).hasClass('ui-state-active')) {
			$.when(addBinContentsToMenu(bin.id)).done(function () {
				//console.log('setActive');
				//binHeader.trigger('setActive');
			});
		} else {
			let parent = $(this).parent(),
				contents = $(this).next();
			if (!parent.hasClass('collapsed')) {
				contents.data('origHeight', contents.height());
				contents.animate({
						height: 0
					}, {
						complete: function () {
							parent.toggleClass('collapsed');
							//contents.css('height', '');
						}
					}

				);
			} else {
				expandSidebarBin();
			}

		}
	});
	import("jquery-ui/ui/widgets/droppable").then((module) => {
		binHeader.droppable({
			accept: '.clip.menuItem',
			hoverClass: "dropHover",
			tolerance: 'pointer',
			over: function (event, ui) {
				
				var header = $(this);

				if (header.hasClass('ui-state-active')) {
					header.closest('.binList').find('.menuItem.newBin').addClass('ui-droppable-active');
					return;
				}
				expandTimeout = setTimeout(function () {
					$.when(addBinContentsToMenu(header.data('id'),false)).done(function () {
						setTimeout(function() {
						//header.trigger('setActive');
						
						//refresh the positions of the droppables
						
						if (ui.helper.data('binId')) {
							let sortingList = $('.listBox.sort-origin');
							$.ui.ddmanager.prepareOffsets(
								sortingList.sortable('instance'),
								event
							);
							// $.ui.ddmanager.prepareOffsets(
							// 	$(`.binContent${header.data('id')} .listBox`).sortable('instance'),
							// 	event
							// );
							// $.ui.ddmanager.prepareOffsets(
							// 	binHeader.droppable('instance'),
							// 	event
							// );
							if (sortingList.hasClass('ui-sortable')) {
								sortingList.sortable('refresh');
								sortingList.sortable('refreshPositions');
							}
						}
						},10);
					});
				}, 500);
			},
			out: function (event, ui) {
				if (expandTimeout) {
					clearTimeout(expandTimeout);
				}
				$(this).closest('.binList').find('.menuItem.newBin').removeClass('ui-droppable-active');
			},
			drop: function (event, ui) {
				let header = $(this);
				header.closest('.binList').find('.menuItem.newBin').removeClass('ui-droppable-active');
				if (header.hasClass('ui-state-active')) {
					moveClipToNewBin( ui.draggable.data('binId'), ui.draggable.data('id'));
				} else {
					if (expandTimeout) {
						clearTimeout(expandTimeout);
					}
					moveClipToBin(bin.id, ui.draggable.data('binId'), ui.draggable.data('id'));
				}
			}
		});
		import("jquery-ui/ui/widgets/sortable").then((module) => {
			listBox.sortable({
		        appendTo: binMenuList.closest('.binMenu'),
		        placeholder: 'clipDragPlaceholder',
		        connectWith: '.binList .listBox',
		        helper: function (event, ui) {
		            var helper = ui.clone(true);
		            helper.addClass('dragHelper binSortHelper fromSideBar').addClass(`fromBin${ui.data('binId')}`);
		            $('.close', helper).remove();
		            return helper;
		        },
		        handle: ".handle",
		        //containment: '.binList',
		        tolerence: 'pointer',
		        axis: 'y',
		        start: function(event, ui) {
		            $(document).one('keydown.cancelDrag', function(e) {
		                if (e.keyCode == keycode('esc')) {
		                    
		                    ui.item.parent().sortable('cancel');
		                    if (!$(`.binHeader${bin.id}`).hasClass('ui-state-active')) {
		                        $(`.binHeader${bin.id}`).trigger('click');
		                    }
		                }
		            });
		            listBox.addClass('sort-origin');
		        },
		        stop: function(event, ui) {
		            $(document).off('keydown.cancelDrag');
		            listBox.removeClass('sort-origin');
		        },
		        out: function (event, ui) {
		            ui.item.addClass('out');
		        },
		        over: function (event, ui) {
		        	if (ui.item.hasClass('out')) {
		        		console.log('refresh');
		        		let sortingList = $('.listBox.sort-origin');
		        		$.ui.ddmanager.prepareOffsets(
			                sortingList.sortable('instance'),
			                event
			            );
			            sortingList.sortable('refresh');
						sortingList.sortable('refreshPositions');
		        	}
		            
		            ui.item.removeClass('out');
		        },
		        receive: function (event, ui) {

		            moveClipToBin(bin.id, ui.item.data('binId'), ui.item.data('id'), null, ui.item.prevAll().length + 1);
		        },
		        update: function (event, ui) {

		            if (ui.item.hasClass('out') || ui.item.data('binId') != bin.id || !$(this).is(':visible')) {
		                return;
		            }
		            sortClips(bin.id, ui.item.data('id'), ui.item.prevAll().length);
		        }
		    });
		});
	});

	return binContainer;
}

function populateBinMenu() {
	var firstBinId = null,
		foundCurrentBin,
		dfd = new $.Deferred();
	binMenuList.empty();
	numBins = 0;
	$.each(binListJson.bins, function (name, value) {
		numBins++;
		if (firstBinId === null) {
			firstBinId = value.id;
		}

		var menuItem = getBinMenuItem(value);

		if (value.id == Cookies.get('currentBinId')) {
			binMenuList.prepend(menuItem);
			$('.showBins').html($('<a>').attr('href', getCurrentBinUrl()).html(menuItem.find(
				'.binHeader').html())).show();
			$('.binMenu > .header').html(menuItem.find('.binHeader').html());
			foundCurrentBin = true;
		} else {
			$(".binMenu .binList").append(menuItem);
		}

	});
	binMenuList.append(
		$("<div/>")
		.addClass('menuItem newBin')
		.append($('<a/>')
			.addClass('button')
			.text("New bin")
			.on('click', function () {
				showAddBinDialog(function (binName) {
					createBin(binName, {
						highlightBin: true
					});
				});
			})
		)/*.droppable({
			accept: '.clip.menuItem',
			hoverClass: "dropHover",
			greedy: true,
			tolerance: 'fit',
			drop: function (event, ui) {
				console.log('new bin');
				moveClipToNewBin(ui.draggable.data('id'));
			}
		})*/
	);
	import("jquery-ui/ui/widgets/accordion").then((module) => {
		binMenuList.accordion({
			heightStyle: "content",
			//collapsible: true,
			//active: 0,
			//animate: false,
			header: "> .binContainer >  div.binHeader",
			event: 'setActive',
			beforeActivate: function (event, ui) {

			},
			activate: function (event, ui) {
				console.log('activate ' + ui.newHeader.data('id'));
				setCurrentBin(ui.newHeader.data('id'));
				if (!ui.newHeader.data('keepPosition')) {
					ui.newHeader.closest('.binContainer').prependTo('.binList');
					$('.binList').scrollTop(0);
				}
				ui.newHeader.data('keepPosition', null);
				if (activationDfd) {
					activationDfd.resolve();
					activationDfd = null;
				}
				binMenuList.find('.binContainer.collapsed .binContent').css({
					display: 'none',
					height: ''
				});
				binMenuList.find('.binContainer.collapsed').removeClass('collapsed');

			}
		});
	});
	if (numBins) {
		if (!foundCurrentBin) {
			var fistItem = $(".binMenu .binList .binContainer:first-child");
			setCurrentBin(fistItem.data('id'));
			//$('.showBins, .binMenu > .header').html(fistItem.find('.binHeader').html());
			//$('.showBins').show();
		}
		$.when(addBinContentsToMenu(currentBinId)).done(function () {
			dfd.resolve(numBins);
		}).fail(function() {
			console.log('addBinContentsToMenu failed');
			dfd.reject();
		});
	} else {
		dfd.resolve(numBins);
	}
	return dfd.promise();
}

function displayBinList(createBinIfEmpty, bins) {

	var dfd = new $.Deferred(),
		binMenuList = $(".binMenu .binList");
	createBinIfEmpty = createBinIfEmpty === undefined ? true : createBinIfEmpty;

	if (bins !== undefined) {
		binListJson = bins;
	}

	if (binMenuList.hasClass('ui-accordion') && typeof(binMenuList.accordion) !== 'undefined') {
		binMenuList.accordion('destroy');
	}

	if (!hfCommon.cookiesEnabled()) {
		$('.showBins, .binMenu .header').html('My Bins');
		binMenuList.empty().append($('<div/>')
			.addClass('binHeader')
			.append($('<span/>')
				.addClass('error')
				.text(
					'You appear to have cookies disabled in your browser.  Cookies must be enabled to use this feature.'
				)
			)
		);
		binSelect.prop('disabled', true);
		return;
	}
	populateBinSelect();
	$.when(populateBinMenu()).done(function (numBins) {
		$('.numBins').text(numBins);
		if (!numBins && createBinIfEmpty) {
			$.when(createBin(HF_GLOBALS.defaultBinName, {
				tempBin: true
			})).always(function () {
				dfd.resolve();
			});
		}
		dfd.resolve();
	}).fail(function() {
		console.log('populateBinMenu failed');
		dfd.reject();
	});
	return dfd.promise();
}

function addClipToBinHelper(data) {
	if (data.binId == -1) {
		return showAddBinDialog(function (newName) {
			data.binName = newName;
			return addClipToBinAjax(data);
		});
	} else if (data.binId) {
		return addClipToBinAjax(data);
	} else {
		showSelectBinDialog(function (binId, binName) {
			data.binId = binId;
			data.binName = binName;
			return addClipToBinAjax(data);
		});
	}
}

function addClipToBin(data) {
	if (!hfCommon.cookiesEnabled()) {
		hfCommon.showCookiesAlert();
		return;
	}
	if (data.binId == 'current') {
		data.binId = currentBinId;
	}
	return $.when(addClipToBinHelper(data)).done(function (binInfo) {
		handleClipCreated(binInfo, data.binName !== undefined, {

		});
	});

}

function addClipsToBin(clips, binId) {
	if (binId) {
		return addClipsToBinHelper(clips, binId);
	} else {
		showAddBinDialog(function (binName) {
			return addClipsToBinHelper(clips, binId, binName);
		});
	}
}

function generateBinHash() {
	return hex_sha1(Math.random().toString());
}

function init() {
	binMenu = $('.binMenu');
	binMenuList = binMenu.find('.binList');
	binSelect = $('.binSelect');
	currentBinId = Cookies.get('currentBinId');

	// binMenu.waitingBox({
	// 	autoStart: true
	// }).data('keepWaiting', true);
	

	return getBins();
}

function isReady() {
	return ready;
}

export const bins = {
	init: init,
	generateBinHash: generateBinHash,
	addClipsToBin: addClipsToBin,
	addClipToBin: addClipToBin,
	deleteClip: deleteClip,
	sortClips: sortClips,
	setCurrentBin: setCurrentBin,
	saveClipChanges: saveClipChanges,
	showAddBinDialog: showAddBinDialog,
	showMenuClip: showMenuClip,
	displayBinList: displayBinList,
	getClip: getClip,
	getCurrentBinUrl: getCurrentBinUrl,
	populateBinSelect: populateBinSelect,
	//moveClipToBin: moveClipToBin,
	numBins: numBins,
	getClipUrl: getClipUrl,
	getBinUrl: getBinUrl,
	getCSVUrl: getCSVUrl,
	getListInfo: getListInfo,
	isInitialized: isReady
};


