const Cookies = require('js-cookie');

import {fieldDisplay} from './fieldDisplay.js';
import {dialogs} from './dialogs.js';
import {browserCheck} from './browserCheck.js';
import {binPage} from '../binPage/binPageFunctions.js';
require("./jquery-ui-touch.js");
//require ('../../lib/newPlayer/jquery.videoPlayer.js');

//require ('./jquery.videoWindow.js');
//require ('./jquery.searchForm.js');


//globals
var ios7bars = null,
	ignoreResize = false,
	largestViewPort = {};
	
export let page = {};

export const hfCommon = {
	scrollToResult: function(el) {
		if (el && el.length) {
			$('html').scrollTop(el.position().top /*-  $("#pageHeader").outerHeight() */ );
			el.closest('.results .list').data('topResult', el);
		}
	},
	getScrollTopResult: function() {
		var headerHeight = $("#pageHeader").height(),
			scrollTop = $('html').scrollTop() + headerHeight,
			topResult = null,
			list = null;

		if (!$('.results .list:visible').length) {
			return null;
		}

		list = $('.results .list:visible').first();
		list.find('.result').each(function () {
			var el = $(this);
			if (el.offset().top > scrollTop) {
				if (!list.data('topResult') || el.offset().top != list.data('topResult').offset().top) {
					list.data('topResult', $(this));
				}

				return false;
			}
		});

		return list.data('topResult');
	},
	fixFixed: function(e) {
		var focusedEl,
			dialog,
			scrollTop = $(document).scrollTop(),
			availableHeight = window.innerHeight ? window.innerHeight : $(window).height(),
			scrollingDialog = false,
			padding = 20; //padding above text element when moving it to the top of the screen

		if (e !== undefined && $('html').hasClass('fixFixed') && $('.ui-dialog:visible').length) {
			return;
		}
		if (e === undefined) {
			if (!$('.focused').length) {
				return;
			}
			focusedEl = $('.focused').first();
		} else {
			focusedEl = $(e.target);
		}

		if (e !== undefined) {
			dialog = $(e.target).closest('.ui-dialog');
			if (dialog.length) {
				scrollingDialog =
					($(".ui-dialog-content", dialog)[0].scrollHeight > $(".ui-dialog-content", dialog).height()) ?
					true : false;
			}
			if (browserCheck.isIOS() && (!dialog.length || !scrollingDialog) && !focusedEl.closest('.videoPlayer').length) {
				$(document).on('touchmove.fixFixed', function (e) {
					e.preventDefault();
				});
			}
		}

		if (focusedEl.closest('.ui-dialog.fullScreen:visible').length) {
			return;
		}

		if (focusedEl.closest('#pageHeader').length || focusedEl.closest('.videoPlayer').length ||
			focusedEl.closest('.ui-dialog').length === 0) {
			$('html').addClass('fixFixed');

			//wait for the keyboard to open (after the event is finished) and
			//postion the header
			setTimeout(function () {
				var IOShandheldLandscape = false;

				$('.holdout.bottom').css({
					position: 'absolute'
				});

				scrollTop = $(document).scrollTop();
				availableHeight = window.innerHeight ? window.innerHeight : $(window).height();

				availableHeight -= padding; //add a buffer.
				IOShandheldLandscape = browserCheck.isIOSHandHeld() && (scrollTop === 0 || ios7bars) && (window.orientation ==
					90 || window.orientation == -90);

				//if the focused text box is in the main page content, set the scroll
				//position so it is visible
				if (!focusedEl.closest('#pageHeader').length && !focusedEl.closest('.videoPlayer').length) {
					var portrait = false;
					var orientation = screen.orientation || screen.mozOrientation || screen.msOrientation;
					if (orientation && (orientation === "portrait-secondary" ||
							orientation === "portrait-primary")) {
						portrait = true;
					} else if (window.orientation !== undefined) {
						portrait = window.orientation % 180 === 0;
					}
					var landscape = !portrait;

					if (landscape || availableHeight < $('#pageHeader').outerHeight() + focusedEl.outerHeight() +
						2 * padding) {
						$('#pageHeader, .holdout.top').css({
							top: -$('#pageHeader').outerHeight() + 'px',
							position: 'absolute'
						});
						//$(document).scrollTop(0);
						//$(document).scrollTop(focusedEl.offset().top - (isAOSP42() ? parseInt($('#pageContent').css('padding-top')) : 0) - padding);
					} else if (browserCheck.isAndroid()) {
						$(document).scrollTop(0);
						$(document).scrollTop(focusedEl.offset().top - $('#pageHeader').outerHeight() - padding);
					} else if (browserCheck.isIOS()) {
						$(document).scrollTop(0);
						$(document).scrollTop(focusedEl.offset().top - $('#pageHeader').outerHeight() - padding);
						setTimeout(function () {
							$('#pageHeader, .holdout.top').css({
								top: $(document).scrollTop() + 'px',
								position: 'absolute'
							});
						}, 0);

					}
					return;

					//if the focused textbox is in the page header. position the header
					//so it is visible.
				} else if (focusedEl.closest('#pageHeader').length) {
					$(document).scrollTop(scrollTop);

					if ($('.focused').closest('#searchBar').length &&
						(IOShandheldLandscape ||
							focusedEl.closest('#searchBar').position().top + focusedEl.position().top + focusedEl.outerHeight() +
							3 * padding >
							availableHeight)) {

						$('#pageHeader, .holdout.top').css({
							top: scrollTop + padding - $('.focused').closest('#searchBar').position().top -
								focusedEl.position().top + 'px',
							position: 'absolute'
						});
					} else if ($('.focused').closest('#signInForm').length &&
						(IOShandheldLandscape ||
							focusedEl.position().top + focusedEl.outerHeight() + $('#signInForm').position().top + 2 *
							padding >
							availableHeight)) {
						$('#pageHeader, .holdout.top').css({
							top: scrollTop - $('#signInForm').position().top - focusedEl.position().top + 'px',
							position: 'absolute'
						});

					} else {
						$('#pageHeader, .holdout.top').css({
							top: scrollTop + 'px',
							position: 'absolute'
						});
					}
					//set th scroll position if the videoplayer window if 	
				} else if (focusedEl.closest('.videoPlayer.fullScreen').length && focusedEl.closest(
						'.titleBar').length) {


					/*
					if ($('#navigationControls').height() + focusedEl.closest('.titleBar').outerHeight() +
						2 * padding > availableHeight) {
						focusedEl.closest('.videoPlayer').removeClass('fullScreen').addClass('fullScreen_fixFixed');
						$('.videoPlayer').css({
							top: scrollTop - $('#navigationControls').height() + padding + 'px',
							position: 'absolute'

						});
					}*/
				}
				/*else if ($('#pageHeader, .holdout.top').offset().top > scrollTop) {
								$('#pageHeader, .holdout.top').css({
									top: scrollTop + 'px',
									position: 'absolute'
								});
							}*/

			}, 500);

		} else if (browserCheck.isAndroid()) {
			//position the dialog so the content area fills the height of the screen.
			if (focusedEl.closest('.ui-dialog:visible').length) {
				dialog = focusedEl.closest('.ui-dialog:visible');
				var dialogContent = focusedEl.closest('.ui-dialog-content');
				if (dialogContent.height() > availableHeight) {
					dialog.css('top', -dialog.find('.ui-dialog-titlebar').outerHeight());
					dialogContent.css('height', availableHeight);
					setTimeout(function () {
						dialogContent.scrollTop(0);
						dialogContent.scrollTop(focusedEl.position().top - padding);
					}, 0);
				}
			}

		}
	},
	cookiesEnabled: function() {
		var cookieEnabled = (navigator.cookieEnabled) ? true : false;

		if (typeof navigator.cookieEnabled == "undefined" && !cookieEnabled) {
			document.cookie = "testcookie";
			cookieEnabled = (document.cookie.indexOf("testcookie") != -1) ? true : false;
		}
		return cookieEnabled;
	},
	showCookiesAlert: function() {
		if (!hfCommon.cookiesEnabled()) {
			dialogs.showAlert({
				title: 'Cookies required',
				message: 'You appear to have cookies disabled in your browser.  Cookies must be enabled to use this feature.'
			});
		}
		return !hfCommon.cookiesEnabled;
	},
	hideMenu: function(menu) {
		var topResult = null,
			scrollTop = $('html').scrollTop();
			
		menu = menu == undefined ? $('.overlay:not(.binMenu)') : menu;

		function afterClose() {
			console.log('afterClose')
			dialogs.setDialogClosed();
			$('.overlay .delPrompt').removeClass('delPrompt');
			$('.overlay .confirmDelete').remove();
			if ($('.overlay input:focus').length) {
				$('.overlay input:focus').blur();
			}
			$('.binList .binHeader.ui-state-active').closest('.binContainer').prependTo('.binList');
			$('.binList').scrollTop(0);
			$('.myLinks .ui-widget-overlay').remove();
		}
		if (!$('.overlay').is(':visible')) {
			return false;
		} else {

			if (menu.css('transition-duration') != '0s') {

				topResult = hfCommon.getScrollTopResult();
				menu.one('transitionend', function () {
					$('.scroller-initiailzed').scroller('handleResize');
					afterClose();
					if ($('html').scrollTop() == 0) {
					    return;
					}
					if (topResult) {
						hfCommon.scrollToResult(topResult);
					}
					
				});
			} else {
				afterClose();
			}
			menu.removeClass('overlay');
			menu.find('a, button, .controlElement').attr('tabindex', '-1');
			menu.each(function () {
				if ($(this).hasClass('binMenu')) {
					$('html').removeClass('binSidebarOpen');
				}
				if ($(this).hasClass('accountMenu')) {
					$('html').removeClass('accountMenuOpen');
				}
				$('html').removeClass('dialogOpen');

			});
			dialogs.setDialogClosed();
			return true;
		}
	},
	blink: function(el, callback) {
		var loopCount = 0,
			numBlinks = 2,
			loop = function () {
				el.animate({
					'background-color': '#ebc600'
				}, {
					duration: 500,
					complete: function () {
						el.animate({
							'background-color': '#ddd'
						}, {
							duration: 500,
							complete: function () {
								loopCount++;
								if (loopCount < numBlinks) {
									loop();
								} else {
									callback();
								}
							}
						});
					}
				});
			};
		if (Modernizr.cssanimations) {
			el.one(
				'animationend webkitAnimationEnd oAnimationEnd',
				function () {
					$(this).removeClass('blink');
					if (typeof callback == 'function')
						callback();
				});
			el.addClass('blink');
		} else {
			loop();
		}
	},
	resetFixedPos: function(obj) {

		var focusedEl = $('.focused'),
			padding = 20;

		focusedEl.removeClass('focused');
		if (obj !== undefined) {
			focusedEl = obj;

		}

		$('html').removeClass('disableScrollPageLoad');

		$('#pageHeader, .holdout.top,.videoPlayer').css({
			top: '0',
			position: ''
		});
		$('.holdout').css({
			position: ''
		});
		$('.ui-dialog').css({
			'top': '0',
			'margin-left': '',
			position: 'fixed'
		});
		$('.ui-dialog .ui-dialog-content').css({
			'min-height': ''
		});
		$('.fullScreen_fixFixed').removeClass('fullScreen_fixFixed').addClass('fullScreen');
		$('html').removeClass('fixFixed');
		$(document).off('touchmove.fixFixed');
		hfCommon.resizeHandler();

		if (focusedEl.length && focusedEl.closest('#pageContent').length) {

			var newTop = null;

			if ($('body').data() && $('body').data('origScrollTop') !== '' && $('body').data('origScrollTop') !==
				undefined) {
				newTop = $('body').data('origScrollTop');
			} else if (focusedEl.length && ($(document).scrollTop() + $(window).height() < focusedEl.offset()
					.top ||
					$(document).scrollTop() > focusedEl.offset().top)) {
				newTop = focusedEl.offset().top - parseInt($('#pageContent').css('padding-top')) - padding;
			}
			//alert('set ' + focusedEl.offset().top + ' '  + focusedEl.attr('id'));
			if (newTop !== null) {
				$(document).scrollTop(0);
				$(document).scrollTop(newTop);
			}

			$('body').data('origScrollTop', '');
		}
		ignoreResize = false;
	},
	resizeHandler: function() {
		var videoHeight,
			menu,
			portrait = false,
			orientation;

		
		if ($("#resultsList").length) {
			$("#resultsList").scrollManager('updateScrollHeight');
		}
		if (window.innerWidth < HF_GLOBALS.fixedHeaderThreshold) {
			$(".headerContainer").addClass('fixed');
			$('html').addClass('fixedHeader');
		} else if ($(window).scrollTop() == 0) {
			$(".headerContainer").removeClass('fixed');
			$('html').removeClass('fixedHeader');
		}

		if ($("#videoWindow").is(':visible')) {
			$("#videoWindow").videoWindow('resize');
		}
		

		///Viewport Handling to be updated...
		if (Math.max(verge.viewportW(), verge.viewportH) > largestViewPort.height) {
			largestViewPort.height = Math.max(verge.viewportW(), verge.viewportH);
		}

		if (Math.min(verge.viewportW(), verge.viewportH) > largestViewPort.width) {
			largestViewPort.width = Math.max(verge.viewportW(), verge.viewportH);
		}

		if (browserCheck.isIOS() && browserCheck.getIOSVersion() < 8 && $(".focused").length) {
			orientation = screen.orientation || screen.mozOrientation || screen.msOrientation;
			if (orientation && (orientation === "portrait-secondary" ||
					orientation === "portrait-primary")) {
				portrait = true;
			} else if (window.orientation !== undefined) {
				portrait = window.orientation % 180 === 0;
			}

			if (window.innerHeight >= 0.7 * (portrait ?
					largestViewPort.height : largestViewPort.width)) {

				$(".focused").blur();
			}
			//fixFixed();
			//return;
		} else if (browserCheck.isAndroid() && $(".focused").length) {
			orientation = screen.orientation || screen.mozOrientation || screen.msOrientation;
			if (orientation && (orientation === "portrait-secondary" ||
					orientation === "portrait-primary")) {
				portrait = true;
			} else if (window.orientation !== undefined) {
				portrait = window.orientation % 180 === 0;
			}

			if (verge.viewportH() >= 0.7 * (portrait ?
					largestViewPort.height : largestViewPort.width)) {

				$(".focused").blur();
			} else {
				if (!ignoreResize) {
					hfCommon.fixFixed();
				}
				ignoreResize = false;
			}
			hfCommon.fixFixed();
			return;
		}

		if ($('.ui-dialog:not(:hidden)').length) {
			dialogs.adjustDialogPosition($('.ui-dialog:not(:hidden)'));
		}
		
		// if ($('#videoWindow.popup:not(:hidden)').length) {
		// 	adjustDialogPosition($('#videoWindow.popup:not(:hidden)'), 0, 0);
		// }
		if ($('.menu.overlay').length) {
			menu = $('.menu.overlay');
			menu.find('.menuContents').css({
				'max-height': ($(window).height() -
					2 * (menu.offset().top - $(document).scrollTop()) -
					(menu.find('.header').outerHeight())) + 'px'
			});
			if ($('.menu.overlay').width() >= $(window).width()) {
				$('html').addClass('fullScreenDialogOpen');
			} else {
				$('html').removeClass('fullScreenDialogOpen');
			}
		}
		//adjust the size of video-js players in dialogs.
		// if ($(".ui-dialog #videoPopup").is(':visible')) {
		// 	var aspectRatio = $(".ui-dialog #videoPopup").data('aspectratio') ?
		// 		$(".ui-dialog #videoPopup").data('aspectratio') : 3 / 4;

		// 	videoHeight = Math.min($('#videoPopup').width() * aspectRatio, $('#videoPopup').height());
		// 	$("#videoPopup .videoPlayerBox").css({
		// 		'height': videoHeight,
		// 		'width': videoHeight / aspectRatio
		// 	});
		// } else if ($("#videoPopup").is(':visible')) {
		// 	$("#videoPopup .videoPlayerBox").css({
		// 		'height': '',
		// 		'width': ''
		// 	});
		// }
		//set the height of the layout container to force IOS safari to
		//recalculate the touch positions.
		$("#layoutContainer").css('height', $("#layoutContainer").height() + 10);
		setTimeout(function () {
			$("#layoutContainer").css('height', '');
		}, 100);
		if (browserCheck.isIOS() && $('html').hasClass('fixFixed')) {
			//$('.fullScreen').removeClass('fullScreen').addClass('fullScreen_fixFixed');
			$('#pageHeader, .holdout.top, .fullScreen_fixFixed').css({
				'top': $(document).scrollTop() + 'px',
				position: 'absolute'
			});
		}
	},
	updateField: function(objectName, fieldName, fieldValue, orig, idValue, element) {
		
		//make sure the first letter of the object name is capitalized
		var first = objectName.substr(0, 1),
			rest = objectName.substr(1, objectName.length - 1);
		
		objectName = first.toUpperCase() + rest.toLowerCase();

		$.ajax({
			data: {
				action: 'update' + objectName,
				fieldName: fieldName,
				fieldValue: fieldValue,
				id: idValue
			},
			dataType: 'json',
			success: function (obj) {
				switch (objectName) {
				case 'Clip':
					$("#clipDetails" + obj.id + ' .clipDetailsContents').attr('title', obj.notes ? obj.notes :
						obj.tapeId);
					if ($('#clip' + obj.id).length) {
						getClipRow($('#clip' + obj.id), obj);
					}
					if (typeof (binPage?.updateModifiedDate) !== 'undefined' && obj.binId == binPage?.displayBinId) {
						binPage.updateModifiedDate(obj);
					}
					break;
				case 'Bin':
					obj.note = obj.note ? fieldDisplay.nl2br(obj.note) : '';
					obj.title = obj.title ? fieldDisplay.escapeString(obj.title) : '';
					if (obj.note) {
						$("#binNote" + obj.id).html(obj.note);

						if (binPage.displayBinId == obj.id && obj.note) {
							$("#binDescription").html(obj.note);
						}
					}
					if (binPage?.updateModifiedDate  && obj.id == binPage.displayBinId) {
						binPage.updateModifiedDate(obj);
					}
					if (obj.title) {
						$(".binSelect option[value=" + obj.id + "]").html(obj.title);
					}
					if (Cookies.get('currentBinId') == obj.id) {
						$(".showBins .name .title").html(obj.title);
						$(".binMenu .header .name .title").html(obj.title);
						$(".binMenu .header .note").html(obj.note);
					}
					$("#binHeader" + obj.id + " .name .title").html(obj.title);
					$("#binHeader" + obj.id + " .note").html(obj.note);

					break;
				}
			},
			error: function (data, testStatus, errorThrown) {

				if (element) {
					element.html(orig);
				}

			}
		});
	},
};

