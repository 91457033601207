import { dialogs } from '../../../js/common/dialogs.js';

//import PBKDF2 from 'crypto-js/pbkdf2';

//var CryptoJS = require("crypto-js");



var useCaptcha = true;
var validated = false;
var readyToSubmit = false;

function generateFiller(password) {
	// var filler = '';
	// for (var i = 0; i < password.length; i++) {
	// 	filler += "X";
	// }
	// return filler;
	return password;
}




export let UserManager = {
	
	ajaxPage: null,
	//generate a string of the same length as the password to avoid
	//momentary blaniking of the field on submit.
	
	renderLogInForm: function(formID, className, action, container) {
		var form = $("<form></form>");
		form.attr('id', formID);
		form.attr('class', className);
		//form.attr('action', action);
		form.attr('method', 'post');

		var table = $("<table></table>");
		table.append("<tr><td>Username:</td><td><input type=\"text\" id=\"" + formID +
			"_userName\" name=\"userName\"></td></tr>");
		table.append("<tr><td>Password:</td><td><input type=\"password\" id=\"" + formID +
			"_userPassword\" name=\"userPassword\"></td></tr>");
		table.append("<tr><td colspan=\"2\"><input type=\"submit\" value=\"Log In\" /></td></tr>");
		$(document).ready(function () {
			$("#" + formID + "_userName").focus();
		});
		form.append(table);
		$(container).append(form);
		this.setupLoginForm(formID);
	},

	setupLogInForm: function(formID) {

		var form = $(`#${formID}`),
			$userName = $("#" + formID + "_userName"),
			$password = $("#" + formID + "_userPassword");
		$userName.on("input", function () {
			if (this.validity.typeMismatch) {
				this.setCustomValidity("'" + $(this).val() + "' is not a valid email address.");
			} else if (this.validity.valueMissing) {
				this.setCustomValidity("You must enter an email address.");
			} else {
				this.setCustomValidity("");
			}
		}).trigger('input');
		$password.on("input", function () {
			if (this.validity.valueMissing) {
				this.setCustomValidity("You must enter a password.");
			} else {
				this.setCustomValidity("");
			}
		}).trigger('input');

		form.append("<input type=\"hidden\" name=\"LoginAction\" value=\"login\" />");
		form.submit(function (event) {
			var password = $password.val();
			var userName = $userName.val();

			if (readyToSubmit) {
				readyToSubmit = false;
				return true;
			} else {
				$("[type=submit]", form).prop('disabled', true);
				event.preventDefault();
			}

			//get the challenge for the userName
			$('#layoutContainer').data('disableWaiting', true);
			$.ajax({
				type: 'POST',
				global: false,
				url: UserManager.ajaxPage,
				data: {
					action: 'getChallenges',
					userName: userName
				},
				error: function (xReq, textStatus, errorThrown) {
					$('#layoutContainer').data('disableWaiting', '');
					dialogs.showAlert(xReq.responseText, function () {}, "Error");
					$("[type=submit]", $("#" + formID)).prop('disabled', false);
					return false;
				},
				success: function (data, textStatus) {
					var urlHash = '',
						passwordHash = '',
						nextHash = '',
						challenge = $(data).find("results").find("challenge").text(),
						challengeNext = $(data).find("results").find("nextChallenge").text();

					$('#layoutContainer').data('disableWaiting', '');
					if (location.href.indexOf('#') != -1) {
						urlHash = location.hash.substr(1); //location.href.substring(location.href.indexOf('#') +1);
					}

					//compute the hashed responses
					//passwordHash = hex_hmac_sha1(password, challenge);
					passwordHash = hex_hmac_sha1(password, challenge);
					
					nextHash = Crypto.PBKDF2(hex_hmac_sha1(password, challengeNext),challengeNext, 20, {
						iterations: 1000
					});

					var newForm = form.clone();
					newForm.attr('id', formID + '_Copy').hide();
					newForm.find(":password").remove();
					$('body').append(newForm);

					newForm.append("<input type=\"hidden\" name=\"passwordHash\"  id=\"" +
						formID + "_passwordHash\" value=\"" + passwordHash + "\"/>");
					newForm.append("<input type=\"hidden\" name=\"next\" id=\"" + formID +
						"_next\" value=\"" + nextHash + "\"/>");
					if (urlHash !== undefined) {
						newForm.append(
							$('<input name="urlHash" "type="hidden">')

							.attr('id', formID + "_urlHash")
							.val(urlHash)
							.css('display', 'none'));
					}

					//$("#" + formID + "_userPassword").val(generateFiller(password));

					readyToSubmit = true;
					newForm.submit();
				},
				dataType: 'xml'
			});
		});
	},

	submitSignUpForm: function(formID, canSubmit, displayError) {
		var self = this;

		var userName = $("#" + formID + "_userName").val();
		var password = $("#" + formID + "_userPassword").val();
		var confirmPassword = $("#" + formID + "_confirmPassword").val();
		var errorMessage = '';

		/*
		if (!password) {
		
			errorMessage = "Please enter a password.";
			
		} else if (!userName) {
			errorMessage = "Please enter your email address.";
			
		}else if (password !=  confirmPassword ) {
			errorMessage = "The passwords you entered do not match.";
			
		}else {
			//errorMessage = testPasswordStrength(password)) {	
		}
		if (errorMessage) {
			if (errorContainer != undefined) {
				errorContainer.text(errorMessage);
			} else {
				showAlert(errorMessage);
			}
			return false;
		}
		*/

		if (!validated) {
			$.ajax({
				url: UserManager.ajaxPage,
				global: false,
				data: {
					action: 'validateFormInput',
					username: $("#" + formID + "_userName").val(),

					//recaptchaChallenge: $("#recaptcha_challenge_field").val(),
					//recaptchaResponse: $('#g-recaptcha-response').val() 
				},
				success: function (response) {

					if (response == 'success' && canSubmit) {
						validated = true;
						$("#" + formID).submit();
						return true;
					} else if (canSubmit) {
						displayError(response);
					}
				},
				error: function (jqXHR, textStatus, errorThrown) {

					if (displayError !== undefined) {
						displayError(jqXHR.responseText);

					} else {
						dialogs.showAlert(jqXHR.responseText, function () {}, "Error");
					}
					return false;
				}
			});
			return true;
		}

		if (readyToSubmit) {
			validated = false;
			readyToSubmit = false;
			//$(this).dialog('close');
			//this will submit the form
			return true;

		}

		//get the challenge for the user
		$.ajax({
			type: 'POST',
			global: false,
			url: UserManager.ajaxPage,
			data: "action=getNewUserChallenge",
			error: function (xReq, textStatus, errorThrown) {
				dialogs.showAlert(xReq.responseText, function () {}, "Error");
				return false;
			},
			success: function (data, textStatus) {

				var challenge = $(data).find("results").find("challenge").text(),
					passwordHash = Crypto.PBKDF2(hex_hmac_sha1(password, challenge), challenge, 20, {
						iterations: 1000
					});

				$("#" + formID).append("<input type=\"hidden\" name=\"passwordHash\"  id=\"" + formID +
					"_su_passwordHash\" value=\"" + passwordHash + "\"/>");
				$("#" + formID + "_userPassword").val(generateFiller(password));
				$("#" + formID + "_confirmPassword").val(generateFiller(password));

				readyToSubmit = true;
				$("#" + formID).submit();
			},
			dataType: 'xml'
		});
		return false;
	},

	renderSignUpForm: function(formID, className, action, container, includeButtons,
		recaptchaKey) {
		var self = this;
		var form = $("<form></form>");
		form.attr('id', formID);
		form.attr('class', className);
		//form.attr('action', action);
		form.attr('method', 'post');
		form.append("<input type=\"hidden\" name=\"LoginAction\" value=\"signup\" />");

		var table = $("<table></table>");
		table.append("<tr><td>Email:</td><td><input type=\"text\" id=\"" + formID +
			"_userName\" name=\"userName\"></td></tr>");
		table.append("<tr><td>Password:</td><td><input type=\"password\" id=\"" + formID +
			"_userPassword\" name=\"userPassword\"></td></tr>");
		table.append("<tr><td>Confirm Password:</td><td><input type=\"password\" id=\"" + formID +
			"_confirmPassword\" name=\"confirmPassword\"></td></tr>");

		//recaptchaHTML = $('<div/>').html(recaptchaHTML).text();
		table.append(
			$("<tr/>")
			.append(
				$("<td/>")
				.attr('colspan', '2')
				.attr('id', formID + '_captcha')
			)
		);

		if (includeButtons) {
			table.append("<tr><td colspan=\"2\"><button id=\"" + formID +
				"_signupSubmit\">Sign Up</button></td></tr>");
		}

		form.append(table);
		$(container).append(form);

		if (useCaptcha) {
			/*Recaptcha.create(recaptchaKey, formID + "_captcha", {
				 theme: "red",
				 callback: Recaptcha.focus_response_field}
			);*/
		}

		$("#signupSubmit").click(function () {
			return self.submitSignUpForm(formID);

		});

		$("#" + formID).submit(function (event) {

			return self.submitSignUpForm(formID);
		});
	},

	renderSignUpDialog: function(formID, dialogId, dialogTitle, className, action, container,
		recaptchaKey) {
		var um = this;
		var div = $("<div class=\"shadowBox\" id=\"" + dialogId + "\" title=\"" + dialogTitle +
			"\"></div>");
		this.renderSignUpForm(formID, className, action, div, false);
		$('body').append(div);
		//container.append("<a style=\"cursor:pointer\" id=\"btnChangePass\" >Change Password</a>");
		$("#" + formID).submit(function (event) {

			return um.submitSignUpForm(formID);
		});
		$("#" + dialogId).dialog({
			bgiframe: true,
			autoOpen: false,
			modal: true,
			width: '325px',
			resizable: false,
			buttons: {

				'Sign Up': function () {
					return $("#" + formID).submit();

				},
				'Cancel': function () {

					//clear the form values
					//$("#" +formID + "_oldPassword").val("");
					//$("#" + formID + "_newPassword").val("");
					//$("#" + formID + "_confirmPassword").val("");
					$(this).dialog('close');

				}
			}
		});

		container.click(function () {
			$("#" + dialogId).dialog('open');
		});

	},

	renderChangePasswordForm: function(formID, className, action, container) {
		var form = $("<form></form>");
		form.attr('id', formID);
		form.attr('class', className);
		form.attr('action', action);
		form.attr('method', 'post');

		form.append("<input type=\"hidden\" name=\"LoginAction\" value=\"changepass\" />");
		var table = $("<table class=\"changePasswordForm\"></table>");

		table.append("<tr><td style=\"text-align:right\"><label for=\"" + formID +
			"_oldPassword\">Old password:</label></td><td class=\"passwordInput\"><input type=\"password\" id=\"" +
			formID +
			"_oldPassword\" name=\"oldPassword\"></td></tr>");
		table.append("<tr><td colspan=\"2\">&nbsp;</td></tr>");
		table.append("<tr><td colspan=\"2\">&nbsp;</td></tr>");
		table.append("<tr><td style=\"text-align:right\"><label for=\"" + formID +
			"_newPassword\">New password:</label></td><td class=\"passwordInput\"><input type=\"password\" id=\"" +
			formID +
			"_newPassword\" name=\"newPassword\"></td></tr>");
		table.append("<tr><td colspan=\"2\">&nbsp;</td></tr>");
		table.append("<tr><td style=\"text-align:right\"><label for=\"" + formID +
			"_confirmPassword\">Confirm new password:</label></td><td class=\"passwordInput\"><input type=\"password\" id=\"" +
			formID + "_confirmPassword\" name=\"confirmPassword\"></td></tr>");
		table.append("<tr><td colspan=\"2\" class=\"errorMessage\"></td></tr>");
		form.append(table);

		$(container).append(form);

	},

	submitChangePasswordForm: function(formID, testPasswordStrength) {
		var oldPasswordHash;
		var newPasswordHash;

		$("#" + formID + " .error").removeClass('error');
		$("#" + formID + " .errorMessage").empty();

		var oldPassword = $("#" + formID + "_oldPassword").val();
		var newPassword = $("#" + formID + "_newPassword").val();
		var confirmPassword = $("#" + formID + "_confirmPassword").val();

		if (newPassword != confirmPassword) {
			$("#" + formID + " .errorMessage").html(
				"Could not change password:<br/>The passwords do not match");
			$("label[for=" + formID + "_newPassword]").addClass('error');
			$("label[for=" + formID + "_confirmPassword]").addClass('error');
			return false;
		}

		var message = testPasswordStrength(newPassword);

		if (message !== true) {
			$("#" + formID + " .errorMessage").html("Could not change password:<br/>" + message);
			$("label[for=" + formID + "_newPassword]").addClass('error');
			$("label[for=" + formID + "_confirmPassword]").addClass('error');
			return false;
		}

		//get the challenge for the user
		$('#layoutContainer').data('disableWaiting', true);
		$.ajax({
			type: 'POST',
			url: UserManager.ajaxPage,
			global: false,
			data: "action=getChallenges",
			error: function (xReq, textStatus, errorThrown) {
				$('#layoutContainer').data('disableWaiting', '');
				$("#" + formID + " .errorMessage").html("Could not change password:<br/>" + xReq.responseText);
				return false;
			},
			success: function (data, textStatus) {
				$('#layoutContainer').data('disableWaiting', '');
				var challenge = $(data).find("results").find("challenge").text(),
					challengeNext = $(data).find("results").find("nextChallenge").text(),
					oldPasswordHash = hex_hmac_sha1(oldPassword, challenge),
					newPasswordHash = Crypto.PBKDF2(hex_hmac_sha1(newPassword, challengeNext), challengeNext,
						20, {
							iterations: 1000
						});
				$.ajax({
					type: 'POST',
					global: false,
					url: UserManager.ajaxPage,
					data: "action=updatePassword&oldPasswordHash=" + oldPasswordHash + "&newPasswordHash=" +
						newPasswordHash,
					error: function (xReq, textStatus, errorThrown) {
						$("#" + formID + " .errorMessage").html("Could not change password:<br/>" + xReq
							.responseText);
						$("label[for=" + formID + "_oldPassword]").addClass('error');
					},
					success: function (data, textStatus) {
						$("#changePasswordDiaog").dialog('close');
						dialogs.showAlert("Your password has been updated!");
					},
					dataType: 'xml'
				});

			},
			dataType: 'xml'
		});
	},

	renderChangePasswordDialog: function(formID, dialogId, className, action, container, testPasswordStrength) {
		var um = this;
		var div = $("<div class=\"shadowBox\" id=\"" + dialogId +
			"\" title=\"Change Password\"></div>");

		um.renderChangePasswordForm(formID, className, action, div);
		$('body').append(div);
		//container.append("<a style=\"cursor:pointer\" id=\"btnChangePass\" >Change Password</a>");
		div.dialog({
			bgiframe: true,
			autoOpen: false,
			modal: true,
			width: 450,
			resizable: false,
			buttons: {
				'Change Password': function () {
					$.when(um.submitChangePasswordForm(formID, testPasswordStrength)).done(function() {
						dialogs.closeDialog(div);
					});

				},
				'Cancel': function () {
					$(this).dialog('close');

				}
			},
			open: function () {
				//$(this).empty();

				$("#" + formID + "_oldPassword").val("");
				$("#" + formID + "_newPassword").val("");
				$("#" + formID + "_confirmPassword").val("");
				$("#" + formID + " .error").removeClass('error');
				$("#" + formID + " .errorMessage").empty();

			}
		});

		container.click(function () {
			dialogs.openDialog(div);
		});

	},

	renderForgotPasswordDialog: function(dialogId, container, usernamePrompt, callback) {
		var um = this;
		var div = $("<div class=\"shadowBox\" id=\"" + dialogId +
			"\" title=\"Reset Password\"></div>");
		div.append($("<p>").text(usernamePrompt));
		div.append($('<form/>')
			.append($("<div>").addClass('emailInput')
				.append($("<input>")
					.attr('type', 'email')
					.prop('required', true)
					.attr('id', dialogId + "_username")
					.on("input", function () {
						if (this.validity.typeMismatch) {
							this.setCustomValidity("'" + $(this).val() + "' is not a valid email address.");
						} else if (this.validity.valueMissing) {
							this.setCustomValidity("You must enter an email address.");
						} else {
							this.setCustomValidity("");
						}
					})
				)
			)
		);

		$('body').append(div);
		//container.append("<a style=\"cursor:pointer\" id=\"btnChangePass\" >Change Password</a>");
		$("#" + dialogId).dialog({
			bgiframe: true,
			autoOpen: false,
			modal: true,
			width: '325px',
			resizable: false,
			buttons: {
				'Reset Password': function () {

					var userName = $("#" + dialogId + "_username").val(),
						userNameEl = $("#" + dialogId + "_username")[0],
						form = $("#" + dialogId + ' form');

					if (!userNameEl.checkValidity()) {
						if (typeof (form[0].reportValidity) == 'function') {
							form[0].reportValidity();
						} else {
							var fakeSubmit = $('<button/>');
							form.append(fakeSubmit);
							fakeSubmit.trigger('click');
							fakeSubmit.remove();
						}
						return;
					}

					$.ajax({
						url: UserManager.ajaxPage,
						data: {
							action: 'forgotPassword',
							username: userName
						},
						success: function () {
							$("#" + dialogId).dialog('close');
							callback(userName);
						}
					});
				},
				'Cancel': function () {
					//clear the form values
					$(this).dialog('close');

				}
			},
			open: function () {
				$("#" + dialogId + "_username").val("");
				if ($("#frmLogin_userName").length) {
					$("#" + dialogId + "_username").val($("#frmLogin_userName").val());
				}
				$("#" + dialogId + "_username").trigger('input');
			}
		});

		container.on('click', function () {
			dialogs.openDialog($("#" + dialogId));
		});

	},

	submitForgotPasswordForm: function(hash, username, newPassword, errorContainer) {
		let dfd = new $.Deferred();
		//get the challenge for the user
		$.ajax({

			url: UserManager.ajaxPage,
			global: false,
			data: {
				action: "getChallenges",
				userName: username
			},
			error: function (xReq, textStatus, errorThrown) {
				dialogs.showAlert(xReq.responseText);
				return false;
			},
			success: function (data, textStatus) {

				var challenge = $(data).find("results").find("challenge").text(),
					challengeNext = $(data).find("results").find("nextChallenge").text(),
					newPasswordHash = Crypto.PBKDF2(hex_hmac_sha1(newPassword, challengeNext), challengeNext,
						20, {
							iterations: 1000
						});

				$.ajax({

					url: UserManager.ajaxPage,
					global: false,
					data: {
						action: 'updatePasswordforgot',
						newPasswordHash: newPasswordHash,
						username: username,
						hash: hash
					},
					error: function (jqXHR, textStatus, errorThrown) {
						if (errorContainer) {
							errorContainer.text(jqXHR.responseText);
							dfd.reject();
						} else {
							dialogs.showAlert({ 
								title: 'Error Resetting Password',
								message: jqXHR.responseText, 
								buttonClickHandler: function() { dfd.reject()}
							});
						}
					},
					success: function (data, textStatus) {
						dialogs.showAlert({ 
							title: 'Password Reset',
							message: "Your password has been reset.", 
							buttonClickHandler: function() { dfd.resolve()}
						});
						

					},
					dataType: 'xml'
				});

			},
			dataType: 'xml'
		});
		return dfd.promise();
	},

	renderLogOutForm: function(formID, className, action, container) {
		var form = $("<form></form>");
		form.attr('id', formID);
		form.attr('class', className);
		form.attr('action', action);
		form.attr('method', 'post');
		form.append("<input type=\"hidden\" name=\"LoginAction\" value=\"logout\" />");
		form.attr('height', '0px');
		//input type=\"submit\" value=\"Log Out\" />");
		container.append(form);
		//container.append("<a id=\"btnLogout\" style=\"cursor:pointer\" onclick=\"document.forms['" +formID +"'].submit()\" >Sign Out</a>");
		container.append($('<a/>')
			.attr('id', "btnLogout")
			.addClass('button')
			.css('cursor', 'ponter')
			.text('Sign Out')
			.click(function () {
				document.forms[formID].submit();
				return false;
			})
		);
	},

	renderTimeout: function(length) {

		/*return setTimeout(function(){
			
			alert("You have been automatically logged out following a period of inactivity.");
			$("#btnLogout").trigger('click');
			
		}, length); */

	},

}
