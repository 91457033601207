require("jquery-ui/ui/widget");
(function ($, window, document, undefined) {

	$.widget("badmath.scroller", {

		_container: null,
		_scroller: null,
		_scrollerContainer: null,
		_innerContainer: null,
		_scrollerWidth: 0,
		_containerWidth: 0,
		_rightArrow: 0,
		_leftArrow: 0,
		_resizeTimeout: null,
		_touching: false,
		_touchStartTime: 0,
		_touchStartLeft: 0,
		_toucStartScrollPos: 0,

		options: {
			animationSpeed: 500,
			prevArrow: '<button class="slick-arrow slick-prev"></button>',
			nextArrow: '<button class="slick-arrow slick-next"></button>',
			resizeTimeout: 300,
			buttonMargin: 50,
			useTransition: true,
			swipeThreshold: 15,
			swipeDuration: 500
		},

		//helpers
		_log: function () {
			if (this.options.debug)
				console.log(arguments);
		},

		_scrollPage: function (dir) {
			let plugin = this,
				scrollDistance = plugin._innerContainer.innerWidth();
			const leftPos = parseInt(plugin._scroller.css('margin-left'));

			//plugin.handleResize();
			if (dir == 'right') {
				scrollDistance -= 2 * plugin.options.buttonMargin;
				if (-leftPos + scrollDistance > plugin._scrollerWidth - plugin._containerWidth) {
					scrollDistance = plugin._scrollerWidth - plugin._containerWidth + leftPos;
				}

			} else {
				scrollDistance += 2 * plugin.options.buttonMargin;
				if (leftPos + scrollDistance > 0) {
					scrollDistance = -leftPos;
				}
			}

			if (plugin.options.useTransition) {
				plugin._scroller
					.one('transitionend', function () {
						plugin._setButtonVisibility();
					})
					.css('margin-left', parseInt(plugin._scroller.css('margin-left')) + (dir == 'left' ?
						scrollDistance : -scrollDistance));

			} else {
				plugin._scroller.animate({
					marginLeft: (dir == 'left' ? "+=" : "-=") + (scrollDistance)
				}, {
					duration: plugin.options.animationSpeed,
					complete: function () {
						plugin._setButtonVisibility();
					}
				});
			}

		},

		_setButtonVisibility: function () {
			let plugin = this;
			//return;
			const leftPos = parseInt(plugin._scroller.css('margin-left'));

			if (leftPos >= 0) {
				plugin._leftArrow.toggleClass('hidden', true);
			} else {
				plugin._leftArrow.toggleClass('hidden', false);
				// plugin._innerContainer.css('padding-left', plugin.options.buttonMargin)
			}
			//console.log(plugin._container.width(), -leftPos + plugin._containerWidth, Math.floor(plugin._scrollerWidth));
			if (Math.ceil(-leftPos + plugin._containerWidth) < Math.floor(plugin._scrollerWidth)) {
				plugin._rightArrow.toggleClass('hidden', false);
				// plugin._innerContainer.css('padding-right', plugin.options.buttonMargin)
			} else {
				plugin._rightArrow.toggleClass('hidden', true);
			}

		},

		handleResize: function () {
			let plugin = this;
			plugin._containerWidth = plugin._container.width();
			//plugin._scrollerContainer.css('width', plugin._containerWidth + 'px');
			plugin._scrollerWidth = plugin._scroller.outerWidth();
			//console.log(`container: ${plugin._containerWidth}, scroller ${plugin._scrollerWidth}`);
			plugin._setButtonVisibility();
		},

		_setup: function () {
			let plugin = this;

			plugin._container = plugin.element;

			plugin._container.wrapInner($('<div/>').addClass('scroller-container')); //.css('width', plugin.element.width()));
			plugin._scrollerContainer = plugin._container.children('.scroller-container');
			plugin._scrollerContainer.wrapInner($('<div/>').addClass('inner-container'));
			plugin._innerContainer = plugin._container.find('.inner-container');

			plugin._innerContainer.wrapInner($('<div/>').addClass('scroller'));
			plugin._scroller = plugin.element.find('.scroller');

			plugin._leftArrow = $(plugin.options.prevArrow).on('click', function () {
				plugin._scrollPage('left');
			});
			plugin._rightArrow = $(plugin.options.nextArrow).on('click', function () {
				plugin._scrollPage('right');
			});
			plugin.element.prepend(plugin._leftArrow).append(plugin._rightArrow);
			plugin.handleResize();

			$(window).on('resize.scroller', function () {
				clearTimeout(plugin._resizeTimeout);
				plugin._resizeTimeout = setTimeout(function () {
					plugin.handleResize();
				}, plugin.options.resizeTimeout);
			});

			plugin._container.on({
				'touchstart.scroller mousedown.scroller': function (e) {
					plugin._touching = true;
					if (e.changedTouches) {
						plugin._touchStartLeft = e.changedTouches[0].screenX;
					} else {
						plugin._touchStartLeft = e.screenX;
					}
					plugin._toucStartScrollPos = parseInt(plugin._scroller.css('margin-left'));
					plugin._scroller.addClass('notransistion');
					plugin._touchStartTime = Date.now();
					//console.log(`touchstart: ${plugin._touchStartLeft}`);
				},
				'touchmove.scroller mousemove.scroller': function (e) {
					if (plugin._touching) {
						var newPos = 0;
						if (e.changedTouches) {
							newPos = e.changedTouches[0].screenX;
						} else {
							newPos = e.screenX;
						}
						var newLeft = Math.max(-plugin._scrollerWidth + plugin._containerWidth,
							Math.min(0, (plugin._toucStartScrollPos + (newPos - plugin._touchStartLeft)))
						);
						plugin._scroller.css('margin-left', newLeft + 'px');
						e.preventDefault();
					}
				},

			});
			$(document).on('touchend.scroller mouseup.scroller touchcancel.scroller', function (e) {
				if (plugin._touching) {
					if (Date.now() - plugin._touchStartTime < plugin.options.swipeDuration) {
						var pos = e.changedTouches ? e.changedTouches[0].screenX : e.screenX;
						//console.log(`touchend: ${pos}`);
						if (pos <= plugin._touchStartLeft - plugin.options.swipeThreshold) {
							plugin._scrollPage('right');
						} else if (pos >= plugin._touchStartLeft + plugin.options.swipeThreshold) {
							plugin._scrollPage('left');
						}
					}
					plugin._touching = 0;
					plugin._touchStartLeft = 0;
					plugin._scroller.removeClass('notransistion');
				}
			});

			plugin._container.one('transitionend', function () {
				plugin.handleResize();
			});
			plugin._container.addClass('scroller-initiailzed');
		},

		_create: function () {

			this._setup();
			// this._initEvents();

		},

		// Destroy an instantiated plugin and clean up
		// modifications the widget has made to the DOM
		_destroy: function () {
			let plugin = this;
			plugin._scroller.contents().unwrap();
			plugin._innerContainer.contents().unwrap();
			plugin._scrollerContainer.contents().unwrap();
			plugin._container.off('.scroller').removeClass('scroller-initiailzed');
			//plugin._scroller.remove();

			$(window).off('resize.scroller');
		},

		// Respond to any changes the user makes to the
		// option method
		_setOption: function (key, value) {
			//store the option
			this._super("_setOption", key, value);
		}

	});

})(jQuery, window, document);
