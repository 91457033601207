export const browserCheck = {

    isAndroid: function() {
        var ua = navigator.userAgent.toLowerCase();
        return ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
    },

    isAndroidFirefox: function() {
        var ua = navigator.userAgent.toLowerCase();
        return this.isAndroid() && ua.indexOf("firefox") > -1;
    },

    isIOSHandHeld: function() {
        return /(iPhone|iPod)/gi.test(navigator.userAgent);
    },

    isIOS: function() {

        return /(iPhone|iPod|iPad)/gi.test(navigator.userAgent);
    },

    getIOSVersion: function() {
        if (!this.isIOS()) {
            return false;
        }
        var agent = navigator.userAgent,
            version = agent.match(/OS\s(\d+)_(\d+)/i);
        return Number(version[1]);
    },

    isSafari: function() {
        return /safari/gi.test(navigator.userAgent) && !/Chrome/gi.test(navigator.userAgent);
    },

    isIE: function() {
        return /(Trident)/gi.test(navigator.userAgent);
    },

};
