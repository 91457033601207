const Cookies = require('js-cookie');
import {dialogs} from './dialogs.js';
import {fieldDisplay} from './fieldDisplay.js';
import './jquery.waitingBox.js';

import { load } from 'recaptcha-v3';

var currentProjectId = '',
	initialized = false;

function displayErrors(dialog, errors, errorListContainer) {
	errorListContainer = errorListContainer !== undefined ? errorListContainer :dialog.find('.error ul');
	if (typeof(errors) == 'string') {
		errors = [errors];
	}
	errors.forEach((message) => {
		errorListContainer.append($('<li>').html(message))
	});
	let sendButton = $('.ui-dialog-buttonpane button:first', dialog.closest('.ui-dialog'));
	errorListContainer.parent().show();
	dialogs.adjustDialogPosition(dialog.parents(".ui-dialog"));
	dialog[0].scrollTop = dialog[0].scrollHeight;
		
	sendButton.removeClass('ui-state-disabled');
	sendButton.prop('disabled', false);

}

function validateMailFormFields() {
	var hasError = false,
		errors = [],
		dialog = $( $('#mailDialog'));
	$("div.error ul",dialog).empty();
	$("div.error", dialog).hide();
	$("label.error, h3.error").removeClass('error');

	if (!$("#txtFromEmail").val().length) {
		errors.push("Please enter your email address.");
		$("label[for=txtFromEmail]").addClass('error');
		hasError = true;
	}
	if (!$("#txtName").val().length) {
		errors.push("Please enter your name.");
		$("label[for=txtName]").addClass('error');
		hasError = true;
	}

	if (!$("#txtToEmail").val().length) {
		errors.push("Please enter the recipient's email address.");
		$("label[for=txtToEmail]").addClass('error');
		hasError = true;
	}
	if (hasError) {
		displayErrors(dialog, errors);
		dialogs.adjustDialogPosition($("#mailDialog").parents(".ui-dialog"));
	}

	return !hasError;
}

function validateNewRequestFormFields(order) {
	var errorMessages = [];
	$("#projectInfo .errorMessage ul").empty();
	$("#projectInfo .errorMessage").hide();
	$("#projectInfo .error").removeClass('error');

	$("#projectInfo .requiredSelect select").each (function() {
		let el = $(this),
			value = el.val(),
			fieldName = el.attr('title');
		if (order && el.closest('.required').hasClass('notForOrder')) {
			return true;
		}

		if (value == -1) {
			el.closest(".requiredSelect").addClass('error');
			errorMessages.push("The " + fieldName.toLowerCase() + " is required");
		} else if (!value && el.closest('.required').find('.other textarea').val() == '') {
			el.closest(".requiredSelect").addClass('error');
			errorMessages.push("The " + fieldName.toLowerCase() + " is required");
		}
	});

	$("#projectInfo .required input:not(.notrequired)" +
		",#projectInfo .required textarea:not(.notrequired)" +
		",#requestInfo .required input:not(.notrequired)" +
		",#requestInfo .required textarea:not(.notrequired)").each(function () {
		let el = $(this),
			value = el.val(),
			fieldName = el.attr('title');
		if ((order && el.closest('.required').hasClass('notForOrder')) || el.closest('.required').hasClass('requiredSelect')) {
			return true;
		}

		if (!value) {
			el.closest(".required").addClass('error');
			errorMessages.push("The " + fieldName.toLowerCase() + " is required");
		}
	});

	if (errorMessages.length) {
		$("#requestDialog").find("#projectInfo .errorMessage").show();
		displayErrors($("#requestDialog"), errorMessages, $("#requestDialog").find("#projectInfo .errorMessage ul"));
		return false;
	}
	return true;
}


function validateExistingRequestFormFields() {
	$("#projectInfo .errorMessage ul").empty();
	$("#projectInfo .errorMessage").hide();
	$("#requestDialog .error").removeClass('error');
	var errorMessages = [];

	$("label.error").removeClass('error');
	if ($("#requestJob").val() === '') {
		errorMessages.push("Please select a job.");
		$("#requestJob").parents('tr').addClass('error');
		return;
	}

	$("#existingProject .required input:not(.notrequired)" +
		",#existingProject .required textarea:not(.notrequired)" +
		",#requestInfo .required input:not(.notrequired)" +
		",#requestInfo .required textarea:not(.notrequired)").each(function () {
		if ($(this).val() === '') {
			$(this).closest(".required").addClass('error');
			var fieldName = $(this).attr('title');
			errorMessages.push("The " + fieldName.toLowerCase() + " is required");
		}
	});

	if (errorMessages.length) {
		$("#requestDialog").find("#projectInfo .errorMessage").show();
		displayErrors($("#requestDialog"), errorMessages, $("#projectInfo .errorMessage ul"))
		return false;
	}
	return true;
}

function sendMail(data, type) {
	let dfd = new $.Deferred(),
		dialog = $("#mailDialog"),
		sendButton = $('.ui-dialog-buttonpane button:first', dialog.closest('.ui-dialog'));
	sendButton.addClass('ui-state-disabled');
	sendButton.prop('disabled', true);
	
	if (validateMailFormFields()) {
		$.ajax({
			data: data,
			global: false,
			/*don't use global error handler */
			success: function (responseData) {
				if (responseData == "Success") {
					$('#mailDialog').dialog('close');
					dialogs.showAlert({
						message: 'A copy of your ' + type + ' has been sent to "' + data.toEmail + '".',
						title: "Email Sent"
					});
					Cookies.set('fromEmail', data.fromEmail, {
						path: HF_GLOBALS.COOKIE_PATH
					});
					Cookies.set('emailName', data.fromName, {
						path: HF_GLOBALS.COOKIE_PATH
					});
					dfd.resolve();
				} else {
					
					displayErrors(dialog, 'There was an unknown error sending your email.');
					dfd.reject();
				}
			},
			error: function (jqXHR, textStatus, errorThrown) {
				var message = fieldDisplay.nl2br(jqXHR.responseText).replace('!CONTACT!', HF_GLOBALS.contactMarkup);
				displayErrors(dialog, message);
				
				dfd.reject();
			}
		});
		//re-enable the button on validation error.
	} else {
		sendButton.removeClass('ui-state-disabled');
		sendButton.prop('disabled', false);
		dfd.reject();
	}
	return dfd.promise();

}

function getAddressFields() {
	var addressFields = {};

	if (!$("#mailingAddressId").val()) {
		$(".mailing_field").each(function () {
			addressFields[$(this).attr('id')] = !$(this).val() ? '' : $(this).val();
		});

	} else {
		addressFields.mailingAddressId = $("#mailingAddressId").val();
	}

	if (!$("#billingAddressId").val()) {
		$(".billing_field").each(function () {
			addressFields[$(this).attr('id')] = !$(this).val() ? '' : $(this).val();
		});

	} else {
		addressFields.billingAddressId = $("#billingAddressId").val();
	}
	addressFields.sameAddress = $("#sameAddressContactForm").is(':checked') ? '1' : '0';
	return addressFields;
};

function contactResearcherExistingProject(projectId, data) {
	var dialog = $("#requestDialog"),
		dialogContainer = dialog.closest('.ui-dialog'),
		sendButton = $('.ui-dialog-buttonpane button:first', dialogContainer);
	sendButton.addClass('ui-state-disabled');
	sendButton.prop('disabled', true);
	dialogContainer.waitingBox();
	if (validateExistingRequestFormFields()) {
		var addressFields = getAddressFields();
		
		$.ajax({
			global: false,
			data: $.extend({
				action: 'createRequest',
				type: 'existing',
				jobId: $("#requestJob").val(),
				researcherName: $("#researcherName").val(),
				message: $("#projectMessage").val(),
				title: $("#projectName").val(),
				projectId: !projectId ? '' : projectId,
				projectDescription: $("#projectDescription").val(),
				fedexNumber: $("#fedexNumber").val(),
				rightsId: $("#rightsSelect").val() == -1 ? '' : $("#rightsSelect").val(),
				rights: $("#rights").val()

			}, addressFields, data),
			success: function (data) {

				$('#requestDialog').dialog('close');
				dialogs.showAlert({
					message: "Thank you!  Your contact request was sent, and your Historic Films representative will be following up with you.",
					title: "Request Sent"
				});

			},
			error: function (jqXHR, textStatus, errorThrown) {
				var message = fieldDisplay.nl2br(jqXHR.responseText).replace('!CONTACT!', HF_GLOBALS.contactMarkup);
				dialog.find("#projectInfo .errorMessage").show();
				displayErrors(dialog, message, dialog.find("#projectInfo .errorMessage ul"));
			}
		}).always(function() {
			dialogContainer.trigger('stopWaiting');
		});
	} else {
		sendButton.removeClass('ui-state-disabled');
		sendButton.prop('disabled', false);
		dialogContainer.trigger('stopWaiting');
	}
}

function contactResearcherNewProject(data, order) {
	var dialog =  $('#requestDialog'),
		dialogContainer =  dialog.closest('.ui-dialog'),
		sendButton = $('.ui-dialog-buttonpane button:first', dialogContainer);
	sendButton.addClass('ui-state-disabled');
	sendButton.prop('disabled', true);
	dialogContainer.waitingBox();
	if (validateNewRequestFormFields(order)) {
		var addressFields = getAddressFields();
		$.ajax({
			global: false,
			data: $.extend({
				action: 'createRequest',
				type: 'new',
				title: $("#projectName").val(),
				message: $("#projectMessage").val(),
				projectDescription: $("#projectDescription").val(),
				fedexNumber: $("#fedexNumber").val(),
				rightsId: $("#rightsSelect").val() == -1 ? '' : $("#rightsSelect").val(),
				rights: $("#rights").val(),
				researcherId: $("#researcherSelect").val()
			}, data, addressFields),
			success: function (data) {

				dialog.dialog('close');
				dialogs.showAlert({
					message: "Thank you!  Your contact request was sent, and an Historic Films representative will be following up with you.",
					title: "Request Sent"
				});

			},
			error: function (jqXHR, textStatus, errorThrown) {

				var message = fieldDisplay.nl2br(jqXHR.responseText).replace('!CONTACT!', HF_GLOBALS.contactMarkup);
				dialog.find("#projectInfo .errorMessage").show();
				displayErrors( dialog, message, dialog.find("#projectInfo .errorMessage ul"));
				
			}
		}).always(function() {
			dialogContainer.trigger('stopWaiting');
		});
	} else {
		sendButton.removeClass('ui-state-disabled');
		sendButton.prop('disabled', false);
		dialogContainer.trigger('stopWaiting');
	}
}

function contactResearcher(data) {

	$("#requestDialog .error").removeClass('error');
	$("#projectInfo .errorMessage").hide();
	if ($(":radio[name=existingProject]:checked").val() == 1) {
		contactResearcherExistingProject(currentProjectId, data);
	} else if (data.orderId) {
		contactResearcherNewProject(data, true);
	} else {
		contactResearcherNewProject(data, false);
	}
}

function openRequestDialog() {
	if (!HF_AUTH.isLoggedIn) {
		dialogs.showAlert({
			message: 'Please <a class="signInLink">sign in</a> or <a href="signup">create an account</a> to contact a researcher.',
			title: "Contact a Researcher"
		});
	} else {
		dialogs.openDialog($('#requestDialog'));
		$(".ui-dialog-buttonpane button:contains('Send Request')", $("#requestDialog").parent())
			.prop('disabled', true)
			.addClass('ui-state-disabled');
	}
}

function showOrderProjectInfo(order) {
	$(".fedexNumber").hide();
	$("#projectDVD").removeAttr('checked');
	$("#projectDate").val('');
	$(".notForOrder").hide().removeClass('required');

	$(".existingProjectHeader").show();
	$(".newProjectHeader").hide();
	$("#rightsSelect").val(order.rightsSelect);
	if (order.rights) {
		$('.other.rights').show();
		$('#rights').val(order.rights);
	} else {
		$('.other.rights').hide();
		$('#rights').val('');
	}

	if (order.fedexNumber) {
		$("#fedexNumber").val(order.fedexNumber);
	} else {
		$("#fedexNumber").val('');
	}

	$("#billingAddressId").val(order.billingAddressId);
	if (order.billingAddressId) {
		showAddress('billing', order.billingAddress);
	} else {
		//clearAddress('billing');
	}
	$("#mailingAddressId").val(order.mailingAddressId);
	if (order.mailingAddressId) {
		showAddress('mailing', order.mailingAddress);
	} else {
		//clearAddress('mailing');
	}
	if (order.mailingAddressId != order.billingAddressId) {
		$("#sameAddressContactForm").removeAttr('checked');
	} else {
		$("#sameAddressContactForm").attr('checked', 'checked');
	}
	$("#projectInfo").show();
}

function showProjectInfo(requestProject) {
	currentProjectId = requestProject.id;
	$(".fedexNumber").hide();
	$("#projectDescription").val(requestProject.projectDescription);
	
	if (requestProject.fedexNumber) {
		$("#fedexNumber").val(requestProject.fedexNumber);
	} else {
		$("#fedexNumber").val('');
	}
	
	$("#rightsSelect").val(requestProject.rightsSelect);
	$("#rights").val(requestProject.rights);
	if (!requestProject.rightsSelect) {
		$(".rights.other").show();
	} else {
		$(".rights.other").hide();
	}

	
	$("#billingAddressId").val(requestProject.billingAddressId);
	if (requestProject.billingAddressId) {
		showAddress('billing', requestProject.billingAddress);
	} else {
		clearAddress('billing');
	}
	$("#mailingAddressId").val(requestProject.mailingAddressId);
	if (requestProject.mailingAddressId) {
		showAddress('mailing', requestProject.mailingAddress);
	} else {
		clearAddress('mailing');
	}
	if (requestProject.mailingAddressId != requestProject.billingAddressId) {
		$("#sameAddressContactForm").prop('checked', false);
		$("div.mailingAddress").show();
	} else {
		$("#sameAddressContactForm").prop('checked', true);
		$("div.mailingAddress").hide();
	}
	$("#projectInfo").show();
	dialogs.adjustDialogPosition($("#requestDialog").parents(".ui-dialog"));
}

function clearProjectInfo() {
	currentProjectId = null;
	$(".notForOrder").show();
	$("#projectDescription").val('');
	$("#projectMasterFormat").val("Digital file for Download");
	$(".other").hide();
	$("#projectMasterFormatOther").val('');
	$("#rightsSelect").val('-1');
	$("#rights").val('');
	$(".fedexNumber").hide();
	$("#fedexNumber").val('');
	$("#projectDate").val('');
	//clearAddress('billing');
	//clearAddress('mailing');
	$("#sameAddressContactForm").attr('checked', 'checked');
	$("#projectInfo").show();
}

function showAddress(prefix, address) {
	$("#" + prefix + "_streetAddress").val(address.streetAddress);
	$("#" + prefix + "_city").val(address.city);
	$("#" + prefix + "_countryId").val(address.countryId);
	$("#" + prefix + "_regionId").empty();
	$.each(address.country.regions, function (index, value) {
		$("#" + prefix + "_regionId").append($("<option>").attr('value', value.id).text(value.name));
	});
	$("#" + prefix + "_zipcode").val(address.zipcode);
}

function clearAddress(prefix) {
	$("#" + prefix + "_streetAddress").val('');
	$("#" + prefix + "_city").val('');
	$("#" + prefix + "_countryId").val('');
	$("#" + prefix + "_regionId").empty();
	$("#" + prefix + "_zipcode").val('');
}

function setAddressesToDefault() {

	function fillIn(prefix) {
		if (HF_AUTH.userAddress[prefix]) {
			$("#" + prefix + "_name").val(HF_AUTH.userAddress[prefix].name);
			$("#" + prefix + "_streetAddress").val(HF_AUTH.userAddress[prefix].streetAddress);
			$("#" + prefix + "_city").val(HF_AUTH.userAddress[prefix].city);
			$("#" + prefix + "_countryId").val(HF_AUTH.userAddress[prefix].countryId);
			$("#" + prefix + "_regionId").val(HF_AUTH.userAddress[prefix].regionId);
			$("#" + prefix + "_zipcode").val(HF_AUTH.userAddress[prefix].zipcode);
		}
	}

	fillIn('billing');
	fillIn('mailing');
	if (HF_AUTH.userAddress.mailing !== null && HF_AUTH.userAddress.mailing.addressId == HF_AUTH.userAddress.billing.addressId) {
		$("#sameAddressContactForm").prop('checked', true);
		$("div.mailingAddress").hide();
	}
}

function showMailBinDialog (bin, binType) {
	
	if (binType === undefined) {
		binType = "Bin";
	}

	$('#mailDialog').dialog('option', 'title', 'Email Bin');
	$('#mailDialog .email').show();
	$("#mailDialog p .type").text('bin');
	$("#mailDialog .emailResearcher").unbind().click(function () {

		$("#requestDialog #projectMessage").val($("#emailMessage").val());
		$("#mailDialog").dialog('close');
		contactForm.showContactResearcherBinDialog(bin.id);
	});

	$('#mailDialog').dialog('option', 'buttons', {
		"Send Mail": function () {
			let container = $('#mailDialog').closest('.ui-dialog');
			container.waitingBox();
			load(HF_GLOBALS.recaptchaSiteKey).then((recaptcha) => {
			
				try {
					recaptcha.execute('submit').then((token) => {
						$.when(sendMail({
							action: 'mailBin',
							binId: bin.id,
							binType: binType,
							toEmail: $("#txtToEmail").val(),
							fromEmail: $("#txtFromEmail").val(),
							fromName: $("#txtName").val(),
							message: $("#emailMessage").val(),
							captchaResponse: token
						}, 'bin')).done(function() {
							var events = [];
							
							$.each(bin.clips, function (clip) {
								events.push([
									"Share Clip", {
										collection: clip.specialtyCollection ? 'Specialty Collection' : 'Standard Collection',
										reelId: clip.tapeId
									}
								]);
							});

							trackEvents(events);
							
						}).always(function() {
							container.trigger('stopWaiting');
						});
					});
				} catch (ex) {
					container.trigger('stopWaiting');
				}
			});

		},
		"Cancel": function () {
			$(this).dialog('close');
		}
	});
	dialogs.openDialog($('#mailDialog'));

};

function showMailClipDialog (clipInfo) {
	//const {reelId, clip, inOffset, outOffset, title, tapeId, specialtyCollection} = clipInfo;
	$('#mailDialog').dialog('option', 'title', 'Mail Clip');
	$('#mailDialog .email').show();
	$("#mailDialog p .type").text('clip');
	$("#mailDialog .emailResearcher").off().click(function () {
		let clipInfo = null;
		if (window.videoWindow && window.videoWindow.hasClass('initialized')) {
			clipInfo = window.videoWindow.videoWindow('getClipInfo');
		}
		$("#requestDialog #projectMessage").val($("#emailMessage").val());

		contactForm.showContactResearcherClipDialog(clipInfo);
		$("#mailDialog").dialog('close');
	});

	$('#mailDialog').dialog('option', 'buttons', {
		"Send Mail": function () {

			load(HF_GLOBALS.recaptchaSiteKey).then((recaptcha) => {
				try {
					recaptcha.execute('submit').then((token) => {
							var sendButton = $('.ui-dialog-buttonpane button:first', $('#mailDialog').closest(
							'.ui-dialog'));
						sendButton.addClass('ui-state-disabled');
						sendButton.prop('disabled', true);
						
						$.when(sendMail({
							action: 'mailClip',
							tapeId: clipInfo.reelId,
							clipId: clipInfo.clip ? clipInfo.clip.id : '',
							clipType: clipInfo.clip ? clipInfo.clip.clipType : '',
							offsetIn: clipInfo.inOffset,
							offsetOut: clipInfo.outOffset,
							title: clipInfo.title,
							toEmail: $("#txtToEmail").val(),
							fromEmail: $("#txtFromEmail").val(),
							fromName: $("#txtName").val(),
							message: $("#emailMessage").val(),
							captchaResponse: token
						}, 'clip')).done(function() {
							trackEvent("Share Clip", {
								collection: clipInfo.specialtyCollection ? 'Specialty Collection' : 'Standard Collection',
								reelId: clipInfo.tapeId
							});
						})
					});
				} catch (ex) {
					
				}
			});

		},
		"Cancel": function () {
			$(this).dialog('close');
		}
	});
	dialogs.openDialog($('#mailDialog'));
};

function showContactResearcherClipDialog (clipInfo) {

	if (!clipInfo) {
		dialogs.showAlert({
			message: "An error occured while retrieving the clip information. Please try again later.",
			title: "Error"
		});
	}
	const {
		reelId, clip, inOffset, outOffset, title, tapeId, specialtyCollection
	} = clipInfo;

	$('#requestDialog').dialog('option', 'buttons', {
		"Send Request": function () {
			trackEvent(
				"Request", {
					collection: specialtyCollection ? 'Specialty Collection' : 'Standard Collection',
					reelId: tapeId
				});
			contactResearcher({
				tapeId: reelId,
				offsetIn: inOffset,
				offsetOut: outOffset,
				clipTitle: title,
				clipId: clip ? clip.id : '',
				clipType: clip ? clip.clipType : ''
			});
		},
		"Cancel": function () {
			$(this).dialog('close');
		}
	});
	$('#contactAttachNotice').text('The clip you selected will be attached to your message.');
	openRequestDialog();
};

function showContactResearcherBinDialog (bin) {
	console.log(bin);
	$('#requestDialog').dialog('option', 'buttons', {
		"Send Request": function () {

			var events = [];
			console.log(bin);
			$.each(bin.clips, function (clip) {
				events.push([
					"Request", {
						collection: clip.specialtyCollection ? 'Specialty Collection' : 'Standard Collection',
						reelId: clip.tapeId
					}
				]);
			});

			trackEvents(events);
			contactResearcher({
				binId: bin.id
			});
		},
		"Cancel": function () {
			$(this).dialog('close');
		}
	});
	$('#contactAttachNotice').text('The bin you selected will be attached to your message.');
	openRequestDialog();
};

function showContactResearcherOrderDialog (orderId) {

	$(".notForOrder").hide();
	
	$.ajax({
		data: {
			action: 'getOrder',
			orderId: orderId
		},
		success: function (data) {
			showOrderProjectInfo($.parseJSON(data));
			$('#requestDialog').dialog('option', 'buttons', {
				"Send Request": function () {

					contactResearcher({
						orderId: orderId
					});
				},
				"Cancel": function () {
					$(this).dialog('close');
				}
			});
			$('#contactAttachNotice').text('The order you selected will be attached to your message.');
			openRequestDialog();
			$(".ui-dialog-buttonpane button:contains('Send Request')")
				.removeAttr('disabled', 'disabled')
				.removeClass('ui-state-disabled');
		}
	});
};

function showContactResearcherDialog () {

	$('#requestDialog').dialog('option', 'buttons', {
		"Send Request": function () {
			contactResearcher({});
		},
		"Cancel": function () {
			$(this).dialog('close');
		}
	});
	$('#contactAttachNotice').html(
		'Note! If you would like to discuss specific footage you see on the site, close this window and use the <button class="exampleButton contactAResearcher" tabindex="0">Contact a researcher</button> buttons you see in the context of clips or bins instead.  Those clips or bins will then be attached to your request.'
	);

	openRequestDialog();

};

function init () {
	dialogs.importDialogWidget().then(() => {
		$("#mailDialog").dialog({
			autoOpen: false,
			modal: true,
			resizable: false,
			width: 481,

			open: function () {
				if (window.videoWindow && window.videoWindow.hasClass('initialized')) {
					window.videoWindow.videoWindow('pauseVideo');
				}

				$("div.error ul", $('#mailDialog')).empty();
				$("div.error").hide();
				$("label.error, h3.error").removeClass('error');
				
				$("#txtFromEmail").val(decodeURIComponent(Cookies.get('fromEmail') ?? '' ));
				$("#txtName").val(decodeURIComponent(Cookies.get('emailName') ?? '' ));
				var sendButton = $('.ui-dialog-buttonpane button:first', $('#mailDialog').closest(
					'.ui-dialog'));
				sendButton.removeClass('ui-state-disabled');
				sendButton.prop('disabled', false);

			},
			close: function () {
				$("input,textarea", $(this)).val('');
			}
		});

		$("#requestDialog").dialog({
			autoOpen: false,
			modal: true,
			resizable: false,
			width: 550,

			open: function () {
				if (window.videoWindow && window.videoWindow.hasClass('initialized')) {
					window.videoWindow.videoWindow('pauseVideo');
				}
			},
			close: function () {
				$(".clearable", $(this)).val('');
				$("#requestDialog .error").removeClass('error');
				$("#projectInfo .errorMessage").hide();
				$("#existingProject").hide();
				$("#projectInfo").hide();
				$("tr.researcherName").hide();
				$("#researcherName").val('');
				$("#requestJob").val('');
				setAddressesToDefault();
				clearProjectInfo();
				$(".newProjectHeader").hide();
				$(".existingProjectHeader").hide();
				$("#existingProject").hide();
				$("#projectInfo").hide();
				$("#requestDialog :radio").each(function () {
					this.checked = false;
				});

				$(".ui-dialog-buttonpane button:contains('Send Request')", $("#requestDialog").parent())
					.prop('disabled', true)
					.addClass('ui-state-disabled');

			}
		});


		$(":radio[name=existingProject]").change(function () {

			clearProjectInfo();
			if ($(this).val() == 1) {
				$("#existingProject").show();
				if ($("#requestJob .realopt").length === 0) {
					$("#requestJob").val(-1);
					$("#requestJob").parents('tr').hide();
					$("tr.researcherName").show();
				}

				$(".newProjectHeader").hide();
				$(".existingProjectHeader").show();
				$("#projectInfo").hide();

			} else {
				$(".ui-dialog-buttonpane button:contains('Send Request')")
					.prop('disabled', false)
					.removeClass('ui-state-disabled');
				$("#projectInfo").show();
				$(".newProjectHeader").show();
				$(".existingProjectHeader").hide();
				$("#existingProject").hide();
				$(".projectField").val('');
				$("#requestJob").val('');
				$(".existingProjectHeader .requirementNote").show();
				$("#projectInfo .notRequiredForProject").addClass('required');
				$("#projectInfo .notRequiredForProject").removeClass('notRequiredForProject');
			}
			$("#requestDialog").css('height', '');
			dialogs.adjustDialogPosition($(this).parents(".ui-dialog"));
		});

		$(".billing_field", $("#requestDialog")).change(function () {
			$("#billingAddressId").val('');
		});

		$(".mailing_field", $("#requestDialog")).change(function () {
			$("#mailingAddressId").val('');
		});

		$("#sameAddressContactForm").change(function () {
			if ($(this).is(':checked')) {
				$(".mailingAddress").hide();
				dialogs.adjustDialogPosition($(this).parents(".ui-dialog"));
			} else {
				$(".mailingAddress").show();
				dialogs.adjustDialogPosition($(this).parents(".ui-dialog"));
			}
		});

		$("#requestJob").change(function () {
			if ($(this).val() === '') {

				clearProjectInfo();
				$("#projectInfo").hide();
				$("tr.researcherName").hide();
				$(".ui-dialog-buttonpane button:contains('Send Request')")
					.prop('disabled', true)
					.addClass('ui-state-disabled');
				$("#requestDialog").height($("#requestDialog").height() - $("#projectInfo").height());
				dialogs.adjustDialogPosition($(this).parents(".ui-dialog"));
				return;
			}
			$(".ui-dialog-buttonpane button:contains('Send Request')")
				.prop('disabled', false)
				.removeClass('ui-state-disabled');
			if ($(this).val() == -1) {
				$("tr.researcherName").show();
				$(".existingProjectHeader .requirementNote").show();
				$("#projectInfo .notRequiredForProject").addClass('required');
				$("#projectInfo .notRequiredForProject").removeClass('notRequiredForProject');
				clearProjectInfo();
				$("#projectInfo").show();
				setAddressesToDefault();
				dialogs.adjustDialogPosition($(this).parents(".ui-dialog"));
			} else if ($(this).val()) {
				$.ajax({
					data: {
						action: 'getRequestProject',
						jobId: $(this).val()
					},
					success: function (data) {
						$("tr.researcherName").hide();
						$("#projectInfo").show();
						$(".existingProjectHeader .requirementNote").hide();
						$("#projectInfo .required").addClass('notRequiredForProject');
						$("#projectInfo .required").removeClass('required');

						if (data) {
							showProjectInfo($.parseJSON(data));
						} else {
							clearProjectInfo();
						}
						dialogs.adjustDialogPosition($("#requestDialog").parents(".ui-dialog"));
					}
				});
			}

		});

		$("#rightsSelect").change(function () {
			if ($(this).val() === '') {
				$(".other.rights", $(this).parent()).show();
			} else {
				$(".other.rights", $(this).parent()).hide();
			}
			dialogs.adjustDialogPosition($(this).parents(".ui-dialog"));
		});


		$("#mailing_countryId").change(function () {
			if ($(this).val()) {
				fieldDisplay.getRegions($(this).val(), 'mailing');
			}
		});

		$("#billing_countryId").change(function () {
			if ($(this).val()) {
				fieldDisplay.getRegions($(this).val(), 'billing');
			}
		});

		$(".contactResearcherBin").click(function () {
			if ($(this).hasClass('disabled')) {
				return;
			}
			contactForm.showContactResearcherBinDialog(Cookies.get('currentBinId'));
		});

		$('.contactResearcherClip').on('click', function (e) {
			let clipInfo = null;
			if (window.videoWindow && window.videoWindow.hasClass('initialized')) {
				clipInfo = window.videoWindow.videoWindow('getClipInfo');
			}
			contactForm.showContactResearcherClipDialog(clipInfo);

		});

		$('.contactResearcher').on('click', function () {
			contactForm.showContactResearcherDialog();
		});
	});
};

export const contactForm = {
	init:init,
	showContactResearcherDialog: showContactResearcherDialog,
	showContactResearcherOrderDialog: showContactResearcherOrderDialog,
	showContactResearcherBinDialog: showContactResearcherBinDialog,
	showContactResearcherClipDialog: showContactResearcherClipDialog,
	showMailClipDialog: showMailClipDialog,
	//getAddressFields: getAddressFields,
	showMailBinDialog: showMailBinDialog
};
