import {fieldDisplay} from '../common/fieldDisplay.js';
import {bins} from '../common/bins.js';
import {commonSearch} from '../common/commonSearch.js';
require("jquery-ui/ui/widgets/sortable");
import {Spinner} from 'spin.js';



var binIsWritable = false,
    onBinPage = null;

function getBinDetails(hashId) {
    $.ajax({
        data: {
            action: 'getBin',
            hash: hashId
        },
        success: function (data) {
            var binInfo = $.parseJSON(data);

            displayBinDetails(binInfo);
        }
    });
}

function getBinDetailsById(id) {
    $.ajax({
        data: {
            action: 'getBin',
            binId: id
        },
        success: function (data) {
            var binInfo = $.parseJSON(data);

            displayBinDetails(binInfo);
        }
    });
}

function getClipInfo(clip) {
    var div = $('<div/>').addClass('info');
    var tcRange = fieldDisplay.displayTCRange(clip.timecodeIn, clip.timecodeOut);
    var content = $("<div>").addClass('content');
    div.append(content);

    if (clip.tape.yearStart || clip.tape.yearEnd || clip.tape.color || clip.tape.genre) {
        var specs = $("<h4 />");
        if (clip.tape.yearStart || clip.tape.yearEnd) {
            specs.append($('<span/>').addClass('tapeYear').text(fieldDisplay.displayRange(clip.tape.yearStart, clip.tape
                .yearEnd)));
        }
        if (clip.tape.color) {
            if (clip.tape.yearStart || clip.tape.yearEnd) specs.append(', ');
            specs.append($('<span/>').addClass('tapeColor').text(fieldDisplay.formatColor(clip.tape.color)));
            if (clip.tape.genre) specs.append(', ');
        }
        if (clip.tape.genre) {
            specs.append($('<span/>').addClass('tapeGenre').text(fieldDisplay.formatColor(clip.tape.genre)));
        }
        content.append(specs);
    }
    if (clip.tape.description) {
        content.append($('<p/>').addClass('description').html(fieldDisplay.nl2br(clip.tape.description)));
    }
    /*
    if (DISPLAY_KEYWORDS) {
        content.append($('<p/>').addClass('keywords')
            .append($('<strong/>').text('Keywords: '))
            .append(formatKeywords(clip.tape.keywords))
        );
    }
    */

    return div;
}

function getClipDetails(clip, li, isWritable, hideCheckBox) {
    let titleBar = $("<div />").addClass('titleBar').attr('title', 'Select this clip'),
        thumb = $("<div />").addClass('thumb'),
        thumbLink = $('<a/>').attr('href', bins.getClipUrl(clip, binPage.binInfo)).on('click', function(e) {
            e.preventDefault();
        }),
        diffDays = 0;

    thumb.append(thumbLink);  
    if (hideCheckBox === undefined) {
        hideCheckBox = false;
    }
 

    li.empty().data('fullClip', null);
    if (clip.hasVideo && !clip.temporailyUnavailable) {
        li.addClass('hasVideo');
    }
    if (clip.specialAccess) {
        li.addClass('specialAccess');
    }
    if (clip.specialtyCollection) {
        li.addClass('specialtyCollection ');
        if (clip.specialAccess) {
            if (clip.MOS && clip.includeAudio) {
                li.addClass('restrictedAudio');
            } else {
                li.addClass('restricted');
            }
        }
    }
     if (clip.temporailyUnavailable) {
        li.addClass('temporailyUnavailable ');
    }

    //always add the hasThumbnails class.
    li.addClass('hasThumbnails');

    li.attr('title', clip.notes ? clip.notes : clip.tapeId);

    //add hidden fields for generating drag helper
    li.data({
        clip: clip,
        id: clip.id,
        clipProKey: clip.tape.id + '_' + clip.offsetIn + '_' + clip.offsetOut,
        hash: clip.hashId,
    });
    if (clip.deletedTape) {
        li.addClass('deletedTape');
    }

    if (isWritable) {
        titleBar
            .prepend($('<div/>').addClass('handle').attr('title', 'Drag to sort'))
            .prepend($('<div/>').addClass('handleTouchOverlay'))
            .append(
                $('<span/>').addClass('closeContainer').append(
                    $('<a/>')
                    .addClass('delete')
                    .addClass('close')
                    .attr('title', "Delete this clip")
                )
            );
    }
    if (!hideCheckBox) {
        titleBar
            .append($("<input/>")
                .addClass('chkClip')
                .attr({
                    type: 'checkbox',
                    title: 'Select this clip',
                    id: "chk" + clip.id
                })
            );
    }
    titleBar
        .append($("<span/>").addClass("tapeId").text(clip.tapeId))
        .append($("<div />")
            .addClass('times')
            .append($("<span />").addClass("startTime").text(fieldDisplay.trimFrames(clip.timecodeIn)))
            .append(' + ')
            .append(
                $("<span />")
                .addClass("totalTime")
                .text(fieldDisplay.getLengthDisplayString(clip.length))
            )
        );

    li.append(titleBar);
    thumbLink.append(fieldDisplay.getNotice(clip,clip.specialAccess,clip.diffDays));
   
    if (clip.hasThumbnails) {
        thumbLink.append(fieldDisplay.getThumbnailFromIndex(clip.tape.id, clip.thumbnailNum ? clip.thumbnailNum : 0,
            'large', 'img', false, clip.hashId, clip.clipType, clip.largeThumbnailHeight
        ));
    }
    thumbLink.append($("<div>")
        .addClass('positionBar')
        .html(
            fieldDisplay.createPositionBar(Number(clip.tape.length), Number(clip.offsetIn), Number(clip.offsetOut))
        )
    );
    li.append(thumb);

    li.append(getClipInfo(clip));

    var comment = $("<div/>")
        .addClass(`commentContainer ${clip.notes ? '' : 'empty'}`)
        .append($('<span/>')
            .addClass('comment')
            .attr('id', 'clipComment' + clip.id)
            .data('fullComment', clip.notes ? fieldDisplay.nl2br(clip.notes) : '')
            .html(clip.notes ? fieldDisplay.nl2br(clip.notes) : '')
        );

    li.append(comment);
    if (clip.clipFlag) {
        li.addClass('hasClipFlag');
        li.append($('<span/>').addClass('clipFlag').html(fieldDisplay.escapeString(clip.clipFlag)).attr('title',
            fieldDisplay.escapeString(clip.clipFlag)));
    }

    if (clip.videoIsAvailable) {
        thumb.hoverVideoPlayer({
            videoPath: fieldDisplay.getClipVideoPath(clip),
            poster: fieldDisplay.getThumbnailUrlFromIndex(clip.tape.id, clip.thumbnailNum ? clip.thumbnailNum : 0,
                'large', false, clip.hashId, clip.clipType),
            offset: clip.offsetIn,
            end: clip.offsetOut
        });
    }

}

function createClipLi(clip) {
    var li = $("<li />")
        .attr('id', 'clipDetails' + clip.id)
        .data({
            id: clip.id,
            clip: clip,
            hash: clip.hashId
        })
        .addClass('clipDetails result')
        .addClass(clip.aspectRatio ? 'aspect' + clip.aspectRatio * 10000 : ''),
        liContents = $("<div />").addClass('clipDetailsContents');
    clip.temporailyUnavailable = false;
    clip.specialAccess = false;
    if (clip.accessExpires) {
        var expiryDate =  new Date(Date.parse(clip.accessExpires, "Y-m-d H:i:s")), // "Y-m-d H:i:s"),
            now = new Date().getTime(),
            timeDiff =expiryDate.getTime() - now;

        clip.specialAccess = expiryDate > now;
        clip.diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    }
    $.when(fieldDisplay.getVideoIsAvailable(fieldDisplay.getClipVideoPath(clip), !clip.specialAccess && !clip.tape.hasVideo)).done(function() {
        clip.videoIsAvailable = true;
        getClipDetails(clip, liContents, binIsWritable);
    }).fail(function() {
        clip.videoIsAvailable = false;
        if (clip.tape.hasVideo) {
            clip.temporailyUnavailable = true;
        }
        getClipDetails(clip, liContents, binIsWritable);
    });
    
    li.append(liContents);
    return li;
}

function addClipEvents(resultPage) {
    // resultPage.find('.comment')

    binPage.binContainer.find("li .comment .empty").hide();

    if (binIsWritable) {
        $.each(resultPage, function (index, el) {
            var comment = el.find('.comment');

            //new Dotdotdot( comment[0], {watch: window});
            
            comment.attr('title', "Click to edit this label").attr('tabindex', '0');
            fieldDisplay.makeEditable(
                comment,
                true,
                'clip',
                el.data('id'),
                'comment',
                "<span class=\"empty\">Add a label to this clip&hellip;<\/span>", {
                    beforeStartCallBack: function (obj) {
                        obj.trigger('destroy.dot');
                    },
                    endCallBack: function (obj, text) {

                        if (!text) {
                            obj.closest('.commentContainer').addClass('empty');
                        } else {
                            obj.closest('.commentContainer').removeClass('empty');
                        }
                    }

                });
        });

        makeBinSortable();

    }
}

function createLoadingPageDisplay(pageNum, startOffset) {
    var resultsPage = $('<li/>')
        .addClass('resultPage')
        .addClass('topLoadingPage')
        .addClass('loadingPage')
        .addClass('resultPage' + pageNum)
        .data('pageNum', pageNum)
        .data('firstItem', startOffset),
        el = $('<div/>')
        .addClass('resultPageLoading'),
        spinner = new Spinner(HF_GLOBALS.smallSpinnerOpts).spin(el[0]);

    return resultsPage.append(el);
}

var relativeDateTimeout = null;

function startRelativeDateTimeout(now) {
    now = now || false;
    clearTimeout(relativeDateTimeout);
    var el = $(".dateModified"),
        date = el.data('date');
    $('.attribution').show();
    el.text(fieldDisplay.relativeDate(now ? null : date));
    relativeDateTimeout = setTimeout(startRelativeDateTimeout, 30000);

}

function initializeBinClips(currentPage) {
    var dfd = new $.Deferred();
    binPage.binContainer.addClass('selectContainer');
    binPage.binContainer.empty();
    if (binPage.binContainer.hasClass('scrollManager')) {
        binPage.binContainer.scrollManager('destroy');
    }
    if (binPage.binContainer.hasClass('ui-sortable')) {
        binPage.binContainer.sortable('destroy');
    }
    binPage.binContainer.scrollManager({

        getData: function (start, count) {

            var dfd = new $.Deferred();
            binPage.binContainer.waitingBox();
            $.ajax({
                data: {
                    action: 'getBinPage',
                    start: start,
                    count: count,
                    binId: binPage.displayBinId,
                    binType: binPage.binInfo.binType
                },
                dataType: 'json',
                success: function (data) {
                    dfd.resolve(data);
                }
            }).always(function() {
                binPage.binContainer.trigger('stopWaiting');
            });
            return dfd.promise();
        },
        getPage: function (data, pageNum, pageStartOffset, startIndex) {

            var index = 0,
                numDrawn = 0,
                clips = [];
            $.each(data.clips, function (name, value) {
                if (numDrawn >= HF_GLOBALS.binPageSize) {
                    return false;
                }
                if (index >= startIndex) {
                    let li = createClipLi(value);
                    clips.push(li);
                    numDrawn++;
                }
                index++;
            });

            return clips;
        },
        removePages: false,
        maximumDisplayedPages: 100,
        numPagesToRemove: 0,
        pageSize: HF_GLOBALS.binPageSize,
        currentPage: currentPage,
        getItemElement: createClipLi,
        //getPageHeader: createPageHeader,
        getLoadingPageDisplay: createLoadingPageDisplay,
        getNewPageScrollOffset: 300,
        disableAutoloadClass: 'disableScrollPageLoad',
        afterInit: function (data) {
            if (binPage.binContainer.data('scrollTop')) {
                $(document).scrollTop(binPage.binContainer.data('scrollTop'));
                binPage.binContainer.data('scrollTop', '');
            }
            dfd.resolve();
        },
        scrollHandler: function (scrollTop) {

        },
        afterPageDrawn: addClipEvents,
        getNumResults: function (data) {
            return data.clips.length;
        },
        noDataHandler: function () {
            binPage.binContainer.append($('<li/>').addClass('noClips').text('(This bin is empty)'));
        },
        startLoading: function () {

        },
        finishLoading: function () {}
    });
    return dfd.promise();
}

function makeBinSortable() {
    if (binPage.binContainer.hasClass('ui-sortable')) {
        binPage.binContainer.sortable('destroy');
    }

    binPage.binContainer.sortable({
        //appendTo: "#binContents",
        tolerance: 'pointer',
        handle: '.handleTouchOverlay',
        items: 'li.clipDetails',
        scrollSensitivity: 200,
        //helper: 'clone',
        start: function (event, ui) {
            if (ui.item.width() / binPage.binContainer.width() > 0.5) {
                binPage.binContainer.sortable('option', 'axis', 'y');
            } else {
                binPage.binContainer.sortable('option', 'axis', false);
            }
        },
        update: function (event, ui) {
           binPage.binContainer.data('scrollTop', $(document).scrollTop());
            $.when(bins.sortClips(
                binPage.displayBinId,
                ui.item.data('id'),
                ui.item.prevAll().length)).done(function (binInfo) {
                binPage.binInfo = binInfo;
                displayBinDetails(binInfo);
                updateModifiedDate(binInfo);
            });
        }
    });
}


function getListInfo(clipId) {
    var li = $(`#clipDetails${clipId}`),
        index = li.prevAll().length +1,
        clipObj = clipObj || li.data('fullClip');
    if (!li.length) {
        return null;
    }
    return {
        index: index,
        numResults: binPage.binInfo.numClips,
        objName: 'clip',
        inlike: `in ${binPage.binInfo.title}`,
        navDescription: `<span class="viewing">Viewing </span><span class="verb">clip </span>` +
            `<span class="count">${fieldDisplay.formatNumber(index)} of ${fieldDisplay.formatNumber(binPage.binInfo.numClips)} in ${binPage.binInfo.title}</span>`,
        step: function (dir) {
            var newEl;
            switch (dir) {
            case 'next':
                newEl = li.next();
                break;
            case 'prev':
                newEl = li.prev();
                break;
            }
            if (newEl) {
                showClip(newEl);
            }
        }
    };
}

function showClip(li, clipObj) {
    var clipId = li.data('id');
    //     index = li.prevAll().length +1;

    clipObj = clipObj || li.data('fullClip');
    // if (clipObj)
    //     clipObj.temporailyUnavailable = li.find('.clipDetailsContents').hasClass('temporailyUnavailable');
    
    $.when($("#videoWindow").videoWindow(
        'showClip', 
        {
            clip: clipObj,
            clipId: clipId,
            isWritable: binIsWritable,
            clipType: li.data('clip').clipType
        }, 
        getListInfo(clipId)
    )).done(function (clip) {
        $.when(function () {
            if (typeof (commonSearch) !== 'undefined') {
                return commonSearch.appendExtraReelData(clip);
            } else {
                return true;
            }
        }()).always(function () {
            console.log(clip);
            li.data('fullClip', clip.clip);
            //console.log('stopWaiting')
            //$("#layoutContainer").data('keepWaiting', '').trigger('stopWaiting');
            //dfd.resolve();
        });

        // if (typeof(commonSearch) !== 'undefined') {
        //  $.when(commonSearch.appendExtraReelData(clip)).done(function() {

        //  })
        // }

        //window.updateState({clip: clip});
        // $(".clip,.clipDetails").removeClass('displayed');
        // $('#clipDetails' + clipTape.id).addClass('displayed');
        // $('#clip' + clipTape.id).addClass('displayed');
    });
}

function displayBinDetails(binInfo) {
    binPage.binInfo = binInfo;
    binPage.displayBinId = binPage.binInfo.id;
    $("#binTitle").html(fieldDisplay.escapeString(binInfo.title ? binPage.binInfo.title : 'Your Clips'));
    updateModifiedDate(binInfo);
    $("#binDescription").html(fieldDisplay.nl2br(binInfo.note));

    binIsWritable = binPage.binInfo.isWritable;
    if (!binIsWritable) {
        $(".deleteBin").addClass('disabled');
    } else {
        $(".deleteBin").removeClass('disabled');
        fieldDisplay.makeEditable($("#binTitle"), false, 'bin', binPage.binInfo.id, 'title',
            "<span class=\"empty\">(Click to edit this bin's name)<\/span>", {
                required: true
            });
        $("#binTitle").attr('title', 'Click to edit this bin\'s name');
        fieldDisplay.makeEditable($("#binDescription"), true, 'bin', binPage.binInfo.id, 'note',
            "<span class=\"empty\">(Click here to add a note about this bin)<\/span>");
        $("#binTitle").attr('title', 'Click to edit this bin\'s name');
        $("#binDescription").attr('title', 'Click to edit this note');
    }

    $('#binContents').data('id', binPage.binInfo.id);

    if (binPage.binInfo.numClips === 0) {
        $(".disableOnEmpty").addClass('disabled').attr('tabindex', '-1');
        $("#resultSelectBin").attr('disabled', 'disabled');
    } else {
        $(".disableOnEmpty").removeClass('disabled').attr('tabindex', '0');
        $("#resultSelectBin").removeAttr('disabled');
    }

    //disable unusable buttons
    $(".disableOnEmptySelection").addClass('disabled')
        .attr('tabindex', '-1')
        .prop('disabled', true);

}

function updateModifiedDate(binInfo) {
    binPage.binInfo = binInfo;
    var timestamp = binPage.binInfo.date_modified_ts ? binPage.binInfo.date_modified_ts : 0;

    if (timestamp) {
        $('.attribution .date').show();
    } else {
        $('.attribution .date').hide();
    }
    $('.dateModified').data('date', timestamp)
        .attr('title', fieldDisplay.formatDate(timestamp, "MMM D, YYYY @ h:mma", true));
    startRelativeDateTimeout(false);
}


function isBinPage() {
    if (onBinPage == null) {
        let body = $('body');
        onBinPage = body.hasClass('bin') || body.hasClass('shareBin') || body.hasClass('order');
    }
    return onBinPage;
}

export let binPage = {
    getBinDetailsById: getBinDetailsById,
    showClip: showClip,
    displayBinId: null,
    updateModifiedDate: updateModifiedDate,
    displayBinDetails: displayBinDetails,
    initializeBinClips: initializeBinClips,
    getClipDetails: getClipDetails,
    isBinPage: isBinPage,
    binInfo: null,
    binContainer: null,
    getListInfo: getListInfo
};